import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type DashboardDocument = {
  id: string;
  name: string;
  type: string;
  url: string;
};

export async function getDocuments(): Promise<DashboardDocument[]> {
  const result = await fetch(`${API_ENDPOINT}/dashboard-documents`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.documents) {
    return [];
  }

  return result.documents;
}

export async function getDocument(
  documentId: string,
): Promise<DashboardDocument> {
  const result = await fetch(
    `${API_ENDPOINT}/dashboard-documents/${documentId}`,
    {
      headers: getHeaders(),
      method: "GET",
    },
  ).then((r) => r.json());

  return result;
}

export async function createDocument(
  url: string,
  name: string,
  type: string,
): Promise<DashboardDocument> {
  const result = await fetch(`${API_ENDPOINT}/dashboard-documents`, {
    body: JSON.stringify({
      name: name,
      type: type,
      url: url,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}

export async function updateDocument(
  id: string,
  url: string,
  name: string,
  type: string,
): Promise<DashboardDocument> {
  const result = await fetch(`${API_ENDPOINT}/dashboard-documents/${id}`, {
    body: JSON.stringify({
      name: name,
      type: type,
      url: url,
    }),
    headers: getHeaders(),
    method: "PUT",
  }).then((r) => r.json());

  return result;
}

export async function deleteDocument(
  documentId: string,
): Promise<DashboardDocument> {
  const result = await fetch(
    `${API_ENDPOINT}/dashboard-documents/${documentId}`,
    {
      headers: getHeaders(),
      method: "DELETE",
    },
  ).then((r) => r.json());

  return result;
}
