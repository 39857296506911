import React, { useState } from "react";
import { Sidebar } from "../../Sidebar";
import { LessonForm } from "./LessonForm";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { getLesson, Lesson } from "../../api/LessonsApi";

export function EditLesson() {
  const { moduleId, subjectId, lessonId } = useParams<{
    lessonId: string;
    moduleId: string;
    subjectId: string;
  }>();

  const [lesson, setLesson] = useState<Lesson | null>(null);

  useEffectOnce(() => {
    getLesson(moduleId, subjectId, lessonId).then((r) => {
      setLesson(r);
    });
  });

  if (!lesson) {
    return null;
  }

  return (
    <Sidebar>
      <LessonForm lesson={lesson} />
    </Sidebar>
  );
}
