import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { createDiscount, Discount, updateDiscount } from "../api/DiscountsApi";

export function DiscountForm({ discount }: { discount: Discount | null }) {
  const [name, setName] = useState(discount ? discount.name : "");
  const [value, setValue] = useState(discount ? discount.discount : 0);

  const [saved, setSaved] = useState(false);

  if (saved) {
    return <Navigate to={"/dashboard/discounts"} />;
  }

  return (
    <form
      onSubmit={async (e) => {
        // TODO checks on fields
        e.preventDefault();

        const updatedBusiness = {
          discount: value,
          name: name.toLowerCase().trim(),
        };

        if (discount) {
          updateDiscount({
            ...updatedBusiness,
            id: discount.id,
          }).then(async () => {
            setSaved(true);
          });
        } else {
          createDiscount({
            id: "",
            ...updatedBusiness,
          }).then(async () => {
            setSaved(true);
          });
        }
      }}
    >
      <div className="flex justify-evenly pb-4">
        <div className="w-25">
          <div>
            <h4>Algemene info</h4>
            <label>Naam</label>
            <input
              className="input"
              onChange={(input) => {
                setName(input.target.value);
              }}
              placeholder="Naam"
              required={true}
              type="text"
              value={name}
            />
            <label>Waarde</label>
            <input
              className="input"
              onChange={(input) => {
                setValue(parseInt(input.target.value));
              }}
              placeholder="Naam"
              required={true}
              type="number"
              value={value}
            />

            <button className="button" type="submit">
              {discount ? "Discount bijwerken" : "Discount toevoegen"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
