import { API_ENDPOINT, getHeaders } from "./AuthApi";

export interface FavoriteType {
  lessonId: string;
  userId: string;
}

export async function getFavorites(): Promise<FavoriteType[]> {
  const result = await fetch(`${API_ENDPOINT}/favorites`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.favorites) {
    return [];
  }

  return result.favorites;
}

export async function createFavorite(lessonId: string): Promise<FavoriteType> {
  const result = await fetch(`${API_ENDPOINT}/favorites`, {
    body: JSON.stringify({
      lessonId: lessonId,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}

export async function deleteFavorite(lessonId: string): Promise<FavoriteType> {
  const result = await fetch(`${API_ENDPOINT}/favorites`, {
    body: JSON.stringify({
      lessonId: lessonId,
    }),
    headers: getHeaders(),
    method: "DELETE",
  }).then((r) => r.json());

  return result;
}
