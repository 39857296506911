import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type Logo = {
  id: string;
  url: string;
};

export async function getLogos(): Promise<Logo[]> {
  const result = await fetch(`${API_ENDPOINT}/logos`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.logos) {
    return [];
  }

  return result.logos;
}

export async function createLogo(url: string): Promise<Logo> {
  const result = await fetch(`${API_ENDPOINT}/logos`, {
    body: JSON.stringify({
      url: url,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}

export async function deleteLogo(id: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/logos/${id}`, {
    headers: getHeaders(),
    method: "DELETE",
  }).then((r) => r.json());
}
