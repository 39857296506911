import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type Media = {
  id: string;
  name: string;
  url: string;
};

export async function getMedia(): Promise<Media[]> {
  const result = await fetch(`${API_ENDPOINT}/media`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.medias) {
    return [];
  }

  return result.medias;
}

export async function createMedia(url: string, name: string): Promise<Media> {
  const result = await fetch(`${API_ENDPOINT}/media`, {
    body: JSON.stringify({
      name: name,
      url: url,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}

export async function addMedia(file: File): Promise<{ path: string } | string> {
  const formData = new FormData();

  formData.append("media", file);

  const result = await fetch(`${API_ENDPOINT}/media/upload`, {
    body: formData,
    headers: getHeaders(false),
    method: "POST",
  }).then((r) => r.json());

  if (result.message) {
    return result.message;
  }

  return result;
}
