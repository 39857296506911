import React, { Fragment, useRef, useState } from "react";
import { Module } from "./api/ModulesApi";
import { getModuleProgress, ModuleProgress as Progress } from "./api/UserApi";
import { getLessonsByModuleAndSubject, Lesson } from "./api/LessonsApi";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import { getSubjects } from "./api/SubjectsApi";
import { Link } from "react-router-dom";
import { Podcast } from "./api/PodcastApi";

export function UserDashboardProgramsBox({
  module,
  podcast,
  podcastTimes,
}: {
  module?: Module;
  podcast?: Podcast;
  podcastTimes?: { id: string; time: number }[];
}) {
  const [moduleProgress, setModuleProgress] = useState<Progress[]>([]);
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const audioRef = useRef<HTMLAudioElement>(null);

  const { i18n, t } = useTranslation("user-dashboard");

  useEffectOnce(() => {
    if (!module) {
      return;
    }

    getSubjects(module.id).then(async (result) => {
      const tempLessons: Lesson[] = [];

      for (const subject of result) {
        const subjectLessons = await getLessonsByModuleAndSubject(
          module.id,
          subject.id,
        );

        tempLessons.push(...subjectLessons);
      }

      setLessons(tempLessons);
    });

    getModuleProgress(module.id).then((result) => {
      setModuleProgress(result);
    });
  });

  const visited = moduleProgress.map((i) => i.lessonId);
  let percentage = 0;

  if (module) {
    percentage =
      lessons.length !== 0
        ? Math.min(
            Math.floor((new Set(visited).size / lessons.length) * 100),
            100,
          )
        : 0;
  }

  const latestVisit = moduleProgress
    ? moduleProgress[moduleProgress.length - 1]
    : null;

  if (podcast && podcastTimes && audioRef.current) {
    const foundTime = podcastTimes.find((p) => p.id === podcast.id);

    if (foundTime) {
      percentage = Math.min(
        (foundTime.time / (audioRef.current.duration / 60)) * 100,
        100,
      );
    }
  }

  return (
    <Fragment>
      {percentage > 0 ? (
        <div className="box-wrapper flex flex-column">
          <div className="title">
            {module
              ? module.skills
                ? t("userPrograms.skillTitle")
                : t("userPrograms.moduleTitle")
              : t("userPrograms.podcastTitle")}
          </div>
          <div className="sub-title">
            {module
              ? i18n.language === "nl"
                ? module.titleNL
                : i18n.language === "en"
                ? module.titleEN
                : module.titleFR
              : i18n.language === "nl"
              ? podcast?.name
              : i18n.language === "en"
              ? podcast?.nameEN
              : podcast?.nameFR}
          </div>
          <div className="module-bar-wrapper">
            <div
              className="module-bar"
              style={{
                width: `${percentage}%`,
              }}
            />
          </div>
          <Link
            className="button"
            to={
              module && latestVisit
                ? `/modules/${module.id}/subjects/${latestVisit.subjectId}/lessons/${latestVisit.lessonId}`
                : `/${module ? "modules" : "podcasts"}/${
                    module ? module.id : ""
                  }`
            }
          >
            {t("userPrograms.continue")}
            <div className="arrow" />
          </Link>
        </div>
      ) : null}
      {podcast ? (
        <audio ref={audioRef} style={{ display: "none" }}>
          <source
            src={
              i18n.language === "nl"
                ? podcast.audio
                : i18n.language === "en"
                ? podcast.audioEN
                : podcast.audioFR
            }
            type="audio/mp3"
          />
        </audio>
      ) : null}
    </Fragment>
  );
}
