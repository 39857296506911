import React from "react";
import { LegendElement, ChartData, TooltipItem } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import TooltipIcon from "../img/tooltipIcon.svg";
import "./ActivityGraph.css";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { ModulesActivity } from "../api/BusinessApi";
import { formatNumber } from "../Utils";
import { TranslationResponseType } from "../i18n";

function customOnClick(
  this: LegendElement<"doughnut">,
  // e: ChartEvent,
  // legendItem: LegendItem,
  // legend: LegendElement<"doughnut">
): void {
  return;
}

export function getActivityGraphData(
  modulesActivity: ModulesActivity,
  translationResponse: TranslationResponseType,
): ChartData<"doughnut", number[], string> {
  const { t } = translationResponse;
  const total =
    modulesActivity.notActive +
    modulesActivity.inProcess +
    modulesActivity.done;

  const notActivePercentage = (modulesActivity.notActive / total) * 100;
  const inProcessPercentage = (modulesActivity.inProcess / total) * 100;
  const donePercentage = (modulesActivity.done / total) * 100;

  return {
    datasets: [
      {
        backgroundColor: [
          "rgba(255, 186, 73, 1)",
          "rgba(107, 152, 170, 1)",
          "rgba(254, 161, 124, 1)",
        ],
        data: [notActivePercentage, inProcessPercentage, donePercentage],
      },
    ],
    labels: [
      t("activityGraph.labelNotActive"),
      t("activityGraph.labelActive"),
      t("activityGraph.labelDone"),
    ],
  };
}

export function ActivityGraph({
  modulesActivity,
}: {
  modulesActivity: ModulesActivity;
}) {
  const { t } = useTranslation("hr-dashboard");
  const translationResponse = useTranslation("hr-dashboard");
  const data = getActivityGraphData(modulesActivity, translationResponse);
  const options = {
    aspectRatio: 1,
    circumference: 180,
    cutout: "80%",
    plugins: {
      legend: {
        labels: {
          font: {
            size: 12,
          },
          usePointStyle: true,
        },
        onClick: customOnClick,
        position: "bottom" as const,
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 1)",
        bodyFont: {
          size: 16,
        },
        boxPadding: 4,
        callbacks: {
          label: function (tooltipItem: TooltipItem<"doughnut">) {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            const currentValue = dataset.data[tooltipItem.dataIndex];
            const label = data.labels ? data.labels[tooltipItem.dataIndex] : "";

            return label + ": " + formatNumber(currentValue) + "%";
          },
        },
        titleFont: {
          size: 16,
        },
        usePointStyle: true,
      },
    },
    responsive: true,
    rotation: -90,
  };

  return (
    <div className="graph">
      <div className={"graph-title flex items-center"}>
        {t("activityGraph.title")}
        <div
          className={"flex items-center"}
          data-tooltip-content={t("activityGraph.tooltipContent")}
          data-tooltip-id="graph-explanation"
        >
          <img alt={""} className={"tooltip-icon"} src={TooltipIcon} />
        </div>
        <Tooltip className={"tooltip"} id={"graph-explanation"} />
      </div>
      {modulesActivity.notActive === 0 &&
      modulesActivity.inProcess === 0 &&
      modulesActivity.done === 0 ? (
        <div className={"ml-5 mt-4 mb-4"}>
          {t("activityGraph.noDataMessage")}
        </div>
      ) : (
        <div className={"doughnut-wrapper graph-wrapper"}>
          <Doughnut
            data={data}
            options={options}
            style={{ maxHeight: "420px" }}
          />
        </div>
      )}
    </div>
  );
}
