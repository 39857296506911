import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ImpactSlider.css";

export function ImpactSlider() {
  const data: any = {
    "50": { collegas: 9, kost: 72.224, verloren: 216 },
    "60": { collegas: 11, kost: 86.669, verloren: 259 },
    "70": { collegas: 13, kost: 101.114, verloren: 302 },
    "80": { collegas: 15, kost: 115.559, verloren: 345 },
    "90": { collegas: 17, kost: 130.004, verloren: 388 },
    "100": { collegas: 19, kost: 144.449, verloren: 431 },
    "110": { collegas: 21, kost: 158.894, verloren: 474 },
    "120": { collegas: 23, kost: 173.339, verloren: 517 },
    "130": { collegas: 25, kost: 187.784, verloren: 560 },
    "140": { collegas: 27, kost: 202.229, verloren: 603 },
    "150": { collegas: 29, kost: 216.674, verloren: 646 },
    "160": { collegas: 31, kost: 231.119, verloren: 689 },
    "170": { collegas: 33, kost: 245.564, verloren: 732 },
    "180": { collegas: 35, kost: 260.009, verloren: 775 },
    "190": { collegas: 37, kost: 274.454, verloren: 818 },
    "200": { collegas: 39, kost: 288.899, verloren: 861 },
    "210": { collegas: 41, kost: 303.344, verloren: 904 },
    "220": { collegas: 43, kost: 317.789, verloren: 947 },
    "230": { collegas: 45, kost: 332.234, verloren: 990 },
    "240": { collegas: 47, kost: 346.679, verloren: 1033 },
    "250": { collegas: 49, kost: 361.124, verloren: 1076 },
    "260": { collegas: 51, kost: 375.569, verloren: 1119 },
    "270": { collegas: 53, kost: 390.014, verloren: 1162 },
    "280": { collegas: 55, kost: 404.459, verloren: 1205 },
    "290": { collegas: 57, kost: 418.904, verloren: 1248 },
    "300": { collegas: 59, kost: 433.349, verloren: 1291 },
    "310": { collegas: 61, kost: 447.794, verloren: 1334 },
    "320": { collegas: 63, kost: 462.239, verloren: 1377 },
    "330": { collegas: 65, kost: 476.684, verloren: 1420 },
    "340": { collegas: 67, kost: 491.129, verloren: 1463 },
    "350": { collegas: 69, kost: 505.574, verloren: 1506 },
    "360": { collegas: 71, kost: 520.019, verloren: 1549 },
    "370": { collegas: 73, kost: 534.464, verloren: 1592 },
    "380": { collegas: 75, kost: 548.909, verloren: 1635 },
    "390": { collegas: 77, kost: 563.354, verloren: 1678 },
    "400": { collegas: 79, kost: 577.799, verloren: 1721 },
    "410": { collegas: 81, kost: 592.244, verloren: 1764 },
    "420": { collegas: 83, kost: 606.689, verloren: 1807 },
    "430": { collegas: 85, kost: 621.134, verloren: 1850 },
    "440": { collegas: 87, kost: 635.579, verloren: 1893 },
    "450": { collegas: 89, kost: 650.024, verloren: 1936 },
    "460": { collegas: 91, kost: 664.469, verloren: 1979 },
    "470": { collegas: 93, kost: 678.914, verloren: 2022 },
    "480": { collegas: 95, kost: 693.359, verloren: 2065 },
    "490": { collegas: 97, kost: 707.804, verloren: 2108 },
    "500": { collegas: 99, kost: 722.249, verloren: 2151 },
    "510": { collegas: 101, kost: 736.694, verloren: 2194 },
    "520": { collegas: 103, kost: 751.139, verloren: 2237 },
    "530": { collegas: 105, kost: 765.584, verloren: 2280 },
    "540": { collegas: 107, kost: 780.029, verloren: 2323 },
    "550": { collegas: 109, kost: 794.474, verloren: 2366 },
    "560": { collegas: 111, kost: 808.919, verloren: 2409 },
    "570": { collegas: 113, kost: 823.364, verloren: 2452 },
    "580": { collegas: 115, kost: 837.809, verloren: 2495 },
    "590": { collegas: 117, kost: 852.254, verloren: 2538 },
    "600": { collegas: 119, kost: 866.699, verloren: 2581 },
    "610": { collegas: 121, kost: 881.144, verloren: 2624 },
    "620": { collegas: 123, kost: 895.589, verloren: 2667 },
    "630": { collegas: 125, kost: 910.034, verloren: 2710 },
    "640": { collegas: 127, kost: 924.479, verloren: 2753 },
    "650": { collegas: 129, kost: 938.924, verloren: 2796 },
    "660": { collegas: 131, kost: 953.369, verloren: 2839 },
    "670": { collegas: 133, kost: 967.814, verloren: 2882 },
    "680": { collegas: 135, kost: 982.259, verloren: 2925 },
    "690": { collegas: 137, kost: 996.704, verloren: 2968 },
    "700": { collegas: 139, kost: "1.011.149", verloren: 3011 },
    "710": { collegas: 141, kost: "1.025.594", verloren: 3054 },
    "720": { collegas: 143, kost: "1.040.039", verloren: 3097 },
    "730": { collegas: 145, kost: "1.054.484", verloren: 3140 },
    "740": { collegas: 147, kost: "1.068.929", verloren: 3183 },
    "750": { collegas: 149, kost: "1.083.374", verloren: 3226 },
    "760": { collegas: 151, kost: "1.097.819", verloren: 3269 },
    "770": { collegas: 153, kost: "1.112.264", verloren: 3312 },
    "780": { collegas: 155, kost: "1.126.709", verloren: 3355 },
    "790": { collegas: 157, kost: "1.141.154", verloren: 3398 },
    "800": { collegas: 159, kost: "1.155.599", verloren: 3441 },
    "810": { collegas: 161, kost: "1.170.044", verloren: 3484 },
    "820": { collegas: 163, kost: "1.184.489", verloren: 3527 },
    "830": { collegas: 165, kost: "1.198.934", verloren: 3570 },
    "840": { collegas: 167, kost: "1.213.379", verloren: 3613 },
    "850": { collegas: 169, kost: "1.227.824", verloren: 3656 },
    "860": { collegas: 171, kost: "1.242.269", verloren: 3699 },
    "870": { collegas: 173, kost: "1.256.714", verloren: 3742 },
    "880": { collegas: 175, kost: "1.271.159", verloren: 3785 },
    "890": { collegas: 177, kost: "1.285.604", verloren: 3828 },
    "900": { collegas: 179, kost: "1.300.049", verloren: 3871 },
    "910": { collegas: 181, kost: "1.314.494", verloren: 3914 },
    "920": { collegas: 183, kost: "1.328.939", verloren: 3957 },
    "930": { collegas: 185, kost: "1.343.384", verloren: 4000 },
    "940": { collegas: 187, kost: "1.357.829", verloren: 4043 },
    "950": { collegas: 189, kost: "1.372.274", verloren: 4086 },
    "960": { collegas: 191, kost: "1.386.719", verloren: 4129 },
    "970": { collegas: 193, kost: "1.401.164", verloren: 4172 },
    "980": { collegas: 195, kost: "1.415.609", verloren: 4215 },
    "990": { collegas: 197, kost: "1.430.054", verloren: 4258 },
    "1000": { collegas: 199, kost: "1.444.499", verloren: 4301 },
    "1010": { collegas: 201, kost: "1.458.944", verloren: 4344 },
    "1020": { collegas: 203, kost: "1.473.389", verloren: 4387 },
    "1030": { collegas: 205, kost: "1.487.834", verloren: 4430 },
    "1040": { collegas: 207, kost: "1.502.279", verloren: 4473 },
    "1050": { collegas: 209, kost: "1.516.724", verloren: 4516 },
    "1060": { collegas: 211, kost: "1.531.169", verloren: 4559 },
    "1070": { collegas: 213, kost: "1.545.614", verloren: 4602 },
    "1080": { collegas: 215, kost: "1.560.059", verloren: 4645 },
    "1090": { collegas: 217, kost: "1.574.504", verloren: 4688 },
    "1100": { collegas: 219, kost: "1.588.949", verloren: 4731 },
    "1110": { collegas: 221, kost: "1.603.394", verloren: 4774 },
    "1120": { collegas: 223, kost: "1.617.839", verloren: 4817 },
    "1130": { collegas: 225, kost: "1.632.284", verloren: 4860 },
    "1140": { collegas: 227, kost: "1.646.729", verloren: 4903 },
    "1150": { collegas: 229, kost: "1.661.174", verloren: 4946 },
    "1160": { collegas: 231, kost: "1.675.619", verloren: 4989 },
    "1170": { collegas: 233, kost: "1.690.064", verloren: 5032 },
    "1180": { collegas: 235, kost: "1.704.509", verloren: 5075 },
    "1190": { collegas: 237, kost: "1.718.954", verloren: 5118 },
    "1200": { collegas: 239, kost: "1.733.399", verloren: 5161 },
    "1210": { collegas: 241, kost: "1.747.844", verloren: 5204 },
    "1220": { collegas: 243, kost: "1.762.289", verloren: 5247 },
    "1230": { collegas: 245, kost: "1.776.734", verloren: 5290 },
    "1240": { collegas: 247, kost: "1.791.179", verloren: 5333 },
    "1250": { collegas: 249, kost: "1.805.624", verloren: 5376 },
    "1260": { collegas: 251, kost: "1.820.069", verloren: 5419 },
    "1270": { collegas: 253, kost: "1.834.514", verloren: 5462 },
    "1280": { collegas: 255, kost: "1.848.959", verloren: 5505 },
    "1290": { collegas: 257, kost: "1.863.404", verloren: 5548 },
    "1300": { collegas: 259, kost: "1.877.849", verloren: 5591 },
    "1310": { collegas: 261, kost: "1.892.294", verloren: 5634 },
    "1320": { collegas: 263, kost: "1.906.739", verloren: 5677 },
    "1330": { collegas: 265, kost: "1.921.184", verloren: 5720 },
    "1340": { collegas: 267, kost: "1.935.629", verloren: 5763 },
    "1350": { collegas: 269, kost: "1.950.074", verloren: 5806 },
    "1360": { collegas: 271, kost: "1.964.519", verloren: 5849 },
    "1370": { collegas: 273, kost: "1.978.964", verloren: 5892 },
    "1380": { collegas: 275, kost: "1.993.409", verloren: 5935 },
    "1390": { collegas: 277, kost: "2.007.854", verloren: 5978 },
    "1400": { collegas: 279, kost: "2.022.299", verloren: 6021 },
    "1410": { collegas: 281, kost: "2.036.744", verloren: 6064 },
    "1420": { collegas: 283, kost: "2.051.189", verloren: 6107 },
    "1430": { collegas: 285, kost: "2.065.634", verloren: 6150 },
    "1440": { collegas: 287, kost: "2.080.079", verloren: 6193 },
    "1450": { collegas: 289, kost: "2.094.524", verloren: 6236 },
    "1460": { collegas: 291, kost: "2.108.969", verloren: 6279 },
    "1470": { collegas: 293, kost: "2.123.414", verloren: 6322 },
    "1480": { collegas: 295, kost: "2.137.859", verloren: 6365 },
    "1490": { collegas: 297, kost: "2.152.304", verloren: 6408 },
    "1500": { collegas: 299, kost: "2.166.749", verloren: 6451 },
    "1510": { collegas: 301, kost: "2.181.194", verloren: 6494 },
    "1520": { collegas: 303, kost: "2.195.639", verloren: 6537 },
    "1530": { collegas: 305, kost: "2.210.084", verloren: 6580 },
    "1540": { collegas: 307, kost: "2.224.529", verloren: 6623 },
    "1550": { collegas: 309, kost: "2.238.974", verloren: 6666 },
    "1560": { collegas: 311, kost: "2.253.419", verloren: 6709 },
    "1570": { collegas: 313, kost: "2.267.864", verloren: 6752 },
    "1580": { collegas: 315, kost: "2.282.309", verloren: 6795 },
    "1590": { collegas: 317, kost: "2.296.754", verloren: 6838 },
    "1600": { collegas: 319, kost: "2.311.199", verloren: 6881 },
    "1610": { collegas: 321, kost: "2.325.644", verloren: 6924 },
    "1620": { collegas: 323, kost: "2.340.089", verloren: 6967 },
    "1630": { collegas: 325, kost: "2.354.534", verloren: 7010 },
    "1640": { collegas: 327, kost: "2.368.979", verloren: 7053 },
    "1650": { collegas: 329, kost: "2.383.424", verloren: 7096 },
    "1660": { collegas: 331, kost: "2.397.869", verloren: 7139 },
    "1670": { collegas: 333, kost: "2.412.314", verloren: 7182 },
    "1680": { collegas: 335, kost: "2.426.759", verloren: 7225 },
    "1690": { collegas: 337, kost: "2.441.204", verloren: 7268 },
    "1700": { collegas: 339, kost: "2.455.649", verloren: 7311 },
    "1710": { collegas: 341, kost: "2.470.094", verloren: 7354 },
    "1720": { collegas: 343, kost: "2.484.539", verloren: 7397 },
    "1730": { collegas: 345, kost: "2.498.984", verloren: 7440 },
    "1740": { collegas: 347, kost: "2.513.429", verloren: 7483 },
    "1750": { collegas: 349, kost: "2.527.874", verloren: 7526 },
    "1760": { collegas: 351, kost: "2.542.319", verloren: 7569 },
    "1770": { collegas: 353, kost: "2.556.764", verloren: 7612 },
    "1780": { collegas: 355, kost: "2.571.209", verloren: 7655 },
    "1790": { collegas: 357, kost: "2.585.654", verloren: 7698 },
    "1800": { collegas: 359, kost: "2.600.099", verloren: 7741 },
    "1810": { collegas: 361, kost: "2.614.544", verloren: 7784 },
    "1820": { collegas: 363, kost: "2.628.989", verloren: 7827 },
    "1830": { collegas: 365, kost: "2.643.434", verloren: 7870 },
    "1840": { collegas: 367, kost: "2.657.879", verloren: 7913 },
    "1850": { collegas: 369, kost: "2.672.324", verloren: 7956 },
    "1860": { collegas: 371, kost: "2.686.769", verloren: 7999 },
    "1870": { collegas: 373, kost: "2.701.214", verloren: 8042 },
    "1880": { collegas: 375, kost: "2.715.659", verloren: 8085 },
    "1890": { collegas: 377, kost: "2.730.104", verloren: 8128 },
    "1900": { collegas: 379, kost: "2.744.549", verloren: 8171 },
    "1910": { collegas: 381, kost: "2.758.994", verloren: 8214 },
    "1920": { collegas: 383, kost: "2.773.439", verloren: 8257 },
    "1930": { collegas: 385, kost: "2.787.884", verloren: 8300 },
    "1940": { collegas: 387, kost: "2.802.329", verloren: 8343 },
    "1950": { collegas: 389, kost: "2.816.774", verloren: 8386 },
    "1960": { collegas: 391, kost: "2.831.219", verloren: 8429 },
    "1970": { collegas: 393, kost: "2.845.664", verloren: 8472 },
    "1980": { collegas: 395, kost: "2.860.109", verloren: 8515 },
    "1990": { collegas: 397, kost: "2.874.554", verloren: 8558 },
    "2000": { collegas: 399, kost: "2.888.999", verloren: 8601 },
  };

  const [value, setValue] = useState("50");

  const { t } = useTranslation("general");

  return (
    <div className="impact-slider">
      <div className="slider-wrapper">
        <div className="slider-title">{t("employees")}</div>
        <div
          id="werknemers"
          style={{
            fontSize: "25px",
            marginBottom: "20px",
          }}
        >
          {value}
        </div>
        <input
          className="range slider-1"
          max="2000"
          min="50"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          step="10"
          type="range"
          value={value}
        />
      </div>
      <div className="impact-results">
        <div className="result">
          <div className="number" id="collegas">
            {data[value].collegas}
          </div>
          <div>{t("problem")}</div>
        </div>
        <div className="result">
          <div className="number" id="verloren">
            {data[value].verloren}
          </div>
          <div>{t("cost")}</div>
        </div>
        <div className="result">
          <div className="number" id="kost">
            € {data[value].kost}
          </div>
          <div>{t("cost2")}</div>
        </div>
      </div>
    </div>
  );
}
