import React from "react";
import Oefeningen from "./img/oefeningen.png";
import Crisis from "./img/ciris.png";
import Favoriet from "./img/favoriet.png";
import Certificaat from "./img/certificaat.png";
import Question from "./img/question.png";
import "./Toolbox.css";
import { Link } from "react-router-dom";
import { ToolboxHeader } from "./ToolboxHeader";
import { loggedIn } from "./api/AuthApi";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

export function Toolbox() {
  const { t } = useTranslation("general");

  const options = [
    {
      description: t("toolbox.exercises_description"),
      image: Oefeningen,
      link: "/toolbox/exercises",
      name: t("toolbox.exercises_name"),
    },
    {
      description: t("toolbox.help_description"),
      image: Crisis,
      link: "/toolbox/crisis",
      name: t("toolbox.help_name"),
    },
    {
      description: t("toolbox.test_description"),
      image: Question,
      link: "/toolbox/tests",
      name: t("toolbox.test_name"),
    },
    {
      description: t("toolbox.favo_description"),
      image: Favoriet,
      link: "/toolbox/favorites",
      name: t("toolbox.favo_name"),
    },
    {
      description: t("toolbox.cert_description"),
      image: Certificaat,
      link: "/toolbox/certificates",
      name: t("toolbox.cert_name"),
    },
  ];

  return (
    <ToolboxHeader>
      <div
        className={`flex wrap ${isMobile ? "" : "container"} justify-center`}
      >
        {!loggedIn() ? (
          <div>{t("login-first")}</div>
        ) : (
          options.map((item) => {
            return (
              <Link className="toolbox-box" to={item.link}>
                <img src={item.image} />
                <div className="name">{item.name}</div>
                <div className="description">{item.description}</div>
              </Link>
            );
          })
        )}
      </div>
    </ToolboxHeader>
  );
}
