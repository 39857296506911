import React from "react";
import ReactModal from "react-modal";
import "./FaceScannerModal.css";
import { FaceScannerInfo } from "./FaceScannerInfo";

export function FaceScannerInfoModal({
  isOpen,
  onRequestClose,
  onAcceptScan,
}: {
  isOpen: boolean;
  onAcceptScan: () => void;
  onRequestClose: () => void;
}) {
  return (
    <ReactModal
      appElement={window.document.getElementById("root") as HTMLElement}
      bodyOpenClassName="scanner-open"
      className="scanner-body"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="scanner-overlay"
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
    >
      <FaceScannerInfo
        onAcceptScan={onAcceptScan}
        onRequestClose={onRequestClose}
      />
    </ReactModal>
  );
}
