import { ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getValidBloomupToken } from "./getValidBloomupToken";
import { getValidMindlabToken } from "./getValidMindlabToken";
import { httpLink } from "./httpLink";

const authLink = setContext(async (_, { headers }) => {
  const mindlabToken = getValidMindlabToken();
  const bloomupToken = await getValidBloomupToken();

  return {
    headers: {
      ...headers,
      "X-ML-Authorization": mindlabToken ? `JWT ${mindlabToken}` : "",
      authorization: bloomupToken ? `Bearer ${bloomupToken}` : "",
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
  name: "mindlab-frontend-" + process.env.NX_PUBLIC_FRONTEND_TYPE,
});

export default client;
