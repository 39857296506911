import { API_ENDPOINT, getHeaders } from "./AuthApi";

interface EvaluationInput {
  experience: string;
  interesting: string;
  less: string;
  name: string | undefined;
  place: string | undefined;
  recommendation: number;
  relevant: string;
  remarks: string;
  score: number;
  unclear: string;
}

interface EvaluationResponse extends EvaluationInput {
  businessId: string | undefined;
  id: string;
  moduleId: string;
  userId: string;
}

export async function createEvaluation(
  data: EvaluationInput,
  moduleId: string,
): Promise<EvaluationResponse> {
  const result = await fetch(`${API_ENDPOINT}/evaluations/${moduleId}`, {
    body: JSON.stringify(data),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}
