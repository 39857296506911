import React from "react";
import reactStringReplace from "react-string-replace";
import { SingleInput } from "./SingleInput";

export function MutliTableForm({ values }: { values: string[][] }) {
  function replaceText(content: string) {
    let replaced = reactStringReplace(
      content,
      /(\[single-input\])/gm,
      (match, i) => {
        return <SingleInput id={i.toString()} />;
      },
    );

    replaced = reactStringReplace(
      replaced,
      /(\[single-form\])/gm,
      (match, i) => {
        return <SingleInput id={i.toString()} />;
      },
    );

    return replaced;
  }

  return (
    <div>
      <table className="w-100">
        <thead>
          {values[0].map((_) => {
            return (
              <th
                style={{
                  width: `calc(100% / ${values[0].length})`,
                }}
              ></th>
            );
          })}
        </thead>
        <tbody>
          {values.map((item) => {
            return (
              <tr>
                {item.map((i) => {
                  return (
                    <td>
                      {replaceText(i).map((item) => {
                        if (typeof item === "string") {
                          return (
                            <div dangerouslySetInnerHTML={{ __html: item }} />
                          );
                        }

                        return item;
                      })}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
