import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import _ from "lodash";
import { Link } from "react-router-dom";
import "./Slider.css";
import Star from "./img/star.svg";
import { AnimatePresence, motion } from "framer-motion";

export enum Sliders {
  IMAGE = "image",
  TESTIMONIAL = "testimonial",
}

export enum SliderDuration {
  LOGO = 0.24,
  PROGRAM = 0.4,
}

type TestimonialData = {
  name: string;
  score: number;
  text: string;
  type: Sliders.TESTIMONIAL;
};
type ImageData = {
  image: string;
  link?: string;
  programName?: string;
  type: Sliders.IMAGE;
};

type SliderItem = TestimonialData | ImageData;

type SliderType =
  | {
      automatic: boolean;
      duration?: number;
      givenData: ImageData[];
      showArrows?: boolean;
      type: Sliders.IMAGE;
    }
  | {
      automatic: boolean;
      duration?: number;
      givenData: TestimonialData[];
      showArrows?: boolean;
      type: Sliders.TESTIMONIAL;
    };

let timeoutId: any;

export function Slider({
  givenData,
  type,
  automatic,
  duration,
  showArrows,
}: SliderType) {
  const [currentPage, setCurrentPage] = useState(1);

  function paginate(items: SliderItem[], pageNumber: number, pageSize: number) {
    const startIndex = (pageNumber - 1) * pageSize;

    return _(items).slice(startIndex).take(pageSize).value();
  }

  let pageSize = 5;

  if (type === Sliders.TESTIMONIAL) {
    pageSize = 3;
  }

  if (isMobile && type !== Sliders.TESTIMONIAL) {
    pageSize = 2;
  }

  if (isMobile && type === Sliders.TESTIMONIAL) {
    pageSize = 1;
  }

  const getPageData = () => {
    const paginationData = paginate(givenData, currentPage, pageSize);

    return {
      data: paginationData,
    };
  };

  const { data } = getPageData();

  const pageCount = Math.ceil(givenData.length / pageSize);

  const nextPage = (increment: number) => {
    if (currentPage + increment <= pageCount && currentPage + increment > 0) {
      setCurrentPage(currentPage + increment);
    } else if (pageCount === currentPage) {
      setCurrentPage(1);
    } else if (increment === -1 && currentPage === 1) {
      setCurrentPage(pageCount);
    }
  };

  useEffect(() => {
    if (!automatic) {
      return;
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(
      () => {
        nextPage(1);
      },
      duration === SliderDuration.LOGO ? 3000 : 5000,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextPage, givenData]);

  return (
    <div
      className={
        type === Sliders.TESTIMONIAL
          ? `slider testimonial-wrapper`
          : `slider wider`
      }
    >
      <div className="flex items-center justify-center w-100">
        {automatic && !showArrows ? null : (
          <div
            className={`arrow left ${
              automatic && showArrows ? "push-down" : ""
            }`}
          >
            {pageCount > 1 ? (
              <div className="arrow-left" onClick={() => nextPage(-1)} />
            ) : null}
          </div>
        )}
        <AnimatePresence>
          <motion.div
            animate={{
              opacity: 1,
              translateX: "0%",
            }}
            className="flex items-center justify-center slider-wrapper"
            exit={{
              opacity: 0,
              translateX: "-10%",
            }}
            initial={{
              opacity: 0,
              translateX: "10%",
            }}
            key={currentPage}
            transition={{
              duration: duration ? duration : SliderDuration.PROGRAM,
            }}
          >
            {data.map((item, index) => {
              if (item.type === Sliders.TESTIMONIAL) {
                return (
                  <TestimonialBlock
                    key={index}
                    middle={false}
                    name={item.name}
                    score={item.score}
                    text={item.text}
                  />
                );
              }

              if (!item.link) {
                return (
                  <div className="image-container" key={index}>
                    <img alt="image" className="image" src={item.image} />
                  </div>
                );
              }

              return (
                <div className="image-container" key={index}>
                  <Link className="link" to={item.link}>
                    <img alt="image" className="image" src={item.image} />
                  </Link>
                  <div className={"program-name"}>{item.programName}</div>
                </div>
              );
            })}
          </motion.div>
        </AnimatePresence>
        <div className="flex items-center justify-center w-100 minimal-width">
          {data.map((item, index) => {
            if (item.type === Sliders.TESTIMONIAL) {
              return (
                <TestimonialBlock
                  hidden={true}
                  key={index}
                  middle={false}
                  name={item.name}
                  score={item.score}
                  text={item.text}
                />
              );
            }

            return null;
          })}
        </div>
        {automatic && !showArrows ? null : (
          <div
            className={`arrow right ${
              automatic && showArrows ? "push-down" : ""
            }`}
          >
            {pageCount > 1 ? (
              <div className="arrow-right" onClick={() => nextPage(1)} />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

function TestimonialBlock({
  score,
  text,
  name,
  middle,
  hidden,
}: {
  hidden?: boolean;
  middle: boolean;
  name: string;
  score: number;
  text: string;
}) {
  return (
    <div
      className={`testimonial ${middle ? "middle" : ""} ${
        hidden ? "" : "smaller-width"
      }`}
      style={{ opacity: `${hidden ? "0" : "1"}` }}
    >
      <div className="subtitle">Testimonial</div>
      <div className="stars">
        {Array.from(Array(score).keys()).map((_) => {
          return <img alt="star" className="star" src={Star} />;
        })}
      </div>
      <div className="text">{text}</div>
      <div className="name">- {name}</div>
    </div>
  );
}
