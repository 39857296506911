import React, { useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import { Loading } from "./Loading";

export function SSORedirect({
  setAuthenticated,
}: {
  setAuthenticated: (state: boolean) => void;
}) {
  const token = useParams<{ token: string }>();

  if (token && token.token) {
    localStorage.setItem("token", token.token);
  }

  setAuthenticated(true);

  return <Navigate replace to={"/home"} />;
}

export function RedirectToSSO({ url }: { url: string }) {
  useEffect(() => {
    window.location.href = url;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
}
