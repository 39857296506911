import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type QuizType = {
  id: string;
  quiz: {
    answer: string;
    optionsEN: { label: string; value: string }[];
    optionsFR: { label: string; value: string }[];
    optionsNL: { label: string; value: string }[];
    questionEN: string;
    questionFR: string;
    questionNL: string;
    type: string;
  }[];
};

export async function getQuizes(): Promise<QuizType[]> {
  const result = await fetch(`${API_ENDPOINT}/quizzes`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.quizzes) {
    return [];
  }

  return result.quizzes;
}

export async function createQuiz(quiz: QuizType): Promise<QuizType> {
  const result = await fetch(`${API_ENDPOINT}/quizzes`, {
    body: JSON.stringify(quiz),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}

export async function updateQuiz(quiz: QuizType): Promise<QuizType> {
  const result = await fetch(`${API_ENDPOINT}/quizzes/${quiz.id}`, {
    body: JSON.stringify(quiz),
    headers: getHeaders(),
    method: "PUT",
  }).then((r) => r.json());

  return result;
}

export async function deleteQuiz(quizId: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/quizzes/${quizId}`, {
    headers: getHeaders(),
    method: "DELETE",
  }).then((r) => r.json());
}
