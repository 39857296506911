import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IntelliProveResults } from "./api/IntelliProveApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons/faCaretUp";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import "./FaceScanResults.css";
import HeartRate from "./img/intelliprove-heart-rate.png";
import MentalStress from "./img/intelliprove-mental-stress.png";
import HealthRisk from "./img/intelliprove-health-risk.png";
import Respiration from "./img/intelliprove-respiration.png";
import Balance from "./img/intelliprove-balance.png";
import Readiness from "./img/intelliprove-readiness.png";
import HeartRateVar from "./img/intelliprove-heart-rate-var.png";
import CoherentBreathing from "./img/intelliprove-resonant-breathing.png";
import { FaceScanResultType } from "./Types";

function calculatePercentage(min: number, max: number, current: number) {
  return ((current - min) / (max - min)) * 100;
}

export function ResultCard({
  title,
  value,
  valueMin,
  valueMax,
  onClick,
  color,
  iconSrc,
  caretIcon,
  resultText,
  adviceText,
  open,
  gradient,
  textScore,
}: {
  adviceText: string;
  caretIcon: IconDefinition | null;
  color: string;
  gradient: string;
  iconSrc: string;
  onClick: () => void;
  open: boolean;
  resultText: string;
  textScore: string;
  title: string;
  value: number | null;
  valueMax: number;
  valueMin: number;
}) {
  const { t } = useTranslation("scan");

  return (
    <div className={`result-card ${open ? "open" : ""}`} onClick={onClick}>
      <div className="result-card-title">{title}</div>
      <div className="result-score">
        <div className="result-bar-overflow">
          <div
            className={`result-bar ${color}`}
            style={{
              transform: `rotate(${
                value
                  ? 45 + calculatePercentage(valueMin, valueMax, value) * 1.8
                  : 45
              }deg)`,
            }}
          />
          <div className="result-value">{value || "-"}</div>
          <div className="text-score">{t(textScore)}</div>
        </div>
      </div>
      <img alt="icon" className="result-card-icon" src={iconSrc} />
      <div className="result-card-caret">
        {!caretIcon ? null : (
          <FontAwesomeIcon color={"black"} icon={caretIcon} size="2x" />
        )}
      </div>
      {open ? (
        <div>
          <div className="divider" />
          <div className="gradient-wrapper">
            <div
              style={{
                background: `${gradient}`,
                borderRadius: "15px",
                height: "15px",
                marginTop: "20px",
              }}
            />
            <div
              className="gradient-indicator"
              style={{
                left: `calc(${
                  value ? calculatePercentage(valueMin, valueMax, value) : 0
                }% - 10px)`,
              }}
            >
              <FontAwesomeIcon color={"black"} icon={faCaretDown} size="2x" />
            </div>
          </div>
          <div className="result-title">{t("result")}:</div>
          <div>{resultText}</div>
          <div className="result-title">{t("advice")}:</div>
          <div>{adviceText}</div>
        </div>
      ) : null}
    </div>
  );
}

function getHeartRateGradient() {
  return "linear-gradient(90deg, var(--green) 0%, var(--yellow) 19%, var(--pink) 100%)";
}

function getRespiratoryRateGradient() {
  return "linear-gradient(90deg, var(--green) 0%, var(--yellow) 100%)";
}

function getHeartRateVariabilityGradient() {
  return "linear-gradient(90deg, var(--pink) 0%, var(--yellow) 50%, var(--green) 100%)";
}

function getAnsBalanceGradient() {
  return "linear-gradient(90deg, var(--pink) 0%, var(--yellow) 20%, var(--green) 30%, var(--yellow) 70%, var(--pink) 100%)";
}

function getMorningReadinessGradient() {
  return "linear-gradient(90deg, var(--dark-grey) 0%, var(--pink) 10%, var(--yellow) 40%, var(--green) 100%)";
}

function getAcuteMentalStressScoreGradient() {
  return "linear-gradient(90deg, var(--dark-grey) 0%, var(--pink) 20%, var(--yellow) 60%, var(--green) 100%)";
}

function getMentalHealthRiskScoreGradient() {
  return "linear-gradient(90deg, var(--dark-grey) 0%, var(--green) 33%, var(--yellow) 66%, var(--pink) 100%)";
}

function getResonantBreathingScoreGradient() {
  return "linear-gradient(90deg, var(--pink) 0%, var(--yellow) 40%, var(--green) 100%)";
}

function getHeartRateTextScore(score: number | null) {
  if (score === null) {
    return "unknown";
  }

  if (score <= 71) {
    return "low";
  }

  if (score <= 83) {
    return "medium";
  }

  return "high";
}

function getRespiratoryRateTextScore(score: number | null) {
  if (score === null) {
    return "unknown";
  }

  if (score <= 20) {
    return "low";
  }

  return "high";
}

function getHeartRateVariabilityTextScore(score: number | null) {
  if (score === null) {
    return "unknown";
  }

  if (score <= 35) {
    return "low";
  }

  if (score <= 100) {
    return "medium";
  }

  return "high";
}

function getAnsBalanceTextScore(score: number | null) {
  if (score === null) {
    return "unknown";
  }

  if (score < -3) {
    return "high";
  }

  if (score <= -2) {
    return "medium";
  }

  if (score <= 2) {
    return "low";
  }

  if (score <= 3) {
    return "medium";
  }

  return "high";
}

function getMorningReadinessTextScore(score: number | null) {
  if (score === null) {
    return "unknown";
  }

  if (score <= 4) {
    return "low";
  }

  if (score <= 8) {
    return "medium";
  }

  return "high";
}

function getAcuteMentalStressScoreTextScore(score: number | null) {
  if (score === null) {
    return "unknown";
  }

  if (score <= 2) {
    return "high";
  }

  if (score <= 4) {
    return "medium";
  }

  return "low";
}

function getMentalHealthRiskScoreTextScore(score: number | null) {
  if (score === null) {
    return "unknown";
  }

  if (score <= 1) {
    return "low";
  }

  if (score <= 2) {
    return "medium";
  }

  return "high";
}

function getResonantBreathingScoreTextScore(score: number | null) {
  if (score === null) {
    return "unknown";
  }

  if (score <= 40) {
    return "low";
  }

  if (score <= 70) {
    return "medium";
  }

  return "high";
}

function getHeartRateGroup(score: number | null) {
  if (score === null) {
    return null;
  }

  if (score <= 71) {
    return "1";
  }

  if (score <= 83) {
    return "2";
  }

  return "3";
}

function getRespiratoryRateGroup(score: number | null) {
  if (score === null) {
    return null;
  }

  if (score <= 20) {
    return "1";
  }

  return "2";
}

function getHeartRateVariabilityGroup(score: number | null) {
  if (score === null) {
    return null;
  }

  if (score <= 35) {
    return "1";
  }

  if (score <= 100) {
    return "2";
  }

  return "3";
}

function getAnsBalanceGroup(score: number | null) {
  if (score === null) {
    return null;
  }

  if (score < -3) {
    return "1";
  }

  if (score <= -2) {
    return "2";
  }

  if (score <= 2) {
    return "3";
  }

  if (score <= 3) {
    return "4";
  }

  return "5";
}

function getMorningReadinessGroup(score: number | null) {
  if (score === null) {
    return null;
  }

  if (score <= 4) {
    return "2";
  }

  if (score <= 8) {
    return "3";
  }

  return "4";
}

function getAcuteMentalStressScoreGroup(score: number | null) {
  if (score === null) {
    return null;
  }

  if (score <= 2) {
    return "2";
  }

  if (score <= 4) {
    return "3";
  }

  return "4";
}

function getMentalHealthRiskScoreGroup(score: number | null) {
  if (score === null) {
    return null;
  }

  if (score <= 1) {
    return "2";
  }

  if (score <= 2) {
    return "3";
  }

  return "4";
}

function getResonantBreathingScoreGroup(score: number | null) {
  if (score === null) {
    return null;
  }

  if (score <= 40) {
    return "1";
  }

  if (score <= 70) {
    return "2";
  }

  return "3";
}

export const detailsTable: Record<
  FaceScanResultType,
  {
    gradient: string;
    group: (v: number | null) => string | null;
    icon: string;
    max: number;
    min: number;
    name: string;
    textScore: (v: number | null) => string;
  }
> = {
  [FaceScanResultType.HEART_RATE]: {
    gradient: getHeartRateGradient(),
    group: (v) => getHeartRateGroup(v),
    icon: HeartRate,
    max: 216,
    min: 36,
    name: "heartRate",
    textScore: (v) => getHeartRateTextScore(v),
  },
  [FaceScanResultType.ACUTE_MENTAL_STRESS_SCORE]: {
    gradient: getAcuteMentalStressScoreGradient(),
    group: (v) => getAcuteMentalStressScoreGroup(v),
    icon: MentalStress,
    max: 5,
    min: 0,
    name: "acuteMentalStressScore",
    textScore: (v) => getAcuteMentalStressScoreTextScore(v),
  },
  [FaceScanResultType.MENTAL_HEALTH_RISK]: {
    gradient: getMentalHealthRiskScoreGradient(),
    group: (v) => getMentalHealthRiskScoreGroup(v),
    icon: HealthRisk,
    max: 3,
    min: 1,
    name: "mentalHealthRisk",
    textScore: (v) => getMentalHealthRiskScoreTextScore(v),
  },
  [FaceScanResultType.RESPIRATORY_RATE]: {
    gradient: getRespiratoryRateGradient(),
    group: (v) => getRespiratoryRateGroup(v),
    icon: Respiration,
    max: 48,
    min: 6,
    name: "respiratoryRate",
    textScore: (v) => getRespiratoryRateTextScore(v),
  },
  [FaceScanResultType.ANS_BALANCE]: {
    gradient: getAnsBalanceGradient(),
    group: (v) => getAnsBalanceGroup(v),
    icon: Balance,
    max: 5,
    min: -5,
    name: "ansBalance",
    textScore: (v) => getAnsBalanceTextScore(v),
  },
  [FaceScanResultType.MORNING_READINESS_SCORE]: {
    gradient: getMorningReadinessGradient(),
    group: (v) => getMorningReadinessGroup(v),
    icon: Readiness,
    max: 10,
    min: 0,
    name: "morningReadiness",
    textScore: (v) => getMorningReadinessTextScore(v),
  },
  [FaceScanResultType.HEART_RATE_VARIABILITY]: {
    gradient: getHeartRateVariabilityGradient(),
    group: (v) => getHeartRateVariabilityGroup(v),
    icon: HeartRateVar,
    max: 200,
    min: 0,
    name: "heartRateVariability",
    textScore: (v) => getHeartRateVariabilityTextScore(v),
  },
  [FaceScanResultType.RESONANT_BREATHING_SCORE]: {
    gradient: getResonantBreathingScoreGradient(),
    group: (v) => getResonantBreathingScoreGroup(v),
    icon: CoherentBreathing,
    max: 100,
    min: 0,
    name: "resonantBreathingScore",
    textScore: (v) => getResonantBreathingScoreTextScore(v),
  },
};

export function getCorrectResult(
  results: IntelliProveResults,
  type: FaceScanResultType,
) {
  switch (type) {
    case FaceScanResultType.HEART_RATE:
      return results.heartRate;

    case FaceScanResultType.ACUTE_MENTAL_STRESS_SCORE:
      return results.acuteMentalStressScore;

    case FaceScanResultType.MENTAL_HEALTH_RISK:
      return results.mentalHealthRisk;

    case FaceScanResultType.RESPIRATORY_RATE:
      return results.respiratoryRate;

    case FaceScanResultType.ANS_BALANCE:
      return results.ansBalance;

    case FaceScanResultType.MORNING_READINESS_SCORE:
      return results.morningReadiness;

    case FaceScanResultType.HEART_RATE_VARIABILITY:
      return results.heartRateVariability;

    case FaceScanResultType.RESONANT_BREATHING_SCORE:
      return results.resonantBreathingScore;

    default:
      return null;
  }
}

export function FaceScanResults({
  results,
  itemsToShow,
}: {
  itemsToShow: FaceScanResultType[];
  results: IntelliProveResults;
}) {
  const [currentItem, setCurrentItem] = useState<FaceScanResultType | null>(
    null,
  );

  const { t } = useTranslation("scan");

  return (
    <div className="scan-results two-by-two">
      <div className="scan-results-title">{t("yourResults")}</div>
      <div className="results-grid two-by-two">
        {itemsToShow.map((item) => {
          const group = detailsTable[item].group(
            getCorrectResult(results, item),
          );

          if (group === null) {
            return null;
          }

          return (
            <ResultCard
              adviceText={t(
                `${detailsTable[item].name}.${detailsTable[item].group(
                  getCorrectResult(results, item),
                )}.right`,
              )}
              caretIcon={currentItem === item ? faCaretUp : faCaretDown}
              color={t(`${detailsTable[item].name}.${group}.color`)}
              gradient={detailsTable[item].gradient}
              iconSrc={detailsTable[item].icon}
              onClick={() => {
                if (currentItem === item) {
                  setCurrentItem(null);
                } else {
                  setCurrentItem(item);
                }
              }}
              open={currentItem === item}
              resultText={t(
                `${detailsTable[item].name}.${detailsTable[item].group(
                  getCorrectResult(results, item),
                )}.left`,
              )}
              textScore={detailsTable[item].textScore(
                getCorrectResult(results, item),
              )}
              title={t(`${detailsTable[item].name}.title`)}
              value={getCorrectResult(results, item)}
              valueMax={detailsTable[item].max}
              valueMin={detailsTable[item].min}
            />
          );
        })}
      </div>
    </div>
  );
}
