import "./BusinessMail.css";
import { Crisp } from "crisp-sdk-web";

export function MindlabMail() {
  Crisp.configure("4b3025fd-d5d7-491a-b0a9-8d1ac53aa633");

  return null;

  /*const [hover, setHover] = useState<boolean>(false);
  const { t } = useTranslation("general");

  return hover ? (
    <div
      className="mindlab-mail-wrapper"
      onMouseLeave={() => setHover(false)}
      onClick={() => setHover(!hover)} //mobile
    >
      <BsFillPersonFill className={"person-icon"} />
      <a
        className="mindlab-mail"
        href={`mailto:info@mindlab.be`}
        title="Contacteer Mindlab"
      >
        <div className={"button"}>{t("mindlabMail")}</div>
      </a>
    </div>
  ) : (
    <div
      className="mindlab-mail-icon pointer"
      onMouseEnter={() => setHover(true)}
      onClick={() => setHover(!hover)} //mobile
    >
      <img src={Camera} alt="mail info" />
    </div>
  );*/
}
