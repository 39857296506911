import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Sidebar } from "./Sidebar";
import { BusinessStatistics, getStats } from "./api/BusinessApi";
import { getModules, Module } from "./api/ModulesApi";
import { Loading } from "./Loading";
import { Duration } from "luxon";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

export function Dashboard() {
  return (
    <Sidebar>
      <Stats />
    </Sidebar>
  );
}

function Stats() {
  const [stats, setStats] = useState<BusinessStatistics>();
  const [modules, setModules] = useState<Module[]>([]);
  const [loading, setLoading] = useState(false);

  useEffectOnce(() => {
    setLoading(true);
    getStats().then((r) => {
      setStats(r);
      setLoading(false);
    });

    getModules().then((result) => {
      setModules(result);
    });
  });

  const options = {
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      y: {
        ticks: {
          callback: function () {
            return "";
          },
        },
      },
    },
  };

  const modulesLabels = modules.filter((m) => !m.skills).map((m) => m.titleNL);

  const modulesDataset = [
    {
      backgroundColor: "#FFBD4A",
      data: modules
        .filter((m) => !m.skills)
        .map((m) => {
          if (!stats) {
            return 0;
          }

          const foundStat = stats.modules.find((s) => s.id === m.id);

          return foundStat?.total || 0;
        }),
      label: "Mindlab",
    },
  ];

  const modulesData = {
    datasets: modulesDataset,
    labels: modulesLabels,
  };

  const skillsLabels = modules.filter((m) => m.skills).map((m) => m.titleNL);

  const skillsDataset = [
    {
      backgroundColor: "#FFBD4A",
      data: modules
        .filter((m) => m.skills)
        .map((m) => {
          if (!stats) {
            return 0;
          }

          const foundStat = stats.modules.find((s) => s.id === m.id);

          return foundStat?.total || 0;
        }),
      label: "Mindlab",
    },
  ];

  const skillsData = {
    datasets: skillsDataset,
    labels: skillsLabels,
  };

  const data = {
    datasets: [
      {
        backgroundColor: ["#75A1AF", "#90B4BF", "#A8C4CD", "#DDE8EB"],
        data: stats
          ? [stats.s0_40, stats.s41_60, stats.s61_80, stats.s81_100]
          : [],
      },
    ],
    labels: ["0-40%", "41%-60%", "61%-80%", "81%-100%"],
  };

  const options2 = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    responsive: true,
  };

  const labels = [
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "December",
  ];

  const usersData = {
    datasets: [
      {
        backgroundColor: "#FCBA55",
        borderColor: "#FCBA55",
        data: labels.map((_, index) => {
          if (!stats) {
            return null;
          }

          const foundStat = stats.newUsersPerMonth.find(
            (i) => i.month === index + 1,
          );

          return foundStat?.count || 0;
        }),
        label: "",
      },
    ],
    labels,
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="business-dashboard flex flex-column">
            <h2>Dashboard</h2>
            <div className="flex mb-4 justify-between">
              <div className="stats-box">
                <div className="number">{stats?.newUsersThisWeek}</div>
                <div className="title">Nieuwe gebruikers deze week</div>
              </div>
              <div className="stats-box">
                <div className="number">
                  {stats?.evaluationScore.toFixed(1)}/10
                </div>
                <div className="title">Tevredenheid gebruikers</div>
              </div>
            </div>
            <div className="stats-box full">
              <div className="title">Keuze progamma's</div>
              <div>
                <Bar data={modulesData} options={options} />
              </div>
            </div>
            <div className="stats-box full mt-4">
              <div className="title">Keuze skills</div>
              <div>
                <Bar data={skillsData} options={options} />
              </div>
            </div>
          </div>
          <div className="business-dashboard flex flex-column">
            <h2>Statistieken</h2>
            <div className="flex">
              <div className="w-45">
                <Doughnut data={data} />
              </div>
              <div className="w-45 flex justify-between ml-auto">
                <div className="stats-box max-height reverse">
                  <div className="title">Gem. tijd per module</div>
                  <div className="number">
                    {stats && stats.modules.length !== 0
                      ? Duration.fromMillis(
                          stats.modules.reduce((a, b) => a + b.mean, 0) /
                            stats.modules.length,
                        ).toFormat("hh'h'mm")
                      : null}
                  </div>
                </div>
                <div className="stats-box max-height ml-5 reverse">
                  <div className="title">Gem. tijd per gebruiker</div>
                  <div className="number">
                    {stats
                      ? Duration.fromMillis(stats.meanPerUser).toFormat(
                          "hh'h'mm",
                        )
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="stats-box full mt-4">
              <div className="title">Aantal nieuwe gebruikers per maand</div>
              <div>
                <Line data={usersData} options={options2} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
