import React, { useCallback, useState } from "react";
import { forgotPassword } from "./api/AuthApi";
import { useTranslation } from "react-i18next";
import { graphql } from "./__generated__";
import { useMutation } from "@apollo/client";

const resetPasswordMutation = graphql(`
  mutation ResetPassword($email: String!, $fromMindlab: Boolean) {
    resetPassword(email: $email, fromMindlab: $fromMindlab)
  }
`);

function PasswordForgot() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [bloomupResetPassword] = useMutation(resetPasswordMutation);
  const { t } = useTranslation("login");

  const handleResetPassword = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (!process.env.NX_PUBLIC_USE_BLOOMUP_LOGIN) {
        forgotPassword(email).then((r) => {
          setMessage(r);
        });
      } else {
        bloomupResetPassword({
          onCompleted: () => {
            setMessage(t("passwordResetSend"));
          },
          onError: () => {
            setMessage(t("genericError"));
          },
          variables: { email: email, fromMindlab: true },
        });
      }
    },
    [bloomupResetPassword, email, t],
  );

  return (
    <div className="auth login">
      <h2>{t("reset")}</h2>

      <form className="auth-form" onSubmit={handleResetPassword}>
        <label>{t("email")}</label>
        <input
          className="input"
          onChange={(input) => {
            setEmail(input.target.value);
          }}
          placeholder={t("email")}
          required={true}
          type="email"
          value={email}
        />
        {message !== "" ? <div className="mt-4">{t(`${message}`)}</div> : null}
        <button className="button mt-4" type="submit">
          {t("request")}
        </button>
      </form>
    </div>
  );
}

export default PasswordForgot;
