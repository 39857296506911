import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type Module = {
  descriptionEN: string;
  descriptionFR: string;
  descriptionNL: string;
  id: string;
  image: string;
  isPublic: boolean;
  linkedBusinesses: string[];
  order: number;
  price: number;
  skills: boolean;
  titleEN: string;
  titleFR: string;
  titleNL: string;
  video: string;
};

export async function getModules(): Promise<Module[]> {
  const result = await fetch(`${API_ENDPOINT}/modules`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.modules) {
    return [];
  }

  return result.modules;
}

export async function getModuleAccess(): Promise<string[]> {
  const result = await fetch(`${API_ENDPOINT}/modules/access`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.modules) {
    return [];
  }

  return result.modules;
}

export async function createModule(module: Module): Promise<Module> {
  const result = await fetch(`${API_ENDPOINT}/modules`, {
    body: JSON.stringify(module),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}

export async function updateModule(module: Module): Promise<Module> {
  const result = await fetch(`${API_ENDPOINT}/modules/${module.id}`, {
    body: JSON.stringify(module),
    headers: getHeaders(),
    method: "PUT",
  }).then((r) => r.json());

  return result;
}

export async function getModule(moduleId: string): Promise<Module> {
  const result = await fetch(`${API_ENDPOINT}/modules/${moduleId}`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.module;
}

export async function deleteModule(moduleId: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/modules/${moduleId}`, {
    headers: getHeaders(),
    method: "DELETE",
  }).then((r) => r.json());
}
