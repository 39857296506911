import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { Sidebar } from "../Sidebar";
import { DashboardDocumentForm } from "./DashboardDocumentForm";
import { DashboardDocument, getDocument } from "../api/DashboardDocumentsApi";

export function EditDashboardDocument() {
  const { documentId } = useParams<{ documentId: string }>();
  const [document, setDocument] = useState<DashboardDocument | null>(null);

  useEffectOnce(() => {
    getDocument(documentId).then((r) => {
      setDocument(r);
    });
  });

  if (!document) {
    return <Sidebar />;
  }

  return (
    <Sidebar>
      <DashboardDocumentForm document={document} />
    </Sidebar>
  );
}
