import { API_ENDPOINT, getHeaders } from "./AuthApi";

interface CmsType {
  data: string;
  type: string;
}

export async function getCmsItem(type: string): Promise<CmsType> {
  const result = await fetch(`${API_ENDPOINT}/cms/${type}`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result;
}

export async function upsertCmsItem(
  type: string,
  data: string,
): Promise<CmsType> {
  const result = await fetch(`${API_ENDPOINT}/cms`, {
    body: JSON.stringify({
      data,
      type,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}
