import React, { useState } from "react";
import { Sidebar } from "../Sidebar";
import { useEffectOnce } from "react-use";
import { Navigate } from "react-router-dom";
import { FaPlus, FaPen, FaTimes } from "react-icons/fa";
import { Challenge, deleteChallenge, getChallenges } from "../api/GameApi";

export function Challenges() {
  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const [addingChallenge, setAddingChallenge] = useState(false);
  const [editingChallenge, setEditingChallenge] = useState<Challenge | null>(
    null,
  );

  const [filter, setFilter] = useState("");

  useEffectOnce(() => {
    getChallenges().then((r) => {
      setChallenges(r);
    });
  });

  if (addingChallenge) {
    return <Navigate to="/dashboard/challenges/new" />;
  }

  if (editingChallenge) {
    return <Navigate to={`/dashboard/challenges/${editingChallenge.id}`} />;
  }

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>Challenges</h2>
          <div
            className="button small ml-auto"
            onClick={() => {
              setAddingChallenge(true);
            }}
          >
            <FaPlus />
          </div>
        </div>

        <input
          className="input"
          onChange={(input) => {
            setFilter(input.target.value);
          }}
          placeholder={"Naam"}
          type="text"
          value={filter}
        />

        {challenges.length === 0 ? (
          "Momenteel nog geen challenges beschikbaar"
        ) : (
          <table className="table ">
            <thead>
              <tr>
                <th>Naam</th>
                <th>Beschrijving</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {challenges
                .filter((b) =>
                  b.nameNL.toLowerCase().includes(filter.toLowerCase()),
                )
                .map((business) => {
                  return (
                    <tr key={business.id}>
                      <td>{business.nameNL}</td>
                      <td>{business.textNL}</td>
                      <td>
                        <div className="flex">
                          <div
                            className="button small w-fit ml-5 yellow"
                            onClick={() => {
                              setEditingChallenge(business);
                            }}
                          >
                            <FaPen />
                          </div>
                          <div
                            className="button small w-fit ml-5 pink"
                            onClick={async () => {
                              await deleteChallenge(business);
                              setChallenges(
                                challenges.filter((c) => c.id !== business.id),
                              );
                            }}
                          >
                            <FaTimes />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
    </Sidebar>
  );
}
