import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { FaPlus, FaPen, FaTimes } from "react-icons/fa";
import { createSubject, Subject, updateSubject } from "../../api/SubjectsApi";
import {
  deleteLesson,
  getLessonsByModuleAndSubject,
  Lesson,
} from "../../api/LessonsApi";
import { useEffectOnce } from "react-use";

export function SubjectForm({ subject }: { subject: Subject | null }) {
  const { moduleId, subjectId } = useParams<{
    moduleId: string;
    subjectId: string;
  }>();

  const [editLesson, setEditLesson] = useState<Lesson | false>(false);
  const [createLesson, setCreateLesson] = useState(false);
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [nameNL, setNameNL] = useState(subject ? subject.nameNL : "");
  const [nameEN, setNameEN] = useState(subject ? subject.nameEN : "");
  const [nameFR, setNameFR] = useState(subject ? subject.nameFR : "");
  const [navigate, setNavigate] = useState<null | string>(null);

  const [saved, setSaved] = useState(false);

  useEffectOnce(() => {
    if (subjectId) {
      getLessonsByModuleAndSubject(moduleId, subjectId).then((result) => {
        setLessons(result);
      });
    }
  });

  if (editLesson) {
    return (
      <Navigate
        to={`/dashboard/modules/${moduleId}/subjects/${subjectId}/lessons/${editLesson.id}`}
      />
    );
  }

  if (createLesson) {
    return (
      <Navigate
        to={`/dashboard/modules/${moduleId}/subjects/${subjectId}/lessons/new`}
      />
    );
  }

  if (navigate) {
    return (
      <Navigate to={`/dashboard/modules/${moduleId}/subjects/${navigate}`} />
    );
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const updatedSubject = {
          id: "",
          nameEN: nameEN,
          nameFR: nameFR,
          nameNL: nameNL,
        };

        if (subject) {
          await updateSubject(moduleId, {
            ...updatedSubject,
            id: subject.id,
          });
        } else {
          const result = await createSubject(moduleId, updatedSubject);

          setNavigate(result.id);

          return;
        }

        setSaved(true);
        setTimeout(() => {
          setSaved(false);
        }, 3000);
      }}
    >
      <div className="container">
        <div className="w-25">
          <div>
            <h4>Algemene info</h4>
            <label>Naam in het Nederlands</label>
            <input
              className="input"
              onChange={(input) => {
                setNameNL(input.target.value);
              }}
              placeholder="Naam"
              required={true}
              type="text"
              value={nameNL}
            />
            <label>Naam in het Engels</label>
            <input
              className="input"
              onChange={(input) => {
                setNameEN(input.target.value);
              }}
              placeholder="Naam"
              type="text"
              value={nameEN}
            />
            <label>Naam in het Frans</label>
            <input
              className="input"
              onChange={(input) => {
                setNameFR(input.target.value);
              }}
              placeholder="Naam"
              type="text"
              value={nameFR}
            />

            {saved ? (
              <div className="mb-4 mt-4 green">Aanpassingen opgeslagen</div>
            ) : null}

            <button className="button" type="submit">
              {subject ? "Onderwerp bijwerken" : "Onderwerp toevoegen"}
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="flex">
          <h4>Lessen</h4>
          <div
            className="button small ml-auto"
            onClick={() => {
              setCreateLesson(true);
            }}
          >
            <FaPlus />
          </div>
        </div>
        <table className="table ">
          <thead>
            <tr>
              <th>Naam</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {lessons.map((lesson) => {
              return (
                <tr>
                  <td>{lesson.nameNL}</td>
                  <td>
                    <div className="flex">
                      <div
                        className="button small w-fit ml-auto yellow"
                        onClick={() => {
                          setEditLesson(lesson);
                        }}
                      >
                        <FaPen />
                      </div>
                      <div
                        className="button small w-fit ml-5 pink"
                        onClick={async () => {
                          await deleteLesson(moduleId, subjectId, lesson);

                          setLessons(lessons.filter((l) => l.id !== lesson.id));
                          // TODO: add confirm
                        }}
                      >
                        <FaTimes />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </form>
  );
}
