import React, { useState } from "react";
import { Sidebar } from "../Sidebar";
import { useEffectOnce } from "react-use";
import { Navigate } from "react-router-dom";
import { FaPlus, FaPen, FaTimes } from "react-icons/fa";
import { listBusinesses, Business, deleteBusiness } from "../api/BusinessApi";
import { DateTime } from "luxon";
import "./Businesses.css";
import { getCorrectUrl } from "../Utils";

export function Businesses() {
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [addingBusiness, setAddingBusiness] = useState(false);
  const [editingBusiness, setEditingBusiness] = useState<Business | null>(null);
  const [filter, setFilter] = useState("");

  useEffectOnce(() => {
    listBusinesses().then((r) => {
      setBusinesses(r);
    });
  });

  if (addingBusiness) {
    return <Navigate to="/dashboard/businesses/new" />;
  }

  if (editingBusiness) {
    return <Navigate to={`/dashboard/businesses/${editingBusiness.id}`} />;
  }

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>Bedrijfscodes</h2>
          <div
            className="button small ml-auto"
            onClick={() => {
              setAddingBusiness(true);
            }}
          >
            <FaPlus />
          </div>
        </div>

        <input
          className="input"
          onChange={(input) => {
            setFilter(input.target.value);
          }}
          placeholder={"Naam"}
          type="text"
          value={filter}
        />

        {businesses.length === 0 ? (
          "Momenteel nog geen bedrijfscodes beschikbaar"
        ) : (
          <div className="limit-table">
            <table className="table ">
              <thead>
                <tr>
                  <th>Logo</th>
                  <th>Naam</th>
                  <th>Code</th>
                  <th>Email</th>
                  <th>Licentietype</th>
                  <th>Startdatum</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {businesses
                  .filter(
                    (b) =>
                      b.code.toLowerCase().includes(filter.toLowerCase()) ||
                      b.name.toLowerCase().includes(filter.toLowerCase()),
                  )
                  .map((business) => {
                    return (
                      <tr key={business.id}>
                        <td>
                          <img
                            alt={business.name}
                            className="business-image"
                            src={getCorrectUrl(business.logo)}
                          />
                        </td>
                        <td>{business.name}</td>
                        <td>{business.code}</td>
                        <td>{business.email}</td>
                        <td>
                          {business.type === 0
                            ? "Standaard"
                            : business.type === 1
                            ? "Premium"
                            : "Genius"}
                        </td>
                        <td>
                          {DateTime.fromISO(business.startDate).toFormat(
                            "dd/LL/y",
                          )}
                        </td>
                        <td>
                          <div className="flex">
                            <div
                              className="button small w-fit ml-5 yellow"
                              onClick={() => {
                                setEditingBusiness(business);
                              }}
                            >
                              <FaPen />
                            </div>
                            <div
                              className="button small w-fit ml-5 pink"
                              onClick={async () => {
                                await deleteBusiness(business);
                                setBusinesses(
                                  businesses.filter(
                                    (b) => b.id !== business.id,
                                  ),
                                );
                              }}
                            >
                              <FaTimes />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Sidebar>
  );
}
