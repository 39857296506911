import React, { useEffect, useState } from "react";
import { createUserTestScore, Test, TestStatement } from "./api/TestApi";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

export function MultipleOldTest({ test }: { test: Test }) {
  const [showScore, setShowScore] = useState<Record<string, number> | null>(
    null,
  );

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [sortedStatements, setSortedStatments] = useState<TestStatement[]>([]);
  const { i18n, t } = useTranslation("general");

  useEffect(() => {
    setSelectedOptions([]);
    setShowScore(null);
    setSortedStatments(test.statements.sort((a, b) => a.position - b.position));
  }, [test]);

  return (
    <div className="tests">
      {!showScore ? (
        <>
          <div className="testview">
            <div className="w-100">
              {sortedStatements.map((statement, index) => {
                const options = test.options.filter(
                  (o) => o.groupId === statement.optionGroupId,
                );

                return (
                  <>
                    <b>{i18n.language === "nl" && statement.title_nl}</b>
                    <b>{i18n.language === "en" && statement.title_en}</b>
                    <b>{i18n.language === "fr" && statement.title_fr}</b>

                    {options.map((option) => {
                      return (
                        <div>
                          <input
                            checked={option.id === selectedOptions[index]}
                            onChange={() => {
                              const tmp = [...selectedOptions];

                              tmp[index] = option.id;
                              setSelectedOptions(tmp);
                            }}
                            required
                            type="radio"
                            value={option.id}
                          />
                          {i18n.language === "nl" && option.title_nl}
                          {i18n.language === "en" && option.title_en}
                          {i18n.language === "fr" && option.title_fr}
                        </div>
                      );
                    })}
                  </>
                );
              })}
            </div>
          </div>

          <div
            className="button mt-4 w-fit ml-5"
            onClick={async () => {
              const groupScores: Record<string, number> = {};

              for (let index = 0; index < test.statements.length; index++) {
                const statement = sortedStatements[index];
                const current = groupScores[statement.groupId];

                if (!current) {
                  groupScores[statement.groupId] = 0;
                }

                const foundOption = test.options.find(
                  (o) => o.id === selectedOptions[index],
                );

                if (!foundOption) {
                  continue;
                }

                groupScores[statement.groupId] += foundOption.score;
              }

              const currentDate = DateTime.utc();

              for (const groupId of Object.keys(groupScores)) {
                await createUserTestScore({
                  date: currentDate.toUTC().toISO() as string,
                  groupId: groupId,
                  score: groupScores[groupId],
                  testId: test.id,
                });
              }

              setShowScore(groupScores);
            }}
          >
            {t("calculate_score")}
          </div>
        </>
      ) : (
        <div>
          <h3 className="test-result">{t("score")}</h3>

          {Object.entries(showScore).map(([id, score]) => {
            const group = test.groups.find((g) => g.id === id);

            if (!group) {
              return null;
            }

            const groupScore = test.testScores
              .filter((s) => s.groupId === group.id && s.ltScore > score)
              .sort((a, b) => a.ltScore - b.ltScore)[0];

            if (!groupScore) {
              return null;
            }

            return (
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      i18n.language === "nl"
                        ? groupScore.explanation_nl.replace(
                            "https://b2b-staging.mindlab.be",
                            "",
                          )
                        : i18n.language === "en"
                        ? (groupScore.explanation_en || "").replace(
                            "https://b2b-staging.mindlab.be",
                            "",
                          )
                        : (groupScore.explanation_fr || "").replace(
                            "https://b2b-staging.mindlab.be",
                            "",
                          ),
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
