import React, { useState, useEffect, ReactElement } from "react";
import "./Checkbox.css";

export function Checkbox({
  title,
  value,
  onClick,
}: {
  onClick: (clicked: boolean) => void;
  title: string | ReactElement;
  value: boolean;
}) {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    onClick(checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <label
      className="checkbox-container"
      onClick={(e) => {
        e.preventDefault();
        setChecked(!checked);
      }}
    >
      {title}
      <input checked={checked} type="checkbox" />
      <span className="checkmark" />
    </label>
  );
}
