import React, { useState } from "react";
import { UserDashboardSidebar } from "./UserDashboardSidebar";
import "./UserDashboardCertificates.css";
import { useTranslation } from "react-i18next";
import { getLessons, Lesson } from "./api/LessonsApi";
import { getModules, Module } from "./api/ModulesApi";
import { getCurrentUser, getModuleCount, User } from "./api/UserApi";
import { useEffectOnce } from "react-use";
import { Loading } from "./Loading";
import { UserDashboardCertificatesBox } from "./UserDashboardCertificatesBox";

export function UserDashboardCertificates() {
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [modules, setModules] = useState<Module[]>([]);
  const [progressCount, setProgressCount] = useState<Record<string, number>>(
    {},
  );

  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation("user-dashboard");

  useEffectOnce(() => {
    const fetchModules = getModules().then(async (result) => {
      setModules(result);
    });

    const fetchCurrentUser = getCurrentUser().then((r) => {
      setUser(r);
    });

    const fetchLessons = getLessons().then((result) => {
      setLessons(result);
    });

    const fetchModuleCount = getModuleCount().then((result) => {
      setProgressCount(result);
    });

    Promise.all([
      fetchModules,
      fetchCurrentUser,
      fetchLessons,
      fetchModuleCount,
    ]).then(() => setLoading(false));
  });

  return (
    <UserDashboardSidebar>
      <div className="certificates-page">
        <h2>{t("userCertificates.title")}</h2>
        <div className="certificates-wrapper flex flex-column">
          {loading ? (
            <div className="flex justify-center items-center">
              <Loading />
            </div>
          ) : (
            modules.map((module) => {
              return (
                <UserDashboardCertificatesBox
                  lessons={lessons.filter(
                    (lesson) => lesson.moduleId === module.id,
                  )}
                  module={module}
                  progress={progressCount[module.id]}
                  user={user}
                />
              );
            })
          )}
        </div>
      </div>
    </UserDashboardSidebar>
  );
}
