import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type Challenge = {
  id: string;
  nameEN: string;
  nameFR: string;
  nameNL: string;
  textEN: string;
  textFR: string;
  textNL: string;
};

export type Score = {
  challengeId: string;
  gameId: string;
  name: string;
  score: number;
  userId: string;
};

type Game = {
  challengeId: string;
  max: number;
  name: string;
  updated: string;
};

export async function getChallenges(): Promise<Challenge[]> {
  const result = await fetch(`${API_ENDPOINT}/game/challenges`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.challenges) {
    return [];
  }

  return result.challenges;
}

export async function createChallenge(
  challenge: Challenge,
): Promise<Challenge> {
  const result = await fetch(`${API_ENDPOINT}/game/challenges`, {
    body: JSON.stringify(challenge),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}

export async function updateChallenge(
  challenge: Challenge,
): Promise<Challenge> {
  const result = await fetch(
    `${API_ENDPOINT}/game/challenges/${challenge.id}`,
    {
      body: JSON.stringify(challenge),
      headers: getHeaders(),
      method: "PUT",
    },
  ).then((r) => r.json());

  return result;
}

export async function deleteChallenge(challenge: Challenge): Promise<void> {
  await fetch(`${API_ENDPOINT}/game/challenges/${challenge.id}`, {
    headers: getHeaders(),
    method: "DELETE",
  });
}

export async function getScores(): Promise<Score[]> {
  const result = await fetch(`${API_ENDPOINT}/game/scores`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.scores) {
    return [];
  }

  return result.scores;
}

export async function getGame(
  code: string | null,
  name: string | null,
): Promise<Game> {
  const result = await fetch(
    `${API_ENDPOINT}/game/current?code=${code || ""}&name=${name || ""}`,
    {
      headers: getHeaders(),
      method: "GET",
    },
  ).then((r) => r.json());

  return result;
}

export async function getPreviousChallenge(): Promise<string> {
  const result = await fetch(`${API_ENDPOINT}/game/previous`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.challengeId;
}

export async function addScore(
  challengeId: string,
  score: number,
  name: string,
): Promise<string> {
  const result = await fetch(`${API_ENDPOINT}/game/scores`, {
    body: JSON.stringify({
      challengeId: challengeId,
      name: name,
      score: score,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result.challengeId;
}

export async function addChallenge(
  challengeId: string,
  score: number,
): Promise<string> {
  const result = await fetch(`${API_ENDPOINT}/game/update`, {
    body: JSON.stringify({
      challengeId: challengeId,
      score: score,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result.challengeId;
}

export async function startGame(
  max: number,
  email1: string,
  email2: string,
  email3: string,
  name: string,
): Promise<Game> {
  const result = await fetch(`${API_ENDPOINT}/game/start`, {
    body: JSON.stringify({
      email1: email1,
      email2: email2,
      email3: email3,
      max: max,
      name: name,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}

export async function stopGame(): Promise<void> {
  await fetch(`${API_ENDPOINT}/game/stop`, {
    headers: getHeaders(),
    method: "DELETE",
  }).then((r) => r.json());
}
