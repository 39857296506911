import React from "react";
import html2canvas from "html2canvas";
import { renderToStaticMarkup } from "react-dom/server";
import {
  BusinessStatistics,
  ModulesActivity,
  TestStatistics,
} from "../api/BusinessApi";
import { StatsBoxRow } from "../BusinessDashboard/StatsBoxRow";
import { Chart, ChartData, ChartType, ChartOptions } from "chart.js";
import { getModulesGraphData } from "../BusinessDashboard/ModulesGraph";
import { Sessions } from "../api/UserApi";
import { getSessionGraphData } from "../BusinessDashboard/SessionGraph";
import { getUsersGraphData } from "../BusinessDashboard/UsersGraph";
import { Module } from "../api/ModulesApi";
import { getActivityGraphData } from "../BusinessDashboard/ActivityGraph";
import { TestsTable } from "../BusinessDashboard/TestsGraph";
import { Test } from "../api/TestApi";
import { ResultCard } from "../FaceScanResults";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { TranslationResponseType } from "../i18n";

const CANVAS_STYLE_HEIGHT = "800px";
const CANVAS_STYLE_WIDTH = "1500px";
const FONT_COLOR = "#1F3B49";
const FONT_FAMILY = "Montserrat";

export async function createFaceScanResultImage(data: {
  adviceText: string;
  caretIcon: IconDefinition | null;
  color: string;
  gradient: string;
  iconSrc: string;
  onClick: () => void;
  open: boolean;
  resultText: string;
  textScore: string;
  title: string;
  value: number | null;
  valueMax: number;
  valueMin: number;
}) {
  const statsBoxRowElement = React.createElement(ResultCard, {
    ...data,
  });

  const resultBoxRowHtmlElement = document.createElement("div");

  // make sure image sizes are not screen dependent
  resultBoxRowHtmlElement.style.width = "420px";

  document.body.appendChild(resultBoxRowHtmlElement);

  resultBoxRowHtmlElement.innerHTML = renderToStaticMarkup(statsBoxRowElement);

  const statsBoxRowCanvas = await html2canvas(resultBoxRowHtmlElement);

  document.body.removeChild(resultBoxRowHtmlElement);

  return statsBoxRowCanvas.toDataURL("image/png");
}

function TimeBlock({ total }: { total: number }) {
  return (
    <div className="progress to-export">
      <div className="progress-block">
        <div className="time-circle">
          <div className="hours">{Math.floor(total / 60)} hr</div>
          <div className="minutes">{Math.floor(total % 60)} min</div>
        </div>
      </div>
    </div>
  );
}

export async function createTimeBoxImage(total: number) {
  const timeBlockElement = React.createElement(TimeBlock, {
    total,
  });

  const htmlElement = document.createElement("div");

  // make sure image sizes are not screen dependent
  htmlElement.style.width = "300px";
  htmlElement.style.height = "300px";

  document.body.appendChild(htmlElement);

  htmlElement.innerHTML = renderToStaticMarkup(timeBlockElement);

  const canvas = await html2canvas(htmlElement);

  document.body.removeChild(htmlElement);

  return canvas.toDataURL("image/png");
}

export async function createStatsBoxRowImage(
  stats: BusinessStatistics,
  premium: boolean,
) {
  const statsBoxRowElement = React.createElement(StatsBoxRow, {
    className: "pdf",
    premium,
    stats,
  });

  const statsBoxRowHtmlElement = document.createElement("div");

  // make sure image sizes are not screen dependent
  statsBoxRowHtmlElement.style.width = "1600px";
  statsBoxRowHtmlElement.style.height = "200px";

  document.body.appendChild(statsBoxRowHtmlElement);

  statsBoxRowHtmlElement.innerHTML = renderToStaticMarkup(statsBoxRowElement);

  const statsBoxRowCanvas = await html2canvas(statsBoxRowHtmlElement);

  document.body.removeChild(statsBoxRowHtmlElement);

  return statsBoxRowCanvas.toDataURL("image/png");
}

export async function createTextImage(text: string) {
  const htmlElement = document.createElement("div");

  // make sure image sizes are not screen dependent
  htmlElement.style.width = "800px";

  document.body.appendChild(htmlElement);

  htmlElement.innerHTML = text;

  const canvas = await html2canvas(htmlElement);

  document.body.removeChild(htmlElement);

  return canvas.toDataURL("image/png");
}

export async function createTestsTableImage(
  tests: Test[],
  testStats: TestStatistics[],
) {
  const statsBoxRowElement = React.createElement(TestsTable, {
    className: "pdf",
    testStats,
    tests,
  });

  const statsBoxRowHtmlElement = document.createElement("div");

  // make sure image sizes are not screen dependent
  statsBoxRowHtmlElement.style.maxWidth = "450px";

  document.body.appendChild(statsBoxRowHtmlElement);

  statsBoxRowHtmlElement.innerHTML = renderToStaticMarkup(statsBoxRowElement);

  const statsBoxRowCanvas = await html2canvas(statsBoxRowHtmlElement);

  document.body.removeChild(statsBoxRowHtmlElement);

  return statsBoxRowCanvas.toDataURL("image/png");
}

function createCanvas(width?: string, height?: string): HTMLElement[] {
  const div = document.createElement("div");
  const canvas = document.createElement("canvas");

  // make sure canvas size is not dependent on screen size
  div.style.width = width || CANVAS_STYLE_WIDTH;
  div.style.height = height || CANVAS_STYLE_HEIGHT;

  div.append(canvas);

  return [div, canvas];
}

function createChartImage<T extends ChartType>(
  chartType: T,
  chartData: ChartData<T, number[], string | number>,
  chartOptions: ChartOptions<T>,
  width?: string,
  height?: string,
): Promise<string> {
  // create and render canvas
  const [div, canvas] = createCanvas(width, height);

  // set chart font color
  Chart.defaults.color = FONT_COLOR;

  return new Promise((resolve) => {
    new Chart(canvas as HTMLCanvasElement, {
      data: chartData,
      options: {
        ...chartOptions,
        animation: {
          duration: 0,
          onComplete: (event) => {
            resolve(event.chart.toBase64Image());
            document.body.removeChild(div);
          },
        },
      },
      type: chartType,
    });

    document.body.appendChild(div);
  });
}

export async function createModulesGraphImage(
  modules: Module[],
  stats: BusinessStatistics,
  translationResponse: TranslationResponseType,
): Promise<string> {
  const chartType = "bar";
  const modulesGraphData = getModulesGraphData(
    modules,
    stats,
    translationResponse,
  );

  const { t } = translationResponse;

  return createChartImage<typeof chartType>(chartType, modulesGraphData, {
    barThickness: 30,
    indexAxis: "y",
    plugins: {
      legend: {
        display: false,
        labels: {
          font: {
            size: 20,
          },
        },
      },
      title: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: FONT_FAMILY,
            size: 45,
          },
        },
        title: {
          display: true,
          font: {
            family: FONT_FAMILY,
            size: 45,
          },
          text: t("modulesGraph.xAxisTitle"),
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: FONT_FAMILY,
            size: 45,
          },
        },
      },
    },
  } as unknown as ChartOptions<typeof chartType>);
}

export async function createSessionGraphImage(
  sessions: Sessions,
): Promise<string> {
  const chartType = "line";
  const sessionGraphData = getSessionGraphData(sessions);

  return createChartImage<typeof chartType>(
    chartType,
    sessionGraphData,
    {
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
      responsive: true,
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: FONT_FAMILY,
              size: 35,
            },
          },
        },
        y: {
          grid: {
            display: false,
          },
          max: Math.max(
            Math.round(
              Math.max(
                ...(sessions.sessionsPerDay.map((item) => item.sessions) || [
                  0,
                ]),
              ) * 1.2,
            ),
            10,
          ),
          min: 0,
          ticks: {
            font: {
              family: FONT_FAMILY,
              size: 45,
            },
            stepSize: 1,
          },
        },
      },
    },
    "1800px",
    "1000px",
  );
}

export async function createUsersGraphImage(
  stats: BusinessStatistics,
): Promise<string> {
  const chartType = "line";
  const usersGraphData = getUsersGraphData(stats);

  return createChartImage<typeof chartType>(chartType, usersGraphData, {
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: FONT_FAMILY,
            size: 45,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        suggestedMax:
          Math.max(
            ...usersGraphData.datasets.map((item) => Math.max(...item.data)),
          ) + 1,
        suggestedMin: 0,
        ticks: {
          font: {
            family: FONT_FAMILY,
            size: 45,
          },
          stepSize: 1,
        },
      },
    },
  });
}

export async function createActivityGraphImage(
  modulesActivity: ModulesActivity,
  translationResponse: TranslationResponseType,
): Promise<string> {
  const chartType = "doughnut";
  const activityGraphData = getActivityGraphData(
    modulesActivity,
    translationResponse,
  );

  // return promise which resolves chart converted to base64 image
  return createChartImage<typeof chartType>(chartType, activityGraphData, {
    aspectRatio: 1,
    circumference: 180,
    cutout: "80%",
    plugins: {
      legend: {
        labels: {
          font: {
            family: FONT_FAMILY,
            size: 55,
          },
          padding: 40,
          usePointStyle: true,
        },
        position: "bottom" as const,
      },
    },
    responsive: true,
    rotation: -90,
  });
}
