import { API_ENDPOINT, getHeaders } from "./AuthApi";

type Setting = {
  orientationTestId: string;
};

export async function getSettings(): Promise<Setting> {
  const result = await fetch(`${API_ENDPOINT}/settings`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.settings;
}

export async function updateSettings(setting: Setting): Promise<Setting> {
  const result = await fetch(`${API_ENDPOINT}/settings`, {
    body: JSON.stringify(setting),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result.settings;
}
