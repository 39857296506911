import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import ToolboxImage from "./img/toolbox.png";
import { useLocation } from "react-use";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

export function ToolboxHeader({ children }: PropsWithChildren<any>) {
  const location = useLocation();
  const { t } = useTranslation("general");

  return (
    <div className="toolbox">
      {location.pathname !== "/toolbox" ? (
        <Link
          style={{
            color: "#ffbd4a",
            marginTop: "20px",
            position: "absolute",
            right: "20px",
          }}
          to="/toolbox"
        >
          {t("back")}
        </Link>
      ) : null}
      <div className="toolbox-header-container wrap">
        <img alt="toolbox" src={ToolboxImage} />
        <h1 className="toolbox-title">TOOLBOX</h1>
      </div>
      <div className={`flex wrap ${isMobile ? "" : "container"}`}>
        {children}
      </div>
    </div>
  );
}
