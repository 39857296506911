import React from "react";

export function EngiePrivacyNotice({ onClose }: { onClose: () => void }) {
  return (
    <div>
      <p>
        All ENGIE legal entities in Belgium have jointly decided to deploy the
        tool Mindlab for the benefit of their employees. ENGIE legal entities in
        Belgium act therefore as Joint Controllers according to art. 26 GDPR.
        The Joint Controllers are represented by:
      </p>
      <p>Electrabel NV/SA</p>
      <p>Simon Bolivarlaan 34, 1000 Brussels</p>
      <p>BE 0403.170.701.</p>
      <p>Mindlab acts as Processor under art. 28 GDPR.</p>
      <p>
        Mindlab aims to stimulate a proactive well-being approach. Employees can
        make facultatively use of the tool to discover their mental health, take
        preventive actions and overcome deficiencies.
      </p>
      <p>In using the tool, the following Personal Data will be Processed : </p>
      <ol>
        <li>
          Normal Personal Data: Name, Surname, email address and company for the
          creation of an account
        </li>
        <li>
          Special Categories of Personal Data : Information related to your
          mental health when using the toolkit (for instance : stress level,
          life habits, leisure activities, sleep quality, sport activities, …){" "}
        </li>
      </ol>
      <p>
        The ENGIE legal entities Process this Data on the basis of a legal
        obligation (art. 6.1c GDPR) and for the purposes of preventive or
        occupational medicine (art. 9.2 h GDPR), namely the Belgian law for
        wellbeing in the workplace (2), enabling the employer to initiate
        preventive actions to pursue an adequate level of mental wellbeing
        within the workforce.
      </p>
      <p>
        Your Personal Data are kept as long as ENGIE has a licence with Mindlab.{" "}
      </p>
      <p>
        ENGIE entities do not have access to the Personal Data (both 1 and 2).
        There will a statistical reporting only based on numbers and without
        disclosure of the names of the employees using the tool to the HR
        partner of each ENGIE entity.
      </p>
      <p>
        Mindlab has restricted access to the Personal Data by limiting the
        number of admin accounts and ‘managed accounts’. They are bound by a
        Data Processing Agreement to all the required obligations for Processors
        under art. 28 GDPR.
      </p>
      <p>
        Data under 1 above may be subject to data transfer outside the European
        Union and in particular to the United States. This concerns newsletters
        sent via tool Mailerlite. The latter has signed SSC’s with Mindlab
        according to art. 46.2 c GDPR.
      </p>
      <p>
        As a Data Subject, you can exercise several rights regarding your
        Processed Personal Data, as laid down in the GDPR:{" "}
      </p>
      <ul>
        <li>The right of access to your personal data; </li>
        <li>The right to rectification of your personal data; </li>
        <li>
          The right to erasure of your personal data (‘right to be forgotten’);{" "}
        </li>
        <li>The right to data portability;</li>
        <li>The right to restriction of processing; </li>
        <li>The right to object. </li>
      </ul>
      <p>
        You can submit your request through our Data Protection Portal at{" "}
        <a
          href="https://www.engie-benelux-privacy.com/en/"
          rel="noreferrer"
          target="_blank"
        >
          https://www.engie-benelux-privacy.com/en/
        </a>
      </p>
      <p>
        You also have to right to file a complaint with the Data Protection
        Authority (GBA – APD):{" "}
        <a href="www.GegevensBeschermingsAutoriteit.be" target="_blank">
          www.GegevensBeschermingsAutoriteit.be
        </a>
      </p>
      <br />
      <br />
      <br />
      <p>
        (1) All words with Caps have the meaning as described in REGULATION (EU)
        2016/679 OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL of 27 April 2016
        on the protection of natural persons with regard to the processing of
        personal data and on the free movement of such data, and repealing
        Directive 95/46/EC (General Data Protection Regulation )
      </p>
      <p>
        (2) The Law of 4 August 1996 on the well-being of employees in the
        performance of their work, BS 18-09-1996
      </p>

      <div className="button ml-auto w-fit yellow" onClick={onClose}>
        I've read and accept the privacy notice
      </div>
    </div>
  );
}
