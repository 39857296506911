import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  addModuleExerciseResult,
  getModuleExerciseResult,
} from "../api/UserApi";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";
import Vlaggen from "../img/vlaggen.jpg";

export function VlaggenForm({ id }: { id: string }) {
  const { lessonId } = useParams<{ lessonId: string }>();
  const [savedInput1, setSavedInput1] = useState<string>("");
  const [savedInput2, setSavedInput2] = useState<string>("");
  const [savedInput3, setSavedInput3] = useState<string>("");
  const [savedInput4, setSavedInput4] = useState<string>("");
  const [savedInput5, setSavedInput5] = useState<string>("");
  const [saved, setSaved] = useState(false);

  const { t, i18n } = useTranslation("general");

  useEffectOnce(() => {
    getModuleExerciseResult(lessonId).then((result) => {
      const currentResult1 = result.find((r) => r.key === `VlaggenForm1-${id}`);

      if (currentResult1) {
        try {
          setSavedInput1(JSON.parse(currentResult1.result));
        } catch {
          // Nothing
        }
      }

      const currentResult2 = result.find((r) => r.key === `VlaggenForm2-${id}`);

      if (currentResult2) {
        try {
          setSavedInput2(JSON.parse(currentResult2.result));
        } catch {
          // Nothing
        }
      }

      const currentResult3 = result.find((r) => r.key === `VlaggenForm3-${id}`);

      if (currentResult3) {
        try {
          setSavedInput3(JSON.parse(currentResult3.result));
        } catch {
          // Nothing
        }
      }

      const currentResult4 = result.find((r) => r.key === `VlaggenForm4-${id}`);

      if (currentResult4) {
        try {
          setSavedInput4(JSON.parse(currentResult4.result));
        } catch {
          // Nothing
        }
      }

      const currentResult5 = result.find((r) => r.key === `VlaggenForm5-${id}`);

      if (currentResult5) {
        try {
          setSavedInput5(JSON.parse(currentResult5.result));
        } catch {
          // Nothing
        }
      }
    });
  });

  return (
    <>
      <div className="flex justify-around">
        <div style={{ maxWidth: "20%" }}>
          <span>
            {i18n.language === "nl" && "Niveau 2"}
            {i18n.language === "en" && "Level 2"}
            {i18n.language === "fr" && "Niveau 2"}
          </span>
          <textarea
            onChange={(e) => {
              setSavedInput2(e.target.value);
            }}
            rows={6}
            style={{ width: "100%" }}
            value={savedInput2}
          />
        </div>
        <div style={{ maxWidth: "20%" }}>
          <span>
            {i18n.language === "nl" && "Niveau 4"}
            {i18n.language === "en" && "Level 4"}
            {i18n.language === "fr" && "Niveau 4"}
          </span>
          <textarea
            onChange={(e) => {
              setSavedInput4(e.target.value);
            }}
            rows={6}
            style={{ width: "100%" }}
            value={savedInput4}
          />
        </div>
      </div>
      <img src={Vlaggen} style={{ width: "100%" }} />
      <div className="flex justify-around">
        <div style={{ maxWidth: "20%" }}>
          <span>
            {i18n.language === "nl" && "Niveau 1"}
            {i18n.language === "en" && "Level 1"}
            {i18n.language === "fr" && "Niveau 1"}
          </span>
          <textarea
            onChange={(e) => {
              setSavedInput1(e.target.value);
            }}
            rows={6}
            style={{ width: "100%" }}
            value={savedInput1}
          />
        </div>
        <div style={{ maxWidth: "20%" }}>
          <span>
            {i18n.language === "nl" && "Niveau 3"}
            {i18n.language === "en" && "Level 3"}
            {i18n.language === "fr" && "Niveau 3"}
          </span>
          <textarea
            onChange={(e) => {
              setSavedInput3(e.target.value);
            }}
            rows={6}
            style={{ width: "100%" }}
            value={savedInput3}
          />
        </div>
        <div style={{ maxWidth: "20%" }}>
          <span>
            {i18n.language === "nl" && "Niveau 5"}
            {i18n.language === "en" && "Level 5"}
            {i18n.language === "fr" && "Niveau 5"}
          </span>
          <textarea
            onChange={(e) => {
              setSavedInput5(e.target.value);
            }}
            rows={6}
            style={{ width: "100%" }}
            value={savedInput5}
          />
        </div>
      </div>
      {saved ? <div className="green mt-4 mb-4">{t("saved")}!</div> : null}
      <div
        className="button w-fit mb-4"
        onClick={async (e) => {
          e.preventDefault();

          await addModuleExerciseResult(
            lessonId,
            `VlaggenForm1-${id}`,
            JSON.stringify(savedInput1),
          );

          await addModuleExerciseResult(
            lessonId,
            `VlaggenForm2-${id}`,
            JSON.stringify(savedInput2),
          );

          await addModuleExerciseResult(
            lessonId,
            `VlaggenForm3-${id}`,
            JSON.stringify(savedInput3),
          );

          await addModuleExerciseResult(
            lessonId,
            `VlaggenForm4-${id}`,
            JSON.stringify(savedInput4),
          );

          await addModuleExerciseResult(
            lessonId,
            `VlaggenForm5-${id}`,
            JSON.stringify(savedInput5),
          );

          setSaved(true);
          setTimeout(() => {
            setSaved(false);
          }, 3000);
        }}
      >
        {t("bewaar")}
      </div>
    </>
  );
}
