import React from "react";
import { FaceScanner } from "../FaceScanner";

export function IntelliProveScan({ results }: { results: string }) {
  const resultsToShow = results.split(",");

  return (
    <div className="embedded">
      <FaceScanner
        embedded={true}
        itemsToShow={resultsToShow.map((item) => parseInt(item))}
        onRequestClose={() => {
          // Nothing
        }}
        periodic={false}
      />
    </div>
  );
}
