import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import Select from "react-select";
import {
  createDocument,
  DashboardDocument,
  updateDocument,
} from "../api/DashboardDocumentsApi";
import { DocumentType } from "../Utils";

export function DashboardDocumentForm({
  document,
}: {
  document: DashboardDocument | null;
}) {
  const [name, setName] = useState(document ? document.name : "");
  const [type, setType] = useState(document ? document.type : "");
  const [url, setUrl] = useState(document ? document.url : "");

  const [saved, setSaved] = useState(false);

  if (saved) {
    return <Navigate to={"/dashboard/dashboard-documents"} />;
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        if (document) {
          await updateDocument(document.id, url, name, type);
        } else {
          await createDocument(url, name, type);
        }

        setSaved(true);
      }}
    >
      <div className="flex justify-evenly pb-4">
        <div className="w-25">
          <div>
            <h4>Algemene info</h4>
            <label>Naam</label>
            <input
              className="input"
              onChange={(input) => {
                setName(input.target.value);
              }}
              placeholder="Naam"
              required={true}
              type="text"
              value={name}
            />
            <label>Url</label>
            <input
              className="input"
              onChange={(input) => {
                setUrl(input.target.value);
              }}
              placeholder="Url"
              required={true}
              type="text"
              value={url}
            />
            <label>Type</label>
            <Select
              className="input"
              onChange={(e: any) => {
                setType(e.value);
              }}
              options={[
                {
                  label: "",
                  value: "",
                },
                {
                  label: DocumentType.MAILTEMPLATES,
                  value: DocumentType.MAILTEMPLATES,
                },
                {
                  label: DocumentType.BEST_PRACTICES,
                  value: DocumentType.BEST_PRACTICES,
                },
                {
                  label: DocumentType.DRAAIBOEK,
                  value: DocumentType.DRAAIBOEK,
                },
                {
                  label: DocumentType.BROCHURES,
                  value: DocumentType.BROCHURES,
                },
                {
                  label: DocumentType.VISUALS_AND_GRAPHICS,
                  value: DocumentType.VISUALS_AND_GRAPHICS,
                },
                {
                  label: DocumentType.VARIA,
                  value: DocumentType.VARIA,
                },
              ]}
              placeholder={"Type"}
              required={true}
              value={{ label: type, value: type }}
            />

            <button className="button" type="submit">
              {document ? "Document bijwerken" : "Document toevoegen"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
