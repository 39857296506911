import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type Lesson = {
  contentEN: string;
  contentFR: string;
  contentNL: string;
  id: string;
  moduleId: string;
  nameEN: string;
  nameFR: string;
  nameNL: string;
  pos: number;
  subjectId: string;
  type: string;
};

export type ResultType = {
  id: string;
  key: string;
  lessonId: string;
  result: string;
};

export async function getLessons(): Promise<Lesson[]> {
  const result = await fetch(`${API_ENDPOINT}/lessons`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.lessons) {
    return [];
  }

  return result.lessons;
}

export async function getLessonsByModuleAndSubject(
  moduleId: string,
  subjectId: string,
): Promise<Lesson[]> {
  const result = await fetch(
    `${API_ENDPOINT}/modules/${moduleId}/subjects/${subjectId}/lessons`,
    {
      headers: getHeaders(),
      method: "GET",
    },
  ).then((r) => r.json());

  if (!result.lessons) {
    return [];
  }

  return result.lessons;
}

export async function createLessons(
  moduleId: string,
  subjectId: string,
  lesson: Lesson,
): Promise<Lesson> {
  const result = await fetch(
    `${API_ENDPOINT}/modules/${moduleId}/subjects/${subjectId}/lessons`,
    {
      body: JSON.stringify(lesson),
      headers: getHeaders(),
      method: "POST",
    },
  ).then((r) => r.json());

  return result;
}

export async function updateLesson(
  moduleId: string,
  subjectId: string,
  lesson: Lesson,
): Promise<Lesson> {
  const result = await fetch(
    `${API_ENDPOINT}/modules/${moduleId}/subjects/${subjectId}/lessons/${lesson.id}`,
    {
      body: JSON.stringify(lesson),
      headers: getHeaders(),
      method: "PUT",
    },
  ).then((r) => r.json());

  return result;
}

export async function deleteLesson(
  moduleId: string,
  subjectId: string,
  lesson: Lesson,
): Promise<Lesson> {
  const result = await fetch(
    `${API_ENDPOINT}/modules/${moduleId}/subjects/${subjectId}/lessons/${lesson.id}`,
    {
      headers: getHeaders(),
      method: "DELETE",
    },
  ).then((r) => r.json());

  return result;
}

export async function getLessonById(lessonId: string): Promise<Lesson> {
  const result = await fetch(
    `${API_ENDPOINT}/modules/subjects/lessons/${lessonId}`,
    {
      headers: getHeaders(),
      method: "GET",
    },
  ).then((r) => r.json());

  return result.lesson;
}

export async function getLesson(
  moduleId: string,
  subjectId: string,
  lessonId: string,
): Promise<Lesson> {
  const result = await fetch(
    `${API_ENDPOINT}/modules/${moduleId}/subjects/${subjectId}/lessons/${lessonId}`,
    {
      headers: getHeaders(),
      method: "GET",
    },
  ).then((r) => r.json());

  return result.lesson;
}

export async function getResults(): Promise<ResultType[]> {
  const result = await fetch(`${API_ENDPOINT}/users/exercises/results`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.results;
}
