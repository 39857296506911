import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type SettingType = {
  disableTests: boolean;
  enforce2FA: boolean;
  inactiveSiteLink: string;
};

type BusinessSetting = {
  businessId: string;
  settings: SettingType;
};

export async function getBusinessSettings(
  businessId: string,
): Promise<BusinessSetting> {
  const result = await fetch(
    `${API_ENDPOINT}/business-settings/${businessId}`,
    {
      headers: getHeaders(),
      method: "GET",
    },
  ).then((r) => r.json());

  return result.settings;
}

export async function upsertBusinessSettings(
  setting: BusinessSetting,
): Promise<BusinessSetting> {
  const result = await fetch(
    `${API_ENDPOINT}/business-settings/${setting.businessId}`,
    {
      body: JSON.stringify(setting),
      headers: getHeaders(),
      method: "POST",
    },
  ).then((r) => r.json());

  return result.settings;
}
