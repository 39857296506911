import React, { useState } from "react";
import { Sidebar } from "../../Sidebar";
import { SubjectForm } from "./SubjectForm";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { getSubject, Subject } from "../../api/SubjectsApi";

export function EditSubject() {
  const { moduleId, subjectId } = useParams<{
    moduleId: string;
    subjectId: string;
  }>();

  const [subject, setSubject] = useState<Subject | null>(null);

  useEffectOnce(() => {
    getSubject(moduleId, subjectId).then((r) => {
      setSubject(r);
    });
  });

  if (!subject) {
    return null;
  }

  return (
    <Sidebar>
      <SubjectForm subject={subject} />
    </Sidebar>
  );
}
