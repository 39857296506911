import React from "react";
import ReactModal from "react-modal";
import "./FaceScannerModal.css";
import { FaceScanner } from "./FaceScanner";

export function FaceScannerModal({
  isOpen,
  onRequestClose,
  periodic,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
  periodic: boolean; // whether scan is a periodic scan or scan started by other event (e.g. user chose to scan themselves or as part of exercise)
}) {
  return (
    <ReactModal
      appElement={window.document.getElementById("root") as HTMLElement}
      bodyOpenClassName="scanner-open"
      className="scanner-body"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="scanner-overlay"
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
    >
      <FaceScanner onRequestClose={onRequestClose} periodic={periodic} />
    </ReactModal>
  );
}
