import React from "react";
import "./StatsBox.css";
import PremiumIcon from "../img/Premium.svg";

export function StatsBox({
  title,
  icon,
  className,
  number,
  premium = false,
  locked = false,
}: {
  className: string;
  icon: string;
  locked?: boolean;
  number?: string;
  premium?: boolean;
  title: string;
}) {
  return (
    <div>
      <div className={`small-stats-box ${className} ${locked ? "locked" : ""}`}>
        <div className={"flex justify-center items-center"}>
          <img alt={""} className={"icon"} src={icon} />
          <div className={"flex flex-column ml-3"}>
            {number ? <div className={"number"}>{number}</div> : null}
            <div className={"text"}>{title}</div>
          </div>
        </div>
      </div>
      {premium ? (
        <img alt={""} className={"premium"} src={PremiumIcon} />
      ) : null}
    </div>
  );
}
