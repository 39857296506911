import React, { Fragment } from "react";
import "./Modules.css";
import { ModulesList } from "./ModulesList";

import { Footer } from "../../Footer";

export function Modules({ authenticated }: { authenticated: boolean }) {
  return (
    <Fragment>
      <ModulesList skills={false} />
      <Footer authenticated={authenticated} />
    </Fragment>
  );
}
