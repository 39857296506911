import React from "react";
import Camera from "./img/camera.png";
import "./StartFaceScan.css";

export function StartFaceScan({ onClick }: { onClick: () => void }) {
  return (
    <div className="start-scan" onClick={onClick}>
      <img alt="Facescan" src={Camera} />
    </div>
  );
}
