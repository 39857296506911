import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { createPodcast, Podcast, updatePodcast } from "../api/PodcastApi";
import { addMedia } from "../api/MediaApi";

export function PodcastForm({ podcast }: { podcast: Podcast | null }) {
  const [name, setName] = useState(podcast ? podcast.name : "");
  const [description, setDescription] = useState(
    podcast ? podcast.description : "",
  );

  const image = podcast ? podcast.image : "";
  const [nameEN, setNameEN] = useState(podcast ? podcast.nameEN : "");
  const [descriptionEN, setDescriptionEN] = useState(
    podcast ? podcast.descriptionEN : "",
  );

  const audioEN = podcast ? podcast.audioEN : "";
  const [nameFR, setNameFR] = useState(podcast ? podcast.nameFR : "");
  const [descriptionFR, setDescriptionFR] = useState(
    podcast ? podcast.descriptionFR : "",
  );

  const audioFR = podcast ? podcast.audioFR : "";
  const audio = podcast ? podcast.audio : "";

  const [selectedImageFile, setSelectedImageFile] = useState<File | null>();
  const [selectedAudioFile, setSelectedAudioFile] = useState<File | null>();
  const [selectedAudioFileEN, setSelectedAudioFileEN] = useState<File | null>();
  const [selectedAudioFileFR, setSelectedAudioFileFR] = useState<File | null>();

  const [saved, setSaved] = useState(false);

  if (saved) {
    return <Navigate to={"/dashboard/podcasts"} />;
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        let updatedBusiness = {
          audio: audio,
          audioEN: audioEN,
          audioFR: audioFR,
          description: description,
          descriptionEN: descriptionEN,
          descriptionFR: descriptionFR,
          image: image,
          name: name,
          nameEN: nameEN,
          nameFR: nameFR,
        };

        if (selectedImageFile) {
          const pathResult = await addMedia(selectedImageFile);

          if (pathResult && typeof pathResult !== "string" && pathResult.path) {
            updatedBusiness = {
              ...updatedBusiness,
              image: pathResult.path,
            };
          }
        }

        if (selectedAudioFile) {
          const pathResult = await addMedia(selectedAudioFile);

          if (pathResult && typeof pathResult !== "string" && pathResult.path) {
            updatedBusiness = {
              ...updatedBusiness,
              audio: pathResult.path,
            };
          }
        }

        if (selectedAudioFileEN) {
          const pathResult = await addMedia(selectedAudioFileEN);

          if (pathResult && typeof pathResult !== "string" && pathResult.path) {
            updatedBusiness = {
              ...updatedBusiness,
              audioEN: pathResult.path,
            };
          }
        }

        if (selectedAudioFileFR) {
          const pathResult = await addMedia(selectedAudioFileFR);

          if (pathResult && typeof pathResult !== "string" && pathResult.path) {
            updatedBusiness = {
              ...updatedBusiness,
              audioFR: pathResult.path,
            };
          }
        }

        if (podcast) {
          updatePodcast({
            ...updatedBusiness,
            id: podcast.id,
          }).then(async () => {
            setSaved(true);
          });
        } else {
          createPodcast({
            id: "",
            ...updatedBusiness,
          }).then(async () => {
            setSaved(true);
          });
        }
      }}
    >
      <div className="flex justify-evenly pb-4">
        <div className="w-25">
          <div>
            <h4>Algemene info</h4>
            <label>Naam</label>
            <input
              className="input"
              onChange={(input) => {
                setName(input.target.value);
              }}
              placeholder="Naam"
              required={true}
              type="text"
              value={name}
            />
            <label>Beschrijving</label>
            <input
              className="input"
              onChange={(input) => {
                setDescription(input.target.value);
              }}
              placeholder="Naam"
              required={true}
              type="text"
              value={description}
            />
            <label>Afbeelding</label>
            <input
              className="input"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  setSelectedImageFile(e.target.files[0]);
                }
              }}
              placeholder="Upload afbeelding"
              type="file"
            />
            <label>Audio</label>
            <input
              className="input"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  setSelectedAudioFile(e.target.files[0]);
                }
              }}
              placeholder="Upload audio"
              type="file"
            />

            <label>Naam EN</label>
            <input
              className="input"
              onChange={(input) => {
                setNameEN(input.target.value);
              }}
              placeholder="Naam EN"
              type="text"
              value={nameEN}
            />
            <label>Beschrijving EN</label>
            <input
              className="input"
              onChange={(input) => {
                setDescriptionEN(input.target.value);
              }}
              placeholder="Beschrijving EN"
              type="text"
              value={descriptionEN}
            />
            <label>Audio EN</label>
            <input
              className="input"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  setSelectedAudioFileEN(e.target.files[0]);
                }
              }}
              placeholder="Upload audio EN"
              type="file"
            />

            <label>Naam FR</label>
            <input
              className="input"
              onChange={(input) => {
                setNameFR(input.target.value);
              }}
              placeholder="Naam FR"
              type="text"
              value={nameFR}
            />
            <label>Beschrijving FR</label>
            <input
              className="input"
              onChange={(input) => {
                setDescriptionFR(input.target.value);
              }}
              placeholder="Beschrijving FR"
              type="text"
              value={descriptionFR}
            />
            <label>Audio FR</label>
            <input
              className="input"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  setSelectedAudioFileFR(e.target.files[0]);
                }
              }}
              placeholder="Upload audio FR"
              type="file"
            />

            <button className="button" type="submit">
              {podcast ? "Podcast bijwerken" : "Podcast toevoegen"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
