import React from "react";
import { BusinessSidebar } from "../BusinessSidebar";
import "./BusinessDocuments.css";
import MailtemplatesIcon from "../img/mailtemplates.svg";
import DraaiboekIcon from "../img/draaiboek.svg";
import BrochuresIcon from "../img/brochures.svg";
import VisualsIcon from "../img/visuals.svg";
import ActionIcon from "../img/action.svg";
import { DocumentType } from "../Utils";
import { DocumentBox } from "../DocumentBox";
import { useTranslation } from "react-i18next";

export function BusinessDocuments() {
  const { t } = useTranslation("hr-dashboard");

  return (
    <BusinessSidebar>
      <div className="business-documents">
        <div className="documents-title">{t("businessDocuments.title")}</div>
        <div className="boxes">
          <DocumentBox
            className={"mailtemplates"}
            icon={MailtemplatesIcon}
            name={t("businessDocuments.mailTemplates")}
            type={DocumentType.MAILTEMPLATES}
          />
          {/*
          <DocumentBox
            type={DocumentType.BEST_PRACTICES}
            name={t("businessDocuments.bestPractices")}
            icon={PracticesIcon}
            className={"practices"}
          />
          */}
          <DocumentBox
            className={"draaiboek"}
            icon={DraaiboekIcon}
            name={t("businessDocuments.script")}
            type={DocumentType.DRAAIBOEK}
          />
          <DocumentBox
            className={"brochures"}
            icon={BrochuresIcon}
            name={t("businessDocuments.brochures")}
            type={DocumentType.BROCHURES}
          />
          <DocumentBox
            className={"visuals"}
            icon={VisualsIcon}
            name={t("businessDocuments.visualsAndGraphics")}
            type={DocumentType.VISUALS_AND_GRAPHICS}
          />
          <DocumentBox
            className={"varia"}
            icon={ActionIcon}
            name={t("businessDocuments.varia")}
            type={DocumentType.VARIA}
          />
        </div>
      </div>
    </BusinessSidebar>
  );
}
