import React, { useState } from "react";
import { Sidebar } from "../Sidebar";
import { useEffectOnce } from "react-use";
import { Navigate } from "react-router-dom";
import { FaPlus, FaEye, FaPen, FaTimes } from "react-icons/fa";
import { User, listUsers, deleteUser, getUserToken } from "../api/UserApi";
import { Dialog } from "../Dialog";

export function Users() {
  const [users, setUsers] = useState<User[]>([]);
  const [addingUser, setAddingUser] = useState(false);
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [deletingUser, setDeletingUser] = useState<User | null>(null);
  const [filter, setFilter] = useState("");

  useEffectOnce(() => {
    listUsers().then((r) => {
      setUsers(r);
    });
  });

  if (addingUser) {
    return <Navigate to="/dashboard/users/new" />;
  }

  if (editingUser) {
    return <Navigate to={`/dashboard/users/${editingUser.id}`} />;
  }

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>Gebruikers</h2>
          <div
            className="button small ml-auto"
            onClick={() => {
              setAddingUser(true);
            }}
          >
            <FaPlus />
          </div>
        </div>

        <input
          className="input"
          onChange={(input) => {
            setFilter(input.target.value);
          }}
          placeholder={"Naam"}
          type="text"
          value={filter}
        />

        {users.length === 0 ? (
          "Momenteel nog geen gebruikers beschikbaar"
        ) : (
          <table className="table ">
            <thead>
              <tr>
                <th>Naam</th>
                <th>Email</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {users
                .filter(
                  (u) =>
                    `${u.firstName} ${u.lastName}`
                      .toLowerCase()
                      .includes(filter.toLowerCase()) ||
                    u.email.toLowerCase().includes(filter.toLowerCase()),
                )
                .map((user) => {
                  return (
                    <tr key={user.id}>
                      <td>
                        {user.firstName} {user.lastName}
                      </td>
                      <td>{user.email}</td>
                      <td>
                        <div className="flex">
                          <div
                            className="button small w-fit ml-auto"
                            onClick={async () => {
                              localStorage.setItem(
                                "admintoken",
                                localStorage.getItem("token") || "",
                              );
                              const result = await getUserToken(user.id);

                              if (result) {
                                window.location.href = "/home";
                              }
                            }}
                          >
                            <FaEye />
                          </div>
                          <div
                            className="button small w-fit ml-5 yellow"
                            onClick={() => {
                              setEditingUser(user);
                            }}
                          >
                            <FaPen />
                          </div>
                          <div
                            className="button small w-fit ml-5 pink"
                            onClick={async () => {
                              setDeletingUser(user);
                            }}
                          >
                            <FaTimes />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
      <Dialog
        isOpen={deletingUser !== null}
        onRequestClose={() => {
          setDeletingUser(null);
        }}
        padding={true}
        size={"small"}
        title={"Gebruiker verwijderen"}
      >
        <div>
          <div>Bent u zeker dat u deze gebruiker wilt verwijderen?</div>
          <div
            className="button w-fit pink"
            onClick={async () => {
              if (!deletingUser) {
                return;
              }

              await deleteUser(deletingUser.id);
              setUsers(users.filter((u) => u.id !== deletingUser.id));
              setDeletingUser(null);
            }}
          >
            Verwijderen
          </div>
        </div>
      </Dialog>
    </Sidebar>
  );
}
