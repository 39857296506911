import React, { PropsWithChildren } from "react";
import "./Sidebar.css";
import { NavLink } from "react-router-dom";

export function Sidebar({ children }: PropsWithChildren<any>) {
  return (
    <div className="flex sidebar-container">
      <div className="sidebar flex flex-column">
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          end
          to="/dashboard"
        >
          Overzicht
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/dashboard-documents"
        >
          Dashboard documents
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/about-us"
        >
          About us
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/landingpage"
        >
          Landingpage
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/faq"
        >
          FAQ
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/test-types"
        >
          Test Types
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/tests"
        >
          Testen
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/users"
        >
          Gebruikers
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/challenges"
        >
          Challenges
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/discounts"
        >
          Discounts
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/businesses"
        >
          Bedrijfscodes
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/logos"
        >
          Logos
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/partners"
        >
          Partners
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/testimonials"
        >
          Testimonials
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/modules"
        >
          Modules
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/lists"
        >
          Lijsten
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/podcasts"
        >
          Podcast
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/blogs"
        >
          Blog
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/quizzes"
        >
          Quizzen
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `sidebar-item ${isActive ? "active" : ""}`
          }
          to="/dashboard/media"
        >
          Media
        </NavLink>
      </div>
      <div className="content">{children}</div>
    </div>
  );
}
