import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  addModuleExerciseResult,
  getModuleExerciseResult,
} from "../api/UserApi";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";

export function TableForm({ columns, id }: { columns: string[]; id: string }) {
  const { lessonId } = useParams<{ lessonId: string }>();
  const [savedInput, setSavedInput] = useState<Record<string, string>>({});
  const [saved, setSaved] = useState(false);

  const { t } = useTranslation("general");

  useEffectOnce(() => {
    getModuleExerciseResult(lessonId).then((result) => {
      const currentResult = result.find((r) => r.key === `TableForm-${id}`);

      if (currentResult) {
        setSavedInput(JSON.parse(currentResult.result));
      }
    });
  });

  return (
    <div>
      <table className="w-100">
        <thead>
          {columns.map((item) => {
            return <th>{item}</th>;
          })}
        </thead>
        <tbody>
          <tr>
            {columns.map((item) => {
              return (
                <td>
                  <textarea
                    onChange={(e) => {
                      const tmp = { ...savedInput };

                      tmp[item] = e.target.value;
                      setSavedInput(tmp);
                    }}
                    rows={6}
                    style={{ width: "100%" }}
                    value={savedInput[item]}
                  />
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
      {saved ? <div className="green mt-4 mb-4">{t("saved")}!</div> : null}
      <div
        className="button w-fit mb-4"
        onClick={async (e) => {
          e.preventDefault();

          await addModuleExerciseResult(
            lessonId,
            `TableForm-${id}`,
            JSON.stringify(savedInput),
          );

          setSaved(true);
          setTimeout(() => {
            setSaved(false);
          }, 3000);
        }}
      >
        {t("bewaar")}
      </div>
    </div>
  );
}
