import React, { useRef } from "react";
import { BusinessStatistics } from "../api/BusinessApi";
import { Line } from "react-chartjs-2";
import TooltipIcon from "../img/tooltipIcon.svg";
import PremiumIcon from "../img/Premium.svg";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { ChartArea, ChartData } from "chart.js";

export function getUsersGraphData(
  stats: BusinessStatistics,
  gradient: CanvasGradient | string = "#FFBA4980",
): ChartData<"line", number[], number> {
  const datasets = [
    {
      backgroundColor: gradient,
      borderColor: gradient,
      data: stats.newUsersPerMonth.map((item, index) =>
        stats.newUsersPerMonth
          .slice(0, index + 1)
          .reduce((sum, item) => sum + item.count, 0),
      ),
      fill: "start",
      label: "",
    },
  ];

  const labels = stats ? stats.newUsersPerMonth.map((item) => item.month) : [];

  return {
    datasets,
    labels,
  };
}

export function UsersGraph({
  stats,
  premium = false,
}: {
  premium?: boolean;
  stats: BusinessStatistics;
}) {
  const chartRef = useRef<any>(null);
  const { t } = useTranslation("hr-dashboard");

  function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
    const gradient = ctx.createLinearGradient(
      area.left,
      area.bottom,
      area.right,
      area.top,
    );

    gradient.addColorStop(0, "#ffba4980");
    gradient.addColorStop(1, "#fea17c80");

    return gradient;
  }

  const gradient = chartRef.current
    ? createGradient(chartRef.current.ctx, chartRef.current.chartArea)
    : "#ffba4980";

  const usersGraphData = getUsersGraphData(stats, gradient);
  const options = {
    aspectRatio: 1,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#000000",
        bodyFont: {
          size: 12,
          weight: "700",
        },
        displayColors: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x" as const,
        },
        zoom: {
          mode: "x" as const,
          pinch: {
            enabled: true, // Enable pinch zooming
          },
          wheel: {
            enabled: true, // Enable wheel zooming
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <div className="graph">
      <div className={"graph-title flex items-center"}>
        {t("usersGraph.title")}
        <div
          className={"flex items-center"}
          data-tooltip-content={t("usersGraph.tooltipContent")}
          data-tooltip-id="graph-explanation"
        >
          <img alt={""} className={"tooltip-icon"} src={TooltipIcon} />
        </div>
        <Tooltip className={"tooltip"} id={"graph-explanation"} />
      </div>
      {!stats || stats.newUsersPerMonth.length <= 1 ? (
        <div className={"ml-5 mt-4 mb-4"}>{t("usersGraph.noDataMessage")}</div>
      ) : (
        <div className="graph-wrapper">
          <Line
            data={usersGraphData}
            options={options}
            ref={chartRef}
            style={{ maxHeight: "420px" }}
          />
        </div>
      )}
      {premium ? (
        <img alt={""} className={"graph-premium"} src={PremiumIcon} />
      ) : null}
    </div>
  );
}
