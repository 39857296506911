import React from "react";
import { DocumentType } from "./Utils";
import { Link } from "react-router-dom";

export function DocumentBox({
  type,
  name,
  icon,
  className,
}: {
  className: string;
  icon: string;
  name: string;
  type: DocumentType;
}) {
  return (
    <Link
      className={`${className} box`}
      to={`/dashboard/documents/${type.toLowerCase().replace(" ", "-")}`}
    >
      <div className="box-inside-wrapper">
        <img alt="icon" className="icon" src={icon} />
        <div className="name">{name}</div>
      </div>
    </Link>
  );
}
