import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type Testimonial = {
  id: string;
  name: string;
  score: number;
  text: string;
};

export async function getTestimonials(): Promise<Testimonial[]> {
  const result = await fetch(`${API_ENDPOINT}/testimonials`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.tests) {
    return [];
  }

  return result.tests;
}

export async function createTestimonial(
  text: string,
  name: string,
  score: number,
): Promise<Testimonial> {
  const result = await fetch(`${API_ENDPOINT}/testimonials`, {
    body: JSON.stringify({
      name: name,
      score: score,
      text: text,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}

export async function updateTestimonial(
  id: string,
  text: string,
  name: string,
  score: number,
): Promise<Testimonial> {
  const result = await fetch(`${API_ENDPOINT}/testimonials/${id}`, {
    body: JSON.stringify({
      name: name,
      score: score,
      text: text,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}

export async function getTestimonial(id: string): Promise<Testimonial> {
  const result = await fetch(`${API_ENDPOINT}/testimonials/${id}`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result;
}

export async function deleteTestimonial(id: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/testimonials/${id}`, {
    headers: getHeaders(),
    method: "DELETE",
  }).then((r) => r.json());
}
