import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import Cert from "./img/cert-template.png";
import CertENG from "./img/certENG.jpeg";
import CertFr from "./img/certFR.jpg";
import { useTranslation } from "react-i18next";

export function Certificate({
  name,
  module,
}: {
  module: string;
  name: string;
}) {
  const { t, i18n } = useTranslation("general");

  const styles = StyleSheet.create({
    image: {
      objectFit: "contain",
      width: "100%",
    },
    page: {
      backgroundColor: "#E4E4E4",
      flexDirection: "row",
    },
    title: {
      color: "#224453",
      fontSize: "13px",
      fontStyle: "italic",
      textAlign: "center",
    },
    title2: {
      color: "#70a0b0",
      fontSize: "30px",
      marginBottom: "20px",
      marginTop: "20px",
      textAlign: "center",
    },
    title3: {
      color: "#224453",
      fontSize: "20px",
      marginTop: "10px",
      textAlign: "center",
    },
    view: {
      display: "flex",
      flexDirection: "column",
      left: 0,
      position: "absolute",
      top: "230px",
      width: "100%",
    },
  });

  return (
    <Document>
      <Page orientation={"landscape"} style={styles.page}>
        <Image
          src={
            i18n.language === "nl"
              ? Cert
              : i18n.language === "en"
              ? CertENG
              : CertFr
          }
          style={styles.image}
        />
        <View style={styles.view}>
          <Text style={styles.title}>{t("cert1")}</Text>
          <Text style={styles.title2}>{name}</Text>
          <Text style={styles.title}>{t("cert2")}</Text>
          <Text style={styles.title3}>"{module}"</Text>
        </View>
      </Page>
    </Document>
  );
}
