import React, { useState } from "react";
import { Sidebar } from "../Sidebar";
import { useEffectOnce } from "react-use";
import { Navigate } from "react-router-dom";
import { FaPen, FaPlus, FaTimes } from "react-icons/fa";
import {
  deleteTestimonial,
  getTestimonials,
  Testimonial,
} from "../api/TestimonialsApi";

export function Testimonials() {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState<Testimonial | false>(false);

  useEffectOnce(() => {
    getTestimonials().then((r) => {
      setTestimonials(r);
    });
  });

  if (adding) {
    return <Navigate to="/dashboard/testimonials/new" />;
  }

  if (editing) {
    return <Navigate to={`/dashboard/testimonials/${editing.id}`} />;
  }

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>Testimonials</h2>
          <div
            className="button small ml-auto"
            onClick={() => {
              setAdding(true);
            }}
          >
            <FaPlus />
          </div>
        </div>

        {testimonials.length === 0 ? (
          "Momenteel nog geen testimonials beschikbaar"
        ) : (
          <table className="table ">
            <thead>
              <tr>
                <th>Text</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {testimonials.map((testimonial) => {
                return (
                  <tr key={testimonial.id}>
                    <td>{testimonial.text}</td>
                    <td>
                      <div className="flex">
                        <div
                          className="button small w-fit ml-5"
                          onClick={async () => {
                            setEditing(testimonial);
                          }}
                        >
                          <FaPen />
                        </div>
                        <div
                          className="button small w-fit ml-5 pink"
                          onClick={async () => {
                            await deleteTestimonial(testimonial.id);
                            setTestimonials(
                              testimonials.filter(
                                (l) => l.id !== testimonial.id,
                              ),
                            );
                          }}
                        >
                          <FaTimes />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </Sidebar>
  );
}
