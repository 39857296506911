import React from "react";
import { Page, View, StyleSheet, Image, Text } from "@react-pdf/renderer";
import ReportFront from "../img/report-front.png";
import { useTranslation } from "react-i18next";
import { Quarter } from "./Quarter";

export function QuarterlyReportTitlePage({
  quarter,
  year,
  businessName,
}: {
  businessName: string;
  quarter: Quarter;
  year: number;
}) {
  const { t } = useTranslation("report");

  const styles = StyleSheet.create({
    image: {
      height: "45%",
      width: "100%",
    },
    title1: {
      marginTop: "60px",
    },
    title2: {
      marginTop: "13px",
      textTransform: "uppercase",
    },
    title3: {
      marginTop: "13px",
    },
    titles: {
      color: "#6090A3",
      fontFamily: "Montserrat",
      fontSize: "30px",
      fontWeight: "bold",
      textAlign: "center",
    },
  });

  return (
    <Page orientation={"landscape"}>
      <Image src={ReportFront} style={styles.image} />
      <View style={styles.titles}>
        <Text style={styles.title1}>{t("usageReport")}</Text>
        <Text style={styles.title2}>{businessName}</Text>
        <Text style={styles.title3}>
          {t("qn", { quarter })} {year}
        </Text>
      </View>
    </Page>
  );
}
