import React, { useCallback, useEffect, useRef, useState } from "react";
import Logo from "./img/Mindlab by BloomUp.svg";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./NewHeader.css";
import { getDecodedBloomupToken, getDecodedToken } from "./api/AuthApi";
import { isB2C, tokenIsExpired } from "./Utils";

import {
  getCurrentUser,
  getHasSkills,
  storeSession,
  User,
} from "./api/UserApi";
import { FaChevronDown, FaChevronUp, FaBars, FaTimes } from "react-icons/fa";
import { getBusinessSettings, SettingType } from "./api/BusinessSettingApi";
import { getConnectedBusinessesForUser } from "./api/BusinessApi";
import { useApolloClient } from "@apollo/client";
import { getValidBloomupToken } from "./apolloClient/getValidBloomupToken";

export function Header({
  authenticated,
  setAuthenticated,
}: {
  authenticated: boolean;
  setAuthenticated: (state: boolean) => void;
}) {
  const [hasSkills, setHasSkills] = useState(false);
  const [isHr, setIsHr] = useState(false);
  const [user, setUser] = useState<User>();
  const [businessSettings, setBusinessSettings] = useState<SettingType>({
    disableTests: true,
    enforce2FA: false,
    inactiveSiteLink: process.env.NX_PUBLIC_FRONTEND_URL || "",
  });

  const navigate = useNavigate();
  const translationHook = useTranslation("login");
  const { t, i18n } = translationHook;
  const [openLang, setOpenLang] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const apolloClient = useApolloClient();

  const location = useLocation();

  useEffect(() => {
    if (user) {
      storeSession(user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const logout = useCallback(() => {
    localStorage.clear();
    localStorage.setItem("reset", "true");
    setAuthenticated(false);

    setBusinessSettings({
      disableTests: false,
      enforce2FA: false,
      inactiveSiteLink: process.env.NX_PUBLIC_FRONTEND_URL || "",
    });
  }, [setAuthenticated]);

  useEffect(() => {
    const bloomupToken = getDecodedBloomupToken("refresh");

    if (
      (localStorage.getItem("token") && tokenIsExpired(getDecodedToken())) ||
      (localStorage.getItem("bloomupRefreshToken") &&
        bloomupToken &&
        tokenIsExpired(bloomupToken))
    ) {
      logout();
      apolloClient.resetStore();
      navigate("/");
    }

    getValidBloomupToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, i18n]);

  useEffect(() => {
    if (location.pathname.startsWith("/nl/") && i18n.language !== "nl") {
      i18n.changeLanguage("nl");

      return;
    }

    if (location.pathname.startsWith("/en/") && i18n.language !== "en") {
      i18n.changeLanguage("en");

      return;
    }

    if (location.pathname.startsWith("/fr/") && i18n.language !== "fr") {
      i18n.changeLanguage("fr");

      return;
    }

    if (!location.pathname.startsWith("/nl/") && i18n.language === "nl") {
      navigate("/nl" + location.pathname + location.search, { replace: true });
    }

    if (!location.pathname.startsWith("/en/") && i18n.language === "en") {
      navigate("/en" + location.pathname + location.search, { replace: true });
    }

    if (!location.pathname.startsWith("/fr/") && i18n.language === "fr") {
      navigate("/fr" + location.pathname + location.search, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (location.pathname.startsWith("/nl/") && i18n.language !== "nl") {
      navigate(
        "/" + i18n.language + location.pathname.substr(3) + location.search,
        { replace: true },
      );

      return;
    }

    if (location.pathname.startsWith("/en/") && i18n.language !== "en") {
      navigate(
        "/" + i18n.language + location.pathname.substr(3) + location.search,
        { replace: true },
      );

      return;
    }

    if (location.pathname.startsWith("/fr/") && i18n.language !== "fr") {
      navigate(
        "/" + i18n.language + location.pathname.substr(3) + location.search,
        { replace: true },
      );

      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translationHook]);

  useEffect(() => {
    if (!authenticated) {
      setBusinessSettings({
        disableTests: false,
        enforce2FA: false,
        inactiveSiteLink: process.env.NX_PUBLIC_FRONTEND_URL || "",
      });

      return;
    }

    getHasSkills().then((result) => setHasSkills(result));

    getCurrentUser()
      .then((user) => {
        if (!user) {
          return;
        }

        setUser(user);
        setIsHr(user ? user.isHr : false);

        getConnectedBusinessesForUser(user.id).then((result) => {
          if (result.length === 0) {
            return;
          }

          getBusinessSettings(result[0].business.id).then((result) => {
            setBusinessSettings(result.settings);
          });
        });
      })
      .catch((error) => {
        if (error.message === "UNAUTHENTICATED") {
          logout();
        }
      });
  }, [authenticated, logout]);

  const showSkills = authenticated && (getDecodedToken().admin || hasSkills);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenLang(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="new-header">
      {authenticated && i18n.language === "nl" ? (
        <a
          className="bloom-mondays-banner__link"
          href="https://www.bloomup.org/nl/bloom-mondays"
          rel="noreferrer"
          target="_blank"
        >
          <div className="bloom-mondays-banner">
            Schrijf je nu in voor de gratis 'Bloom Monday' sessies! Klik hier
            voor meer info.
          </div>
        </a>
      ) : null}
      <div className="flex items-center header-wrapper">
        <img className="logo" src={Logo} />
        {authenticated ? (
          <div className="header-top ml-auto flex">
            <div className="mt-auto mb-auto">
              <Link
                className="ml-auto mr-5 mt-auto mb-auto yellow no-decoration"
                to="/profile"
              >
                {getDecodedToken().firstName}
              </Link>
              <Link
                className="button yellow reverse no-decoration mr-5"
                onClick={() => {
                  if (localStorage.getItem("admintoken")) {
                    localStorage.setItem(
                      "token",
                      localStorage.getItem("admintoken") || "",
                    );
                    localStorage.removeItem("admintoken");

                    window.location.href = "/home";
                  } else {
                    logout();
                  }

                  // Always wipe the cache when logging out
                  apolloClient.resetStore();
                }}
                to={isB2C() ? "/login" : "/"}
              >
                Logout
              </Link>
            </div>
          </div>
        ) : (
          <div className="header-top ml-auto flex">
            <div className="mt-auto mb-auto">
              <Link
                className="ml-auto button yellow reverse w-fit"
                to={"/login"}
              >
                {t("login")}
              </Link>
              <Link className="ml-5 button yellow w-fit mr-5" to={"/register"}>
                {t("register")}
              </Link>
            </div>
          </div>
        )}
        {i18n ? (
          <>
            <div
              className="small pointer flex items-center"
              onClick={() => {
                setOpenLang(!openLang);
              }}
            >
              <div className="mr-1">
                {i18n.language === "nl"
                  ? "NL"
                  : i18n.language === "en"
                  ? "EN"
                  : "FR"}
              </div>
              {openLang ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {openLang ? (
              <div className="lang-select" ref={dropdownRef}>
                <div
                  className="pointer"
                  onClick={async () => {
                    await i18n.changeLanguage("nl");
                    setOpenLang(false);
                  }}
                >
                  NL
                </div>
                <div
                  className="pointer"
                  onClick={async () => {
                    await i18n.changeLanguage("en");
                    setOpenLang(false);
                  }}
                >
                  EN
                </div>
                <div
                  className="pointer"
                  onClick={async () => {
                    await i18n.changeLanguage("fr");
                    setOpenLang(false);
                  }}
                >
                  FR
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
      <div className="menu-icon-container">
        <div className="menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </div>
      <div className={`second ${menuOpen ? "open" : "closed"}`}>
        <div className="second-inside">
          <NavLink
            className="menu-item"
            end
            onClick={() => setMenuOpen(false)}
            to={authenticated ? "/" + i18n.language + "/home" : "/"}
          >
            Home
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              `menu-item ${isActive ? "active" : ""}`
            }
            onClick={() => setMenuOpen(false)}
            to={"/" + i18n.language + "/modules"}
          >
            {t("modules")}
          </NavLink>
          {authenticated && !showSkills ? null : (
            <NavLink
              className={({ isActive }) =>
                `menu-item ${isActive ? "active" : ""}`
              }
              onClick={() => setMenuOpen(false)}
              to={"/" + i18n.language + "/skills"}
            >
              {t("skills")}
            </NavLink>
          )}
          {!authenticated || businessSettings.disableTests ? null : (
            <NavLink
              className={({ isActive }) =>
                `menu-item ${
                  isActive ? (authenticated ? "active" : "no") : ""
                } ${authenticated ? "" : "disabled"}`
              }
              onClick={() => setMenuOpen(false)}
              to={authenticated ? "/" + i18n.language + "/tests" : "#"}
            >
              {t("tests")}
            </NavLink>
          )}
          {(authenticated && !showSkills) || !authenticated ? null : (
            <NavLink
              className={({ isActive }) =>
                `menu-item ${
                  isActive ? (authenticated ? "active" : "no") : ""
                } ${showSkills ? "" : "disabled"}`
              }
              onClick={() => setMenuOpen(false)}
              to={showSkills ? "/" + i18n.language + "/podcasts" : "#"}
            >
              Podcasts
            </NavLink>
          )}
          {!authenticated ? null : (
            <NavLink
              className={({ isActive }) =>
                `menu-item ${
                  isActive ? (authenticated ? "active" : "no") : ""
                } ${authenticated ? "" : "disabled"}`
              }
              onClick={() => setMenuOpen(false)}
              to={
                authenticated
                  ? isHr
                    ? "/" + i18n.language + "/dashboard/general"
                    : "/" + i18n.language + "/user-dashboard"
                  : "#"
              }
            >
              Dashboard
            </NavLink>
          )}
          <NavLink
            className="menu-item"
            onClick={() => setMenuOpen(false)}
            to={"/" + i18n.language + "/blog"}
          >
            Blog
          </NavLink>
          <NavLink
            className="menu-item"
            onClick={() => setMenuOpen(false)}
            to={"/" + i18n.language + "/about"}
          >
            {t("about")}
          </NavLink>
        </div>
      </div>
    </div>
  );
}
