import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Challenge, createChallenge, updateChallenge } from "../api/GameApi";

export function ChallengeForm({ challenge }: { challenge: Challenge | null }) {
  const [nameNL, setNameNL] = useState(challenge ? challenge.nameNL : "");
  const [nameEN, setNameEN] = useState(challenge ? challenge.nameEN : "");
  const [nameFR, setNameFR] = useState(challenge ? challenge.nameFR : "");
  const [textNL, setTextNL] = useState(challenge ? challenge.textNL : "");
  const [textEN, setTextEN] = useState(challenge ? challenge.textEN : "");
  const [textFR, setTextFR] = useState(challenge ? challenge.textFR : "");

  const [saved, setSaved] = useState(false);

  if (saved) {
    return <Navigate to={"/dashboard/challenges"} />;
  }

  return (
    <form
      onSubmit={async (e) => {
        // TODO checks on fields
        e.preventDefault();

        const updatedBusiness = {
          nameEN: nameEN,
          nameFR: nameFR,
          nameNL: nameNL,
          textEN: textEN,
          textFR: textFR,
          textNL: textNL,
        };

        if (challenge) {
          updateChallenge({
            ...updatedBusiness,
            id: challenge.id,
          }).then(async () => {
            setSaved(true);
          });
        } else {
          createChallenge({
            id: "",
            ...updatedBusiness,
          }).then(async () => {
            setSaved(true);
          });
        }
      }}
    >
      <div className="flex justify-evenly pb-4">
        <div className="w-25">
          <div>
            <h4>Algemene info</h4>
            <label>Naam NL</label>
            <input
              className="input"
              onChange={(input) => {
                setNameNL(input.target.value);
              }}
              placeholder="Naam"
              required={true}
              type="text"
              value={nameNL}
            />
            <label>Naam EN</label>
            <input
              className="input"
              onChange={(input) => {
                setNameEN(input.target.value);
              }}
              placeholder="Naam"
              required={true}
              type="text"
              value={nameEN}
            />
            <label>Naam FR</label>
            <input
              className="input"
              onChange={(input) => {
                setNameFR(input.target.value);
              }}
              placeholder="Naam"
              required={true}
              type="text"
              value={nameFR}
            />
            <label>Beschrijving in het Nederlands</label>
            <textarea
              className="input"
              onChange={(input) => {
                setTextNL(input.target.value);
              }}
              placeholder="Beschrijving"
              required={true}
              rows={6}
              value={textNL}
            />
            <label>Beschrijving in het Engels</label>
            <textarea
              className="input"
              onChange={(input) => {
                setTextEN(input.target.value);
              }}
              placeholder="Beschrijving"
              rows={6}
              value={textEN}
            />
            <label>Beschrijving in het Frans</label>
            <textarea
              className="input"
              onChange={(input) => {
                setTextFR(input.target.value);
              }}
              placeholder="Beschrijving"
              rows={6}
              value={textFR}
            />

            <button className="button" type="submit">
              {challenge ? "Challenge bijwerken" : "Challenge toevoegen"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
