import React, { useState, useRef, useEffect, Fragment } from "react";
import Webcam from "react-webcam";
import fixWebmDuration from "fix-webm-duration";
import { RecordRTCPromisesHandler } from "recordrtc";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RotatingLines } from "react-loader-spinner";
import { isMobile } from "react-device-detect";
import {
  IntelliProveApi,
  IntelliProveQualityResponse,
  IntelliProveResults,
} from "./api/IntelliProveApi";
import { FaceScanResults } from "./FaceScanResults";
import "./FaceScanner.css";
import { useTranslation } from "react-i18next";
import { BsCheckCircle } from "react-icons/bs";
import { FiAlertCircle } from "react-icons/fi";
import { FaceScanResultType } from "./Types";
import visualFaceScan from "./img/visualInstructions.png";
import { dataURLtoFile, getSupportedCodecs } from "./util";

const RECORDING_SECONDS = 20;

interface VideoUploadData {
  qualityResponse: IntelliProveQualityResponse;
  video: Blob;
}

export function FaceScanner({
  onRequestClose,
  periodic,
  embedded,
  itemsToShow,
}: {
  embedded?: boolean;
  itemsToShow?: FaceScanResultType[];
  onRequestClose: () => void;
  periodic: boolean;
}) {
  const [startScanShown, setStartScanShown] = useState(false);
  const [webcamLoaded, setWebcamLoaded] = useState(false);
  const [instructionsShown, setInstructionsShown] = useState(true);
  const [recording, setRecording] = useState(false);
  const [recordingCounter, setRecordingCounter] = useState(0);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [results, setResults] = useState<IntelliProveResults | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("");
  const [videoUploadData, setVideoUploadData] =
    useState<VideoUploadData | null>(null);

  const webcamRef = useRef<Webcam | null>(null);
  const { t } = useTranslation("face-scanner");

  function qualityErrorCodeToMsg(errorCode: number) {
    switch (errorCode) {
      case 1:
        return t("tooClose");

      case 2:
        return t("tooFar");

      case 3:
        return t("notHorizontallyCentered");

      case 4:
        return t("chestNotVisible");

      case 5:
        return t("faceCamera");

      case 6:
        return t("insufficientLighting");

      case 7:
        return t("lowerFace");

      case 8:
        return t("noFace");

      case 9:
        return t("skinNotVisible");

      default:
        return t("unknownQualityError");
    }
  }

  async function recordAndUploadVideo(
    qualityResponse: IntelliProveQualityResponse,
  ) {
    if (webcamRef.current?.stream) {
      const codec = getSupportedCodecs()[0];
      const bitrate = 3_000_000;
      const recorder = new RecordRTCPromisesHandler(webcamRef.current.stream, {
        audioBitsPerSecond: bitrate,

        disableLogs: true,

        frameRate: 30,
        mimeType: codec as any,
        type: "video",
        videoBitsPerSecond: bitrate,
      });

      const startTime = Date.now();

      recorder.startRecording();
      setRecordingCounter(RECORDING_SECONDS);
      setRecording(true);

      // eslint-disable-next-line no-promise-executor-return
      const sleep = (s: number) => new Promise((r) => setTimeout(r, s * 1000));

      await sleep(RECORDING_SECONDS);

      await recorder.stopRecording();
      setRecording(false);

      const duration = Date.now() - startTime;

      let videoBlob = await recorder.getBlob();

      if (codec.startsWith("video/webm")) {
        // Add duration data which is omitted by MediaRecorder for webm videos
        videoBlob = await fixWebmDuration(videoBlob, duration, {
          logger: false,
        });
      }

      const video = new File([videoBlob], `face-scan.webm`, {
        type: codec,
      });

      setVideoUploadData({
        qualityResponse,
        video,
      });
    }
  }

  async function startScan() {
    if (webcamRef.current) {
      setErrorMsg(null);
      setStartScanShown(false);

      // do quality check
      const imageSrc = webcamRef.current.getScreenshot();

      if (imageSrc) {
        setLoading(true);
        setLoadingText(t("checkingQuality"));

        const qualityResponse = await IntelliProveApi.check(
          dataURLtoFile(imageSrc, "quality-check.png"),
        );

        setLoading(false);

        if (qualityResponse.error_type === 0) {
          // quality check successful, start video recording
          await recordAndUploadVideo(qualityResponse);
        } else {
          setStartScanShown(true);
          setErrorMsg(qualityErrorCodeToMsg(qualityResponse.error_type));
        }
      } else {
        setStartScanShown(true);
      }
    }
  }

  // when video upload data becomes available, upload video and get scan results
  useEffect(() => {
    (async () => {
      if (videoUploadData) {
        let resultUUID: string;

        try {
          setLoading(true);
          setLoadingText(t("uploadingVideo"));
          resultUUID = await IntelliProveApi.uploadVideo(
            videoUploadData.video,
            videoUploadData.qualityResponse,
            periodic,
          );
        } catch {
          setErrorMsg(t("uploadFailed"));
          setStartScanShown(true);
          setLoading(false);

          return;
        }

        setLoadingText(t("gettingResults"));
        try {
          const results = await IntelliProveApi.getResults(resultUUID);

          if (results.errorCode === 0) {
            setResults(results);
            setLoading(false);
          } else {
            setErrorMsg(t(`gettingResultsFailed.${results.errorCode}`));
            setStartScanShown(true);
            setLoading(false);
          }
        } catch {
          setErrorMsg(t("gettingResultsFailed.default"));
          setStartScanShown(true);
          setLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoUploadData]);

  // if recording, decrement recordingCounter by 1 every second
  useEffect(() => {
    if (recordingCounter > 0) {
      setTimeout(() => {
        if (recording) {
          setRecordingCounter(recordingCounter - 1);
        }
      }, 1000);
    }
  }, [recordingCounter, recording]);

  // componentWillUnmount to ensure state is reset on unmount
  useEffect(() => {
    return () => closeAndResetScanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function closeAndResetScanner() {
    onRequestClose();
    setStartScanShown(true);
    setErrorMsg(null);
    setVideoUploadData(null);
    setResults(null);
    setRecording(false);
    setRecordingCounter(0);
    setLoading(false);
  }

  return (
    <Fragment>
      {results ? (
        embedded ? (
          <FaceScanResults itemsToShow={itemsToShow || []} results={results} />
        ) : (
          <FaceScanResults
            itemsToShow={[
              FaceScanResultType.HEART_RATE,
              FaceScanResultType.RESPIRATORY_RATE,
              FaceScanResultType.HEART_RATE_VARIABILITY,
              FaceScanResultType.RESONANT_BREATHING_SCORE,
            ]}
            results={results}
          />
        )
      ) : (
        <Webcam
          audio={false}
          className="scanner-webcam"
          mirrored={true}
          onCanPlay={() => {
            setLoading(false);
            setWebcamLoaded(true);
          }}
          ref={webcamRef}
          screenshotFormat="image/png"
          videoConstraints={{
            facingMode: "user",
            height: { ideal: 720, max: 720, min: 360 },
            width: { ideal: 1280, max: 1280, min: 480 },
          }}
        />
      )}
      <div>
        {webcamLoaded && startScanShown ? (
          <Fragment>
            <button
              className="button start-scan-button"
              onClick={() => startScan()}
            >
              {t("startScan")}
            </button>
            <div
              className={`scanner-outline ${
                embedded ? "embedded-position" : ""
              }`}
            />
            {!errorMsg ? (
              <div
                className={`scanner-outline-instruction ${
                  embedded ? "embedded-position" : ""
                }`}
              >
                {t("outlineInstruction")}
              </div>
            ) : null}
          </Fragment>
        ) : null}
        {webcamLoaded && instructionsShown ? (
          <div className="scanner-instructions">
            <div className="scanner-instructions-lines">
              <div className="why-title">{t("whyTitle")}</div>
              <div className="scanner-instructions-line why-text">
                {t("whyText")}
              </div>
              <div className="scanner-instructions-title">
                {t("instructionTitle")}
              </div>
              <div className="scanner-instructions-line intro">
                {t("instructionIntro")}
              </div>
              <div className={"flex"}>
                <div className={"scanner-instructions-do"}>
                  <div className="scanner-instructions-line">
                    <BsCheckCircle className={"icon"} color={"green"} />{" "}
                    {t("checkInstruction1")}
                  </div>
                  <div className="scanner-instructions-line">
                    <BsCheckCircle className={"icon"} color={"green"} />{" "}
                    {t("checkInstruction2")}
                  </div>
                  <div className="scanner-instructions-line">
                    <BsCheckCircle className={"icon"} color={"green"} />{" "}
                    {t("checkInstruction3")}
                  </div>
                  <div className="scanner-instructions-line">
                    <BsCheckCircle className={"icon"} color={"green"} />{" "}
                    {t("checkInstruction4")}
                  </div>
                  <div className="scanner-instructions-line">
                    <div className={"flex icon-wrapper"}>
                      <BsCheckCircle className={"icon"} color={"green"} />{" "}
                      <div className={"text"}>{t("checkInstruction5")}</div>
                    </div>
                  </div>
                  <div className="instructions-image-wrapper">
                    <img src={visualFaceScan} />
                  </div>
                </div>
                <div className={"scanner-instructions-dont"}>
                  <div className="scanner-instructions-line">
                    <div className={"flex icon-wrapper"}>
                      <FiAlertCircle className={"icon"} color={"red"} />{" "}
                      <div className={"text"}>{t("alertInstruction1")}</div>
                    </div>
                  </div>
                  <div className="scanner-instructions-line">
                    <div className={"flex icon-wrapper"}>
                      <FiAlertCircle className={"icon"} color={"red"} />{" "}
                      <div className={"text"}>{t("alertInstruction2")}</div>
                    </div>
                  </div>
                  <div className="scanner-instructions-line">
                    <div className={"flex icon-wrapper"}>
                      <FiAlertCircle className={"icon"} color={"red"} />{" "}
                      <div className={"text"}>{t("alertInstruction3")}</div>
                    </div>
                  </div>
                  <div className="scanner-instructions-line">
                    <div className={"flex icon-wrapper"}>
                      <FiAlertCircle className={"icon"} color={"red"} />{" "}
                      <div className={"text"}>{t("alertInstruction4")}</div>
                    </div>
                  </div>
                  <div className="scanner-instructions-line">
                    <div className={"flex icon-wrapper"}>
                      <FiAlertCircle className={"icon"} color={"red"} />{" "}
                      <div className={"text"}>{t("alertInstruction5")}</div>
                    </div>
                  </div>
                  <div className="scanner-instructions-line">
                    <div className={"flex icon-wrapper"}>
                      <FiAlertCircle className={"icon"} color={"red"} />{" "}
                      <div className={"text"}>{t("alertInstruction6")}</div>
                    </div>
                  </div>
                  <ul className="exclusion-list">
                    <li className="exclusion-list-item">
                      {t("alertInstruction6Bullet1")}
                    </li>
                    <li className="exclusion-list-item">
                      {t("alertInstruction6Bullet2")}
                    </li>
                    <li className="exclusion-list-item">
                      {t("alertInstruction6Bullet3")}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <button
              className="button understood-button"
              onClick={() => {
                setInstructionsShown(false);
                setStartScanShown(true);
              }}
            >
              {t("understood")}
            </button>
          </div>
        ) : null}
        {recording ? (
          <div className="recording-timer">{recordingCounter}</div>
        ) : null}
        {errorMsg ? <div className="scanner-error">{errorMsg}</div> : null}
        {embedded ? null : (
          <div
            className="scanner-close-button"
            onClick={() => closeAndResetScanner()}
            title="Close dialog"
          >
            <FontAwesomeIcon color="grey" icon={faTimes} size="2x" />
          </div>
        )}
        {loading ? (
          <div className="loading-spinner">
            <RotatingLines
              animationDuration="0.75"
              strokeColor="white"
              strokeWidth="5"
              visible={loading}
              width={isMobile ? "100" : "115"}
            />
          </div>
        ) : null}
      </div>
      {loading ? (
        <div className={`loading-text ${embedded ? "embedded-position" : ""}`}>
          {loadingText}
        </div>
      ) : null}
    </Fragment>
  );
}
