import React from "react";
import { Document, Font, StyleSheet } from "@react-pdf/renderer";
import { Business } from "../api/BusinessApi";
import { Quarter } from "./Quarter";
import montserratRegular from "../fonts/Montserrat-Regular.otf";
import montserratBold from "../fonts/Montserrat-Bold.otf";
import { QuarterlyReportTitlePage } from "./QuarterlyReportTitlePage";
import { QuarterlyReportContent } from "./QuarterlyReportContent";

export function QuarterlyReport({
  quarter,
  year,
  business,
  statsBoxRowImage,
  modulesGraphImage,
  skillsGraphImage,
  sessionGraphImage,
  usersGraphImage,
  activityGraphImage,
  testsTableImage,
}: {
  activityGraphImage: string;
  business: Business;
  modulesGraphImage: string;
  quarter: Quarter;
  sessionGraphImage: string;
  skillsGraphImage: string;
  statsBoxRowImage: string;
  testsTableImage: string;
  usersGraphImage: string;
  year: number;
}) {
  Font.register({
    family: "Montserrat",
    fonts: [
      {
        src: montserratRegular,
      },
      {
        src: montserratBold,
      },
    ],
  });

  const styles = StyleSheet.create({
    doc: {
      fontFamily: "Montserrat",
    },
  });

  return (
    <Document style={styles.doc}>
      <QuarterlyReportTitlePage
        businessName={business.name}
        quarter={quarter}
        year={year}
      />
      <QuarterlyReportContent
        activityGraphImage={activityGraphImage}
        modulesGraphImage={modulesGraphImage}
        sessionGraphImage={sessionGraphImage}
        skillsGraphImage={skillsGraphImage}
        statsBoxRowImage={statsBoxRowImage}
        testsTableImage={testsTableImage}
        usersGraphImage={usersGraphImage}
      />
    </Document>
  );
}
