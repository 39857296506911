import React from "react";
import { uniqueId } from "../uniqueId";
import { getCorrectUrl } from "../Utils";

export function Video({ url }: { url: string }) {
  if (url.includes("youtube.com")) {
    const params = new URLSearchParams(`?${url.split("?")[1]}`);

    return (
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        height="315"
        src={`https://www.youtube.com/embed/${params.get("v")}`}
        width="560"
      />
    );
  }

  return (
    <div>
      <video className="fit-object" controls={true} key={uniqueId()}>
        <source src={`${getCorrectUrl(url)}`} type="video/mp4" />
      </video>
    </div>
  );
}
