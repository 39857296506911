import { API_ENDPOINT, getHeaders } from "./AuthApi";

type TestOption = {
  groupId: string;
  id: string;
  position: number;
  score: number;
  title_en?: string;
  title_fr?: string;
  title_nl: string;
};

type TestScore = {
  explanation_en?: string;
  explanation_fr?: string;
  explanation_nl: string;
  groupId: string;
  id: string;
  ltScore: number;
};

export type TestStatement = {
  groupId: string;
  id: string;
  optionGroupId: string;
  position: number;
  title_en?: string;
  title_fr?: string;
  title_nl: string;
};

type TestGroup = {
  id: string;
  title_en?: string;
  title_fr?: string;
  title_nl: string;
};

export type Test = {
  badScore: number;
  badScoreType: string;
  deleted: boolean;
  description_en?: string;
  description_fr?: string;
  description_nl: string;
  groups: TestGroup[];
  id: string;
  options: TestOption[];
  statements: TestStatement[];
  template: string;
  testScores: TestScore[];
  testType: string;
  title_en?: string;
  title_fr?: string;
  title_nl: string;
};

export enum TestTemplates {
  DEFAULT = "default",
  MULTIPLE_OLD = "multiple_old",
}

export type TestType = {
  id: string;
  image: string;
  titleEN: string;
  titleFR: string;
  titleNL: string;
};

export type UserTestScore = {
  date: string;
  groupId: string;
  id: string;
  score: number;
  testId: string;
  userId: string;
};

export async function listTestTypes(): Promise<TestType[]> {
  const result = await fetch(`${API_ENDPOINT}/test-types`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.types;
}

export async function createTestType(data: {
  image: string;
  titleEN: string;
  titleFR: string;
  titleNL: string;
}): Promise<TestType> {
  const result = await fetch(`${API_ENDPOINT}/test-types`, {
    body: JSON.stringify(data),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}

export async function updateTestType(
  data: {
    image: string;
    titleEN: string;
    titleFR: string;
    titleNL: string;
  },
  id: string,
): Promise<TestType> {
  const result = await fetch(`${API_ENDPOINT}/test-types/${id}`, {
    body: JSON.stringify(data),
    headers: getHeaders(),
    method: "PUT",
  }).then((r) => r.json());

  return result;
}

export async function deleteTestType(id: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/test-types/${id}`, {
    headers: getHeaders(),
    method: "DELETE",
  }).then((r) => r.json());
}

export async function listTests(): Promise<Test[]> {
  const result = await fetch(`${API_ENDPOINT}/tests`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.tests;
}

export async function createTest(test: Test): Promise<Test> {
  const result = await fetch(`${API_ENDPOINT}/tests`, {
    body: JSON.stringify(test),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result.test;
}

export async function updateTest(test: Test): Promise<Test> {
  const result = await fetch(`${API_ENDPOINT}/tests/${test.id}`, {
    body: JSON.stringify(test),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result.test;
}

export async function deleteTest(test: Test): Promise<Test> {
  const result = await fetch(`${API_ENDPOINT}/tests/${test.id}`, {
    headers: getHeaders(),
    method: "DELETE",
  }).then((r) => r.json());

  return result.test;
}

export async function getTest(testId: string): Promise<Test> {
  const result = await fetch(`${API_ENDPOINT}/tests/${testId}`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.test;
}

export async function createUserTestScore(score: {
  date: string;
  groupId: string;
  score: number;
  testId: string;
}): Promise<void> {
  await fetch(`${API_ENDPOINT}/users/tests/${score.testId}/score`, {
    body: JSON.stringify(score),
    headers: getHeaders(),
    method: "POST",
  });
}

export async function getUserTestScores(): Promise<UserTestScore[]> {
  const result = await fetch(`${API_ENDPOINT}/users/tests/scores`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.userTestScores) {
    return [];
  }

  return result.userTestScores;
}
