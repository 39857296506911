import { API_ENDPOINT, getDecodedToken, getHeaders } from "./AuthApi";
import { User } from "./UserApi";
import { quarterAndYearToFilterQueryString } from "../Utils";
import { QuarterAndYear } from "../QuarterlyReport/QuarterAndYear";

export type Business = {
  code: string;
  contactEmail: string;
  email: string;
  employees: number;
  height: string;
  id: string;
  info_en: string;
  info_fr: string;
  info_nl: string;
  infoAuthorName: string;
  infoAuthorTitleEn: string;
  infoAuthorTitleFr: string;
  infoAuthorTitleNl: string;
  invoiceAddress: string;
  logo: string;
  name: string;
  preventionService: string;
  startDate: string;
  type: number;
  vat: string;
  width: string;
};

export type ConnectedBusiness = {
  business: Business;
};

export async function listBusinesses(): Promise<Business[]> {
  const result = await fetch(`${API_ENDPOINT}/businesses`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.businesses;
}

export async function getAdmins(
  businessId: string,
): Promise<{ businessId: string; userId: string }[]> {
  const result = await fetch(`${API_ENDPOINT}/admins/${businessId}`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.admins;
}

export async function createAdmins(
  data: { businessId: string; userId: string }[],
  businessId: string,
): Promise<void> {
  await fetch(`${API_ENDPOINT}/admins/${businessId}`, {
    body: JSON.stringify({ admins: data }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());
}

export async function getBusiness(businessId: string): Promise<Business> {
  const result = await fetch(`${API_ENDPOINT}/businesses/${businessId}`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.business;
}

export type ModulesActivity = {
  done: number;
  inProcess: number;
  notActive: number;
};

export type TestStatistics = {
  flags: number;
  id: string;
  total: number;
};

export type BusinessStatistics = {
  activity: {
    count: number;
    month: number;
  }[];
  evaluationScore: number;
  faceScans: number;
  meanPerUser: number;
  modules: {
    id: string;
    mean: number;
    mindlabTotal: number;
    total: number;
    users: number;
  }[];
  modulesActivity: ModulesActivity;
  newUsersPerMonth: {
    count: number;
    month: number;
  }[];
  newUsersThisWeek: number;
  numberOfUsers: number;
  podcasts: {
    id: string;
    total: number;
  }[];
  s0_40: number;
  s41_60: number;
  s61_80: number;
  s81_100: number;
  tests: TestStatistics[];
  totalModulesStarted: number;
  totalSkillsStarted: number;
};

export async function getStatsForBusiness(
  businessId: string,
  quarterAndYear?: QuarterAndYear,
): Promise<BusinessStatistics> {
  const filterQueryString = quarterAndYear
    ? quarterAndYearToFilterQueryString(
        quarterAndYear.quarter,
        quarterAndYear.year,
      )
    : "";

  const result = await fetch(
    `${API_ENDPOINT}/stats/${businessId}?${filterQueryString}`,
    {
      headers: getHeaders(),
      method: "GET",
    },
  ).then((r) => r.json());

  return result.stats;
}

export async function getStats(): Promise<BusinessStatistics> {
  const result = await fetch(`${API_ENDPOINT}/stats`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.stats;
}

export async function listUsersForBusiness(
  businessId: string,
): Promise<User[]> {
  const result = await fetch(`${API_ENDPOINT}/businesses/${businessId}/users`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.users;
}

export async function getConnectedBusinesses(): Promise<ConnectedBusiness[]> {
  const token = getDecodedToken();

  if (!token || !token.userId || token.userId === "") {
    return [];
  }

  const result = await fetch(
    `${API_ENDPOINT}/users/${token.userId}/businesses/connected`,
    {
      headers: getHeaders(),
      method: "GET",
    },
  ).then((r) => r.json());

  return result.connectedBusinesses;
}

export async function getConnectedBusinessesForUser(
  userId: string,
): Promise<ConnectedBusiness[]> {
  if (!userId || userId === "") {
    return [];
  }

  const result = await fetch(
    `${API_ENDPOINT}/users/${userId}/businesses/connected`,
    {
      headers: getHeaders(),
      method: "GET",
    },
  ).then((r) => r.json());

  return result.connectedBusinesses;
}

export async function createBusiness(business: Business): Promise<Business> {
  const result = await fetch(`${API_ENDPOINT}/businesses`, {
    body: JSON.stringify(business),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result.business;
}

export async function updateBusiness(business: Business): Promise<Business> {
  const result = await fetch(`${API_ENDPOINT}/businesses/${business.id}`, {
    body: JSON.stringify(business),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result.business;
}

export async function updateBusinessUser(
  name: string,
  email: string,
  employees: number,
  businessId: string,
  vat: string,
  invoiceAddress: string,
): Promise<Business> {
  const result = await fetch(`${API_ENDPOINT}/user/businesses/${businessId}`, {
    body: JSON.stringify({
      contactEmail: email,
      employees: employees,
      invoiceAddress: invoiceAddress,
      name: name,
      vat: vat,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result.business;
}

export async function updateBusinessPageUser(
  descriptionNL: string,
  descriptionEN: string,
  descriptionFR: string,
  businessId: string,
  info_author_name: string,
  info_author_title_nl: string,
  info_author_title_en: string,
  info_author_title_fr: string,
): Promise<Business> {
  const result = await fetch(
    `${API_ENDPOINT}/user/businesses-page/${businessId}`,
    {
      body: JSON.stringify({
        info_author_name,
        info_author_title_en,
        info_author_title_fr,
        info_author_title_nl,
        info_en: descriptionEN,
        info_fr: descriptionFR,
        info_nl: descriptionNL,
      }),
      headers: getHeaders(),
      method: "POST",
    },
  ).then((r) => r.json());

  return result.business;
}

export async function addAdmins(
  emails: string[],
  businessId: string,
  removed: string[],
): Promise<boolean> {
  const result = await fetch(`${API_ENDPOINT}/businesses/${businessId}/hr`, {
    body: JSON.stringify({
      emails: emails,
      removed: removed,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result.ok;
}

export async function excludeModules(
  modules: string[],
  businessId: string,
): Promise<boolean> {
  const result = await fetch(
    `${API_ENDPOINT}/businesses/${businessId}/exclude`,
    {
      body: JSON.stringify({
        modules: modules,
      }),
      headers: getHeaders(),
      method: "POST",
    },
  ).then((r) => r.json());

  return result.ok;
}

export async function listAdmins(businessId: string): Promise<string[]> {
  const result = await fetch(`${API_ENDPOINT}/businesses/${businessId}/hr`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.emails;
}

export async function listExcluded(businessId: string): Promise<string[]> {
  const result = await fetch(
    `${API_ENDPOINT}/businesses/${businessId}/exclude`,
    {
      headers: getHeaders(),
      method: "GET",
    },
  ).then((r) => r.json());

  return result.modules;
}

export async function deleteBusiness(business: Business): Promise<Business> {
  const result = await fetch(`${API_ENDPOINT}/businesses/${business.id}`, {
    headers: getHeaders(),
    method: "DELETE",
  }).then((r) => r.json());

  return result.business;
}

export async function addImage(file: File): Promise<{ path: string } | string> {
  const formData = new FormData();

  formData.append("image", file);

  const result = await fetch(`${API_ENDPOINT}/upload`, {
    body: formData,
    headers: getHeaders(false),
    method: "POST",
  }).then((r) => r.json());

  if (result.message) {
    return result.message;
  }

  return result.image;
}

export async function requestDemo(
  email: string,
  name: string,
  business: string,
): Promise<void> {
  await fetch(`${API_ENDPOINT}/demo`, {
    body: JSON.stringify({
      business,
      email,
      name,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());
}
