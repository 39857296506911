const CODEC_OPTIONS = [
  "video/webm;codecs=vp8",
  "video/webm;codecs=vp8.0",
  "video/webm",
  "video/webm;codecs=h264",
  "video/mp4;codecs=h264",
  "video/mp4;codecs=mp4a",
  "video/mp4;codecs=avc1",
  "video/mp4",
  "video/webm;codecs=vp9",
  "video/webm;codecs=vp9.0",
  "video/webm;codecs=avc1",
  "video/webm;codecs=daala",
  "video/mpeg",
  "video/x-matroska;codecs=avc1",
];

/**
 * Returns a list of supported codecs for the current browser.
 * @returns {string[]} - The list of supported codecs.
 * @example
 * const supportedCodecs = getSupportedCodecs(navigator);
 * console.log(supportedCodecs);
 *   [
 *     "video/webm;codecs=vp8",
 *     "video/webm;codecs=vp8.0",
 *     "video/webm",
 *   ]
 * */
export function getSupportedCodecs(): string[] {
  return CODEC_OPTIONS.filter((c) => MediaRecorder.isTypeSupported(c));
}

export function dataURLtoFile(dataUrl: string, filename: string): File {
  const arr = dataUrl.split(","),
    mime = arr[0].match(/:(.*?);/)?.[1],
    bstr = atob(arr[1]);

  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
