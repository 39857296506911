import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "./Dialog";

export function SuggestFaceScanModal({
  authenticated,
  isOpen,
  onRequestClose,
  onRequestInfo,
  onAcceptScan,
}: {
  authenticated: boolean;
  isOpen: boolean;
  onAcceptScan: () => void;
  onRequestClose: () => void;
  onRequestInfo: () => void;
}) {
  const { t } = useTranslation("face-scanner");

  return (
    <Dialog
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      padding={true}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      size="small"
      title={t("suggestScanTitle")}
    >
      {authenticated ? (
        <Fragment>
          <button className="button mt-4" onClick={onAcceptScan}>
            {t("doScan")}
          </button>
          <button className="button yellow mt-4 ml-2" onClick={onRequestClose}>
            {t("maybeLater")}
          </button>
          <button className="button yellow mt-4 ml-2" onClick={onRequestInfo}>
            {t("moreInfo")}
          </button>
        </Fragment>
      ) : (
        <Fragment>
          <div className="suggest-scan-text">{t("notToday")}</div>
          <button className="button yellow mt-4 ml-2" onClick={onRequestClose}>
            {t("comeBack")}
          </button>
        </Fragment>
      )}
    </Dialog>
  );
}
