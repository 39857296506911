import React, { useState, useEffect, ReactComponentElement } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import { getVersion, loggedIn } from "./api/AuthApi";
import { Profile } from "./Profile";
import { Dashboard } from "./Dashboard";
import { Tests } from "./Tests/Tests";
import { isB2C, RedirectIfNotAdmin, RedirectIfNotLoggedIn } from "./Utils";
import { Businesses } from "./Businesses/Businesses";
import "react-datetime/css/react-datetime.css";
import { Home } from "./Home";
import { Modules } from "./Modules/users/Modules";
import { Module } from "./Modules/users/Module";
import { ModuleProgress } from "./Modules/users/ModuleProgress";
import { ShowTest } from "./Tests/ShowTest";
import { EditTest } from "./Tests/EditTest";
import { CreateTest } from "./Tests/CreateTest";
import { AdminModules } from "./Modules/admins/AdminModules";
import { CreateBusiness } from "./Businesses/CreateBusiness";
import { EditBusiness } from "./Businesses/EditBusiness";
import { EditModule } from "./Modules/admins/EditModule";
import { CreateModule } from "./Modules/admins/CreateModule";
import { ViewSubjects } from "./Modules/admins/ViewSubjects";
import { CreateSubject } from "./Modules/admins/CreateSubject";
import { EditSubject } from "./Modules/admins/EditSubject";
import { CreateLesson } from "./Modules/admins/CreateLesson";
import { EditLesson } from "./Modules/admins/EditLesson";
import { Checkout } from "./Checkout";
import { About } from "./About";
import { Lists } from "./Lists/Lists";
import { CreateList } from "./Lists/CreateList";
import { EditList } from "./Lists/EditList";
import { TestsView } from "./TestsView";
import { Media } from "./Media/Media";
import { TestTypes } from "./Tests/TestTypes";
import { Mindlab } from "./Mindlab";
import { GeneralTerms } from "./GeneralTerms";
import { PrivacyPolicy } from "./PrivacyPolicy";
import ScrollToTop from "./ScrollToTop";
import { Toolbox } from "./Toolbox";
import { Crisishulp } from "./Crisishulp";
import { EditUser } from "./Users/EditUser";
import { Users } from "./Users/Users";
import { BusinessMail } from "./BusinessMail";
import { MindlabMail } from "./MindlabMail";
import { Favorites } from "./Favorites";
import { ExercisesResults } from "./ExercisesResults";
import { BusinessInfo } from "./BusinessDashboard/BusinessInfo";
import { BusinessDashboard } from "./BusinessDashboard/BusinessDashboard";
import { BusinessStats } from "./BusinessDashboard/BusinessStats";
import { BusinessDocuments } from "./BusinessDashboard/BusinessDocuments";
import { ToolboxCertificates } from "./ToolboxCertificates";
import { TestResults } from "./TestResults";
import { Skills } from "./Modules/users/Skills";
import { Faq } from "./Faq";
import { CreateUser } from "./Users/CreateUser";
import { getCurrentUser } from "./api/UserApi";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";
import { Blog, BlogPage } from "./Blog";
import PasswordForgot from "./PasswordForgot";
import PasswordReset from "./PasswordReset";
import { Game } from "./Game";
import { Challenges } from "./Challenges/Challenges";
import { CreateChallenge } from "./Challenges/CreateChallenge";
import { EditChallenge } from "./Challenges/EditChallenge";
import { Discounts } from "./Discounts/Discounts";
import { CreateDiscount } from "./Discounts/CreateDiscount";
import { EditDiscount } from "./Discounts/EditDiscount";
import { Podcasts } from "./Podcasts/Podcasts";
import { CreatePodcast } from "./Podcasts/CreatePodcast";
import { EditPodcast } from "./Podcasts/EditPodcast";
import { Podcast } from "./Podcast";
import { Blogs } from "./Blogs/Blogs";
import { CreateBlog } from "./Blogs/CreateBlog";
import { EditBlog } from "./Blogs/EditBlog";
import { Quizzes } from "./Quizzes/Quizzes";
import { CreateQuiz } from "./Quizzes/CreateQuiz";
import { EditQuiz } from "./Quizzes/EditQuiz";
import { Logos } from "./Logos/Logos";
import { CreateLogo } from "./Logos/CreateLogo";
import { Testimonials } from "./Testimonials/Testimonials";
import { CreateTestimonial } from "./Testimonials/CreateTestimonial";
import Inactive from "./Inactive";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Partners } from "./Partners/Partners";
import { CreatePartner } from "./Partners/CreatePartner";
import { RedirectToSSO, SSORedirect } from "./SSORedirect";
import { GameGroup } from "./GameGroup";
import { GameStarted } from "./GameStarted";
import { Landingpage } from "./Landingpage";
import { Header } from "./Header";
import { StartFaceScan } from "./StartFaceScan";
import { FaceScannerModal } from "./FaceScannerModal";
import { DashboardDocuments } from "./DashboardDocuments/DashboardDocuments";
import { CreateDashboardDocument } from "./DashboardDocuments/CreateDashboardDocument";
import { EditDashboardDocument } from "./DashboardDocuments/EditDashboardDocument";
import { BusinessReports } from "./BusinessDashboard/BusinessReports";
import { IntelliProveApi } from "./api/IntelliProveApi";
import { SuggestFaceScanModal } from "./SuggestFaceScanModal";
import { DocumentsPage } from "./DocumentsPage";
import { UserDashboardPrograms } from "./UserDashboardPrograms";
import { UserDashboardCertificates } from "./UserDashboardCertificates";
import { UserDashboardReports } from "./UserDashboardReports";
import { UserDashboardFavoriteLessons } from "./UserDashboardFavoriteLessons";
import { UserDashboardExercises } from "./UserDashboardExercises";
import { UserDashboardTimes } from "./UserDashboardTimes";
import { FaceScannerInfoModal } from "./FaceScannerInfoModal";
import { LandingPageCms } from "./LandingPageCms";
import { FaqCms } from "./FaqCms";
import { UpdateTestimonial } from "./Testimonials/UpdateTestimonial";
import { ErrorSSO } from "./ErrorSSO";
import { SSOLanding } from "./SSOLanding";
import { SSORedirectV2 } from "./SSORedirectV2";

interface FaceScanStartData {
  periodic: boolean;
  showScannerModal: boolean;
}

function App() {
  const [authenticated, setAuthenticated] = useState(loggedIn());
  const [showSuggestFaceScanModal, setShowSuggestFaceScanModal] =
    useState(false);

  const [showInfoFaceScan, setShowInfoFaceScan] = useState(false);
  const [faceScanDue, setFaceScanDue] = useState(false);
  const [faceScanStartData, setFaceScanStartData] = useState<FaceScanStartData>(
    {
      periodic: false,
      showScannerModal: false,
    },
  );

  const { i18n } = useTranslation();

  useEffectOnce(() => {
    getCurrentUser().then(async (r) => {
      if (!r) {
        return;
      }

      localStorage.setItem("reset", "true");

      await i18n.changeLanguage(r.language);
    });

    getVersion().then((v) => {
      if (v !== process.env.NX_PUBLIC_VERSION) {
        window.location.reload();
      }
    });
  });

  const languages = ["nl", "en", "fr"];
  const routes = [
    {
      check: null,
      component: <Landingpage authenticated={authenticated} />,
      condition: true,
      path: "/",
    },
    {
      check: null,
      component: (
        <Home
          authenticated={authenticated}
          setShowSuggestFaceScanModal={setShowSuggestFaceScanModal}
        />
      ),
      condition: true,
      path: "/home",
    },
    {
      check: null,
      component: <Modules authenticated={authenticated} />,
      condition: true,
      path: "/modules",
    },
    {
      check: null,
      component: <Inactive />,
      condition: true,
      path: "/inactive",
    },
    {
      check: null,
      component: <Skills authenticated={authenticated} />,
      condition: true,
      path: "/skills",
    },
    {
      check: null,
      component: <Game />,
      condition: true,
      path: "/game/single",
    },
    {
      check: null,
      component: <GameGroup />,
      condition: true,
      path: "/game/group",
    },
    {
      check: null,
      component: <GameStarted />,
      condition: true,
      path: "/game/test",
    },
    {
      check: null,
      component: <Game />,
      condition: true,
      path: "/game",
    },
    {
      check: null,
      component: <Podcast authenticated={authenticated} />,
      condition: true,
      path: "/podcasts",
    },
    {
      check: null,
      component: <Module />,
      condition: true,
      path: "/modules/:moduleId",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <ModuleProgress />,
      condition: true,
      path: "/modules/:moduleId/progress",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <Checkout />,
      condition: true,
      path: "/checkout/:moduleId",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <ModuleProgress />,
      condition: true,
      path: "/modules/:moduleId/subjects/:subjectId/lessons/:lessonId",
    },
    {
      check: null,
      component: <Login setAuthenticated={setAuthenticated} />,
      condition: true,
      path: "/login",
    },
    {
      check: null,
      component: <SSOLanding setAuthenticated={setAuthenticated} />,
      condition: true,
      path: "/login/sso/:businessId",
    },
    {
      check: null,
      component: <SSORedirectV2 setAuthenticated={setAuthenticated} />,
      condition: true,
      path: "/login/ssoRedirect/:businessId",
    },
    {
      check: null,
      component: <Register setAuthenticated={setAuthenticated} />,
      condition: true,
      path: "/register",
    },
    {
      check: null,
      component: <Register setAuthenticated={setAuthenticated} />,
      condition: true,
      path: "/zna-registratie",
    },
    {
      check: null,
      component: <Blog authenticated={authenticated} />,
      condition: true,
      path: "/blog",
    },
    {
      check: null,
      component: <BlogPage />,
      condition: true,
      path: "/blog/:id",
    },
    {
      check: null,
      component: <Faq authenticated={authenticated} />,
      condition: true,
      path: "/faq",
    },
    {
      check: null,
      component: <PasswordForgot />,
      condition: true,
      path: "/password/reset",
    },
    {
      check: null,
      component: <PasswordReset />,
      condition: true,
      path: "/password-reset",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <Profile />,
      condition: true,
      path: "/profile",
    },
    {
      check: null,
      component: <About authenticated={authenticated} />,
      condition: true,
      path: "/about",
    },
    {
      check: null,
      component: <Mindlab />,
      condition: true,
      path: "/mindlab",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <ShowTest />,
      condition: true,
      path: "/tests-view/:testId",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <BusinessDashboard />,
      condition: true,
      path: "/dashboard/general",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <BusinessStats />,
      condition: true,
      path: "/dashboard/statistics",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <BusinessDocuments />,
      condition: true,
      path: "/dashboard/documents",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <DocumentsPage />,
      condition: true,
      path: "/dashboard/documents/:type",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <BusinessReports />,
      condition: true,
      path: "/dashboard/reports",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <BusinessInfo />,
      condition: true,
      path: "/dashboard/info",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <TestsView authenticated={authenticated} />,
      condition: true,
      path: "/tests",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <TestsView authenticated={authenticated} />,
      condition: true,
      path: "/tests/:typeId",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <TestsView authenticated={authenticated} />,
      condition: true,
      path: "/tests/:typeId/test/:testId",
    },
    {
      check: null,
      component: <GeneralTerms />,
      condition: true,
      path: "/general-terms",
    },
    {
      check: null,
      component: <PrivacyPolicy />,
      condition: true,
      path: "/privacy-policy",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <UserDashboardPrograms />,
      condition: true,
      path: "/user-dashboard",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <UserDashboardPrograms />,
      condition: true,
      path: "/user-dashboard/programs",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <UserDashboardCertificates />,
      condition: true,
      path: "/user-dashboard/certificates",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <UserDashboardReports />,
      condition: true,
      path: "/user-dashboard/reports",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <UserDashboardFavoriteLessons />,
      condition: true,
      path: "/user-dashboard/favorite-lessons",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <UserDashboardExercises />,
      condition: true,
      path: "/user-dashboard/exercises",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <UserDashboardTimes />,
      condition: true,
      path: "/user-dashboard/times",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <Crisishulp />,
      condition: true,
      path: "/user-dashboard/crisis",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <Toolbox />,
      condition: true,
      path: "/toolbox",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <Crisishulp />,
      condition: true,
      path: "/toolbox/crisis",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <Favorites />,
      condition: true,
      path: "/toolbox/favorites",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <ToolboxCertificates />,
      condition: true,
      path: "/toolbox/certificates",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <ExercisesResults />,
      condition: true,
      path: "/toolbox/exercises",
    },
    {
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      component: <TestResults />,
      condition: true,
      path: "/toolbox/tests",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <Dashboard />,
      condition: true,
      path: "/dashboard",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <Users />,
      condition: true,
      path: "/dashboard/users",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <CreateUser />,
      condition: true,
      path: "/dashboard/users/new",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <EditUser />,
      condition: true,
      path: "/dashboard/users/:userId",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <Tests />,
      condition: true,
      path: "/dashboard/tests",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <CreateTest />,
      condition: true,
      path: "/dashboard/tests/new",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <EditTest />,
      condition: true,
      path: "/dashboard/tests/:testId",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <AdminModules />,
      condition: true,
      path: "/dashboard/modules",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <CreateModule />,
      condition: true,
      path: "/dashboard/modules/new",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <EditModule />,
      condition: true,
      path: "/dashboard/modules/:moduleId",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <ViewSubjects />,
      condition: true,
      path: "/dashboard/modules/:moduleId/subjects",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <CreateSubject />,
      condition: true,
      path: "/dashboard/modules/:moduleId/subjects/new",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <EditSubject />,
      condition: true,
      path: "/dashboard/modules/:moduleId/subjects/:subjectId",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <CreateLesson />,
      condition: true,
      path: "/dashboard/modules/:moduleId/subjects/:subjectId/lessons/new",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <EditLesson />,
      condition: true,
      path: "/dashboard/modules/:moduleId/subjects/:subjectId/lessons/:lessonId",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <Businesses />,
      condition: true,
      path: "/dashboard/businesses",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <CreateBusiness />,
      condition: true,
      path: "/dashboard/businesses/new",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <Media />,
      condition: true,
      path: "/dashboard/media",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <TestTypes />,
      condition: true,
      path: "/dashboard/test-types",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <EditBusiness />,
      condition: true,
      path: "/dashboard/businesses/:businessId",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <Lists />,
      condition: true,
      path: "/dashboard/lists",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <CreateList />,
      condition: true,
      path: "/dashboard/lists/new",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <Logos />,
      condition: true,
      path: "/dashboard/logos",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <CreateLogo />,
      condition: true,
      path: "/dashboard/logos/new",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <Partners />,
      condition: true,
      path: "/dashboard/partners",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <CreatePartner />,
      condition: true,
      path: "/dashboard/partners/new",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <Testimonials />,
      condition: true,
      path: "/dashboard/testimonials",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <CreateTestimonial />,
      condition: true,
      path: "/dashboard/testimonials/new",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <UpdateTestimonial />,
      condition: true,
      path: "/dashboard/testimonials/:id",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <EditList />,
      condition: true,
      path: "/dashboard/lists/:listId",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <Challenges />,
      condition: true,
      path: "/dashboard/challenges",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <CreateChallenge />,
      condition: true,
      path: "/dashboard/challenges/new",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <EditChallenge />,
      condition: true,
      path: "/dashboard/challenges/:challengeId",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <Podcasts />,
      condition: true,
      path: "/dashboard/podcasts",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <CreatePodcast />,
      condition: true,
      path: "/dashboard/podcasts/new",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <EditPodcast />,
      condition: true,
      path: "/dashboard/podcasts/:podcastId",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <DashboardDocuments />,
      condition: true,
      path: "/dashboard/dashboard-documents",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <CreateDashboardDocument />,
      condition: true,
      path: "/dashboard/dashboard-documents/new",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <EditDashboardDocument />,
      condition: true,
      path: "/dashboard/dashboard-documents/:documentId",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <Blogs />,
      condition: true,
      path: "/dashboard/blogs",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <CreateBlog />,
      condition: true,
      path: "/dashboard/blogs/new",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <EditBlog />,
      condition: true,
      path: "/dashboard/blogs/:blogId",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <Quizzes />,
      condition: true,
      path: "/dashboard/quizzes",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <CreateQuiz />,
      condition: true,
      path: "/dashboard/quizzes/new",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <EditQuiz />,
      condition: true,
      path: "/dashboard/quizzes/:blogId",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <Discounts />,
      condition: true,
      path: "/dashboard/discounts",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <CreateDiscount />,
      condition: true,
      path: "/dashboard/discounts/new",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <EditDiscount />,
      condition: true,
      path: "/dashboard/discounts/:name",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <FaqCms />,
      condition: true,
      path: "/dashboard/faq",
    },
    {
      check: <RedirectIfNotAdmin />,
      component: <LandingPageCms />,
      condition: true,
      path: "/dashboard/landingpage",
    },
    {
      check: null,
      component: <ErrorSSO />,
      condition: true,
      path: "/error/sso",
    },
    {
      check: null,
      component: <SSORedirect setAuthenticated={setAuthenticated} />,
      condition: true,
      path: "/sso/:token",
    },
    {
      check: null,
      component: (
        <RedirectToSSO
          url={`${process.env.NX_PUBLIC_API_URL}/sso/signin?business=01GQ4KS7WRD7BR93SEFNMCCWAA`}
        />
      ),
      condition: true,
      path: "/fpc/gent/sso",
    },
    {
      check: null,
      component: (
        <RedirectToSSO
          url={`${process.env.NX_PUBLIC_API_URL}/sso/signin?business=01GKMSG90Y7B9GE5546B3KE11X`}
        />
      ),
      condition: true,
      path: "/fpc/antwerpen/sso",
    },
    {
      check: null,
      component: (
        <RedirectToSSO
          url={`${process.env.NX_PUBLIC_API_URL}/sso/signin?business=01GBQAHCNSR419TTVC6WM357F0`}
        />
      ),
      condition: true,
      path: "/dieteren/sso",
    },
    {
      check: null,
      component: (
        <RedirectToSSO
          url={`${process.env.NX_PUBLIC_API_URL}/sso/signin?business=01HASDDKMZ12KGGTANNTEYC1AJ`}
        />
      ),
      condition: true,
      path: "/uzgent/sso",
    },
    {
      check: null,
      component: (
        <RedirectToSSO
          url={`${process.env.NX_PUBLIC_API_URL}/sso/signin?business=01HEN13FJYBMCD0RVMVZ1A16KR`}
        />
      ),
      condition: true,
      path: "/sbs/sso",
    },
  ];

  const components: ReactComponentElement<any>[] = [];

  routes.forEach((route) => {
    if (route.condition) {
      components.push(
        <Route
          element={
            <>
              {route.check}
              {route.component}
            </>
          }
          path={`${route.path}`}
        />,
      );
    }

    return languages.forEach((language) => {
      if (route.condition) {
        components.push(
          <Route
            element={
              <>
                {route.check}
                {route.component}
              </>
            }
            path={`/${language}${route.path}`}
          />,
        );
      }
    });
  });

  useEffect(() => {
    if (authenticated) {
      IntelliProveApi.checkScanDue().then((due) => setFaceScanDue(due));
    }
  }, [authenticated]);

  useEffect(() => {
    if (faceScanDue) {
      if (localStorage.getItem("facescan") === "true") {
        return;
      }

      localStorage.setItem("facescan", "true");
      setShowSuggestFaceScanModal(true);
    }
  }, [faceScanDue]);

  return (
    <Router>
      <ScrollToTop />
      <Header
        authenticated={authenticated}
        setAuthenticated={(state: boolean) => {
          setAuthenticated(state);
        }}
      />
      <BusinessMail authenticated={authenticated} />
      <MindlabMail />
      <Routes>{components}</Routes>
      {authenticated ? (
        <StartFaceScan
          onClick={() =>
            setFaceScanStartData({
              periodic: false,
              showScannerModal: true,
            })
          }
        />
      ) : null}
      <SuggestFaceScanModal
        authenticated={authenticated}
        isOpen={showSuggestFaceScanModal}
        onAcceptScan={() => {
          setFaceScanStartData({
            periodic: true,
            showScannerModal: true,
          });
          localStorage.removeItem("facescan");
          setFaceScanDue(false);
          setShowSuggestFaceScanModal(false);
        }}
        onRequestClose={() => setShowSuggestFaceScanModal(false)}
        onRequestInfo={() => {
          setShowInfoFaceScan(true);
          setShowSuggestFaceScanModal(false);
        }}
      />
      <FaceScannerModal
        isOpen={faceScanStartData.showScannerModal}
        onRequestClose={() =>
          setFaceScanStartData({
            periodic: faceScanStartData.periodic,
            showScannerModal: false,
          })
        }
        periodic={faceScanStartData.periodic}
      />
      <FaceScannerInfoModal
        isOpen={showInfoFaceScan}
        onAcceptScan={() => {
          setFaceScanStartData({
            periodic: true,
            showScannerModal: true,
          });
          localStorage.removeItem("facescan");
          setFaceScanDue(false);
          setShowSuggestFaceScanModal(false);
          setShowInfoFaceScan(false);
        }}
        onRequestClose={() => setShowInfoFaceScan(false)}
      />
    </Router>
  );
}

export default App;
