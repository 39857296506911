import React, { Fragment, useEffect, useState } from "react";
import "./Module.css";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import { BiBookAlt } from "react-icons/bi";
import { BsTextCenter } from "react-icons/bs";
import {
  getModule,
  getModuleAccess,
  Module as ModuleType,
} from "../../api/ModulesApi";
import { Link, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { getSubjects, Subject } from "../../api/SubjectsApi";
import { getLessonsByModuleAndSubject, Lesson } from "../../api/LessonsApi";
import {
  getCurrentUser,
  getModuleProgress,
  ModuleProgress as Progress,
  User,
} from "../../api/UserApi";
import {
  getCorrectUrl,
  isB2C,
  lessonTypeToImage,
  typeStringToLessonType,
} from "../../Utils";
import { useTranslation } from "react-i18next";
import { Certificate } from "../../Certificate";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";

export function Module() {
  const { moduleId } = useParams<{ moduleId: string }>();
  const [module, setModule] = useState<ModuleType>();
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [moduleAccess, setModuleAccess] = useState<string[]>([]);
  const [moduleProgress, setModuleProgress] = useState<Progress[]>([]);
  const [openAll, setOpenAll] = useState(false);
  const { t, i18n } = useTranslation("general");
  const [user, setUser] = useState<User>();

  const available = moduleAccess.includes(moduleId) || module?.price === 0;

  useEffectOnce(() => {
    getModule(moduleId).then((result) => {
      setModule(result);
    });

    getSubjects(moduleId).then((result) => {
      setSubjects(result);
    });

    getModuleAccess().then((result) => {
      setModuleAccess(result);
    });

    getModuleProgress(moduleId).then(async (result) => {
      setModuleProgress(result);
    });

    getCurrentUser().then((r) => {
      setUser(r);
    });
  });

  async function fetchLessons() {
    const tempLessons: Lesson[] = [];

    for (const subject of subjects) {
      const subjectLessons = await getLessonsByModuleAndSubject(
        moduleId,
        subject.id,
      );

      tempLessons.push(...subjectLessons);
    }

    setLessons(tempLessons);
  }

  useEffect(() => {
    if (subjects.length === 0) {
      return;
    }

    fetchLessons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjects]);

  function getCorrectName(lesson: Lesson) {
    if (!lesson) {
      return null;
    }

    return i18n.language === "nl"
      ? lesson.nameNL
      : i18n.language === "en"
      ? lesson.nameEN
      : lesson.nameFR;
  }

  function getFirstLesson() {
    let index = 0;

    if (lessons.length === 0) {
      return null;
    }

    while (
      getCorrectName(lessons[index]) === "" &&
      !getCorrectName(lessons[index])
    ) {
      index++;
    }

    return lessons[index];
  }

  const firstLesson = getFirstLesson();

  const latestVisit = moduleProgress
    ? moduleProgress[moduleProgress.length - 1]
    : null;

  function ModuleBox() {
    return (
      <>
        <img className="image" src={getCorrectUrl(module?.image || "")} />
        <div className="bar-content">
          {!available && isB2C() ? (
            <div className="flex items-center">
              <Link
                className="button yellow xsmall"
                to={`/checkout/${module?.id}`}
              >
                {t("buy")}
              </Link>
              <div className="ml-auto pricing">&euro; {module?.price}</div>
            </div>
          ) : null}
          {available && lessons.length > 0 ? (
            <Link
              className="button yellow center"
              to={
                latestVisit
                  ? `/modules/${moduleId}/subjects/${latestVisit.subjectId}/lessons/${latestVisit.lessonId}`
                  : `/modules/${moduleId}/subjects/${firstLesson?.subjectId}/lessons/${firstLesson?.id}`
              }
            >
              {t("start")}
            </Link>
          ) : null}

          <div className="items-title">{t("contains")}</div>
          <div className="item">
            <BiBookAlt />{" "}
            {
              subjects.filter((s) => {
                if (i18n.language === "nl") {
                  return s.nameNL !== "";
                }

                if (i18n.language === "en") {
                  return s.nameEN !== "";
                }

                if (i18n.language === "fr") {
                  return s.nameFR !== "";
                }

                return true;
              }).length
            }{" "}
            {t("lessons")}
          </div>
          <div className="item">
            <BsTextCenter />
            {
              lessons.filter((s) => {
                if (i18n.language === "nl") {
                  return s.nameNL !== "";
                }

                if (i18n.language === "en") {
                  return s.nameEN !== "";
                }

                if (i18n.language === "fr") {
                  return s.nameFR !== "";
                }

                return true;
              }).length
            }{" "}
            {t("subjects")}
          </div>
          {/*<div className="item">
                  <BsQuestionCircle />1 Quiz
                </div>*/}
        </div>
      </>
    );
  }

  return (
    <div className="module-detail">
      <div>
        <div className="banner">
          <div className="wrapper">
            <div className="left content">
              <div className="module-title">
                {i18n.language === "nl" && module?.titleNL}
                {i18n.language === "en" && module?.titleEN}
                {i18n.language === "fr" && module?.titleFR}
              </div>
              <div className="description">
                {i18n.language === "nl" && module?.descriptionNL}
                {i18n.language === "en" && module?.descriptionEN}
                {i18n.language === "fr" && module?.descriptionFR}
              </div>
            </div>
          </div>
        </div>
        <div className="mobile box">
          <ModuleBox />
        </div>
        <div className="wrapper">
          <div className="bar-wrapper">
            <div className="hover-bar">
              <ModuleBox />
            </div>
          </div>
        </div>
        <div className="wrapper lessons">
          <div className="left">
            <div className="extra">
              {/*<strong>
                Deze module is bedoeld voor personen die al langer dan 3 maanden
                hinder ondervinden van slaapproblemen. Zijn je slaapproblemen
                recenter gestart, dan raden we je aan om eerst de module stress
                of mindfulness te doorlopen.
              </strong>*/}
            </div>
            {lessons.length !== 0 && moduleProgress.length >= lessons.length ? (
              <div className="module-certificate">
                <PDFDownloadLink
                  document={
                    <Certificate
                      module={
                        i18n.language === "nl"
                          ? module?.titleNL || ""
                          : i18n.language === "en"
                          ? module?.titleEN || ""
                          : module?.titleFR || ""
                      }
                      name={`${user?.firstName} ${user?.lastName}`}
                    />
                  }
                >
                  <div className="center">{t("download_cert")}</div>
                </PDFDownloadLink>
              </div>
            ) : null}
            <div>
              <div className="flex">
                <div className="title">{t("content")}</div>
                <div
                  className="ml-auto toggle pointer"
                  onClick={() => {
                    setOpenAll(!openAll);
                  }}
                >
                  {openAll ? t("collapse") : t("expand")}
                </div>
              </div>
              {subjects
                .filter((m) => {
                  return (
                    (i18n.language === "fr" && m.nameFR.trim() !== "") ||
                    (i18n.language === "en" && m.nameEN.trim() !== "") ||
                    i18n.language === "nl"
                  );
                })
                .map((subject) => {
                  const subjectLessons = lessons.filter(
                    (lesson) => lesson.subjectId === subject.id,
                  );

                  return (
                    <ModuleDropdown
                      available={available}
                      lessons={subjectLessons}
                      openAll={openAll}
                      subject={subject}
                      visited={moduleProgress.map((i) => i.lessonId)}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ModuleDropdown({
  subject,
  lessons,
  visited,
  openAll,
  available,
}: {
  available: boolean;
  lessons: Lesson[];
  openAll: boolean;
  subject: Subject;
  visited: string[];
}) {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation("general");

  useEffect(() => {
    setOpen(openAll);
  }, [openAll]);

  return (
    <>
      <div
        className="flex lesson"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="name">
          {open ? <AiFillCaretDown /> : <AiFillCaretRight />}{" "}
          {i18n.language === "nl" && subject.nameNL}
          {i18n.language === "en" && subject.nameEN}
          {i18n.language === "fr" && subject.nameFR}
        </div>
        <div className="ml-auto subjects-count">
          {lessons.length} {t("subjects")}
        </div>
      </div>
      {open ? (
        <>
          {lessons.map((lesson, index) => {
            const name =
              i18n.language === "nl"
                ? lesson.nameNL
                : i18n.language === "en"
                ? lesson.nameEN
                : lesson.nameFR;

            if (!name || name === "") {
              return null;
            }

            const content = (
              <Fragment>
                <img
                  src={lessonTypeToImage(typeStringToLessonType(lesson.type))}
                />
                <div>{name}</div>
                {visited.includes(lesson.id) ? (
                  <div className="ml-auto visited-check">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                ) : null}
              </Fragment>
            );

            if (visited.includes(lesson.id)) {
              return (
                <Link
                  className={`subject ${
                    visited.includes(lesson.id) ? "visited" : ""
                  }`}
                  key={index}
                  to={
                    available
                      ? `/modules/${lesson.moduleId}/subjects/${subject.id}/lessons/${lesson.id}`
                      : "#"
                  }
                >
                  {content}
                </Link>
              );
            }

            return <div className={`subject no-pointer`}>{content}</div>;
          })}
        </>
      ) : null}
    </>
  );
}
