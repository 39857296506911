import React, { useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { createLessons, Lesson, updateLesson } from "../../api/LessonsApi";
import { LessonType, typeStringToLessonType } from "../../Utils";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const QuillModules = {
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["link", "image"],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"],
  ],
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
export const QuillFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export function LessonForm({ lesson }: { lesson: Lesson | null }) {
  const { moduleId, subjectId, lessonId } = useParams<{
    lessonId: string;
    moduleId: string;
    subjectId: string;
  }>();

  const [nameNL, setNameNL] = useState(lesson ? lesson.nameNL : "");
  const [nameEN, setNameEN] = useState(lesson ? lesson.nameEN : "");
  const [nameFR, setNameFR] = useState(lesson ? lesson.nameFR : "");
  const [pos, setPos] = useState(lesson ? lesson.pos : 1);
  const [contentNL, setContentNL] = useState(lesson ? lesson.contentNL : "");
  const [contentEN, setContentEN] = useState(lesson ? lesson.contentEN : "");
  const [contentFR, setContentFR] = useState(lesson ? lesson.contentFR : "");
  const [type, setType] = useState<LessonType>(
    lesson ? typeStringToLessonType(lesson.type) : LessonType.TEXT,
  );

  const [navigate, setNavigate] = useState<null | string>(null);

  const [saved, setSaved] = useState(false);

  if (navigate) {
    return (
      <Navigate
        to={`/dashboard/modules/${moduleId}/subjects/${subjectId}/lessons/${navigate}`}
      />
    );
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        const updatedLesson = {
          contentEN: contentEN,
          contentFR: contentFR,
          contentNL: contentNL,
          id: "",
          moduleId: "",
          nameEN: nameEN || "",
          nameFR: nameFR || "",
          nameNL: nameNL,
          pos: pos,
          subjectId: "",
          type: type,
        };

        if (lesson) {
          await updateLesson(moduleId, subjectId, {
            ...updatedLesson,
            id: lesson.id,
          });
        } else {
          const result = await createLessons(
            moduleId,
            subjectId,
            updatedLesson,
          );

          setNavigate(result.id);
        }

        setSaved(true);
        setTimeout(() => {
          setSaved(false);
        }, 3000);
      }}
    >
      <div className="container overflow-unset">
        <div>
          <div>
            <h4>Algemene info</h4>
            <div className="flex">
              <div className="ml-5">
                <label>Naam in het Nederlands</label>
                <input
                  className="input"
                  onChange={(input) => {
                    setNameNL(input.target.value);
                  }}
                  placeholder="Naam"
                  required={true}
                  type="text"
                  value={nameNL}
                />
              </div>
              <div className="ml-5">
                <label>Naam in het Engels</label>
                <input
                  className="input"
                  onChange={(input) => {
                    setNameEN(input.target.value);
                  }}
                  placeholder="Naam"
                  type="text"
                  value={nameEN}
                />
              </div>
              <div className="ml-5">
                <label>Naam in het Frans</label>
                <input
                  className="input"
                  onChange={(input) => {
                    setNameFR(input.target.value);
                  }}
                  placeholder="Naam"
                  type="text"
                  value={nameFR}
                />
              </div>
            </div>
            <div>
              <label>Les type</label>
              <Select
                className="input mt-4"
                onChange={(e: any) => {
                  setType(typeStringToLessonType(e.value));
                }}
                options={Object.values(LessonType).map((item) => {
                  return {
                    label: item,
                    value: item,
                  };
                })}
                placeholder={"Les type"}
                value={{ label: type, value: type }}
              />
              <label>Volgorde</label>
              <input
                className="input"
                min={0}
                onChange={(e) => {
                  setPos(parseInt(e.target.value));
                }}
                type="number"
                value={pos}
              />
            </div>

            {saved ? (
              <div className="mt-4 mb-4">Aanpassingen opgeslagen</div>
            ) : null}

            <div>
              <button className="button" type="submit">
                {lesson ? "Les bijwerken" : "Les toevoegen"}
              </button>
              <Link
                className="button w-fit"
                target={"_blank"}
                to={`/modules/${moduleId}/subjects/${subjectId}/lessons/${lessonId}`}
              >
                Bekijk les
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="flex">
          <h4>Content in het Nederlands</h4>
        </div>
        <div style={{ backgroundColor: "white" }}>
          <ReactQuill
            formats={QuillFormats}
            modules={QuillModules}
            onChange={(value) => {
              setContentNL(value);
            }}
            theme="snow"
            value={contentNL}
          />
        </div>
      </div>
      <div className="container">
        <div className="flex">
          <h4>Content in het Engels</h4>
        </div>
        <div style={{ backgroundColor: "white" }}>
          <ReactQuill
            formats={QuillFormats}
            modules={QuillModules}
            onChange={(value) => {
              setContentEN(value);
            }}
            theme="snow"
            value={contentEN}
          />
        </div>
      </div>
      <div className="container">
        <div className="flex">
          <h4>Content in het Frans</h4>
        </div>
        <div style={{ backgroundColor: "white" }}>
          <ReactQuill
            formats={QuillFormats}
            modules={QuillModules}
            onChange={(value) => {
              setContentFR(value);
            }}
            theme="snow"
            value={contentFR}
          />
        </div>
      </div>
    </form>
  );
}
