import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type List = {
  id: string;
  name: string;
};

export type ListItem = {
  id: string;
  listId: string;
  nameEN: string;
  nameFR: string;
  nameNL: string;
};

export async function getLists(): Promise<List[]> {
  const result = await fetch(`${API_ENDPOINT}/lists`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.lists;
}

export async function getList(id: string): Promise<List> {
  const result = await fetch(`${API_ENDPOINT}/lists/${id}`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.list;
}

export async function createList(name: string): Promise<List> {
  const result = await fetch(`${API_ENDPOINT}/lists`, {
    body: JSON.stringify({
      name: name,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}

export async function updateList(id: string, name: string): Promise<List> {
  const result = await fetch(`${API_ENDPOINT}/lists/${id}`, {
    body: JSON.stringify({
      name: name,
    }),
    headers: getHeaders(),
    method: "PUT",
  }).then((r) => r.json());

  return result;
}

export async function getListItems(listId: string): Promise<ListItem[]> {
  const result = await fetch(`${API_ENDPOINT}/lists/${listId}/items`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.items;
}

export async function createListItems(
  listId: string,
  items: { listId: string; nameEN: string; nameFR: string; nameNL: string }[],
): Promise<List> {
  const result = await fetch(`${API_ENDPOINT}/lists/${listId}/items`, {
    body: JSON.stringify({
      items: items,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result.items;
}
