import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type Subject = {
  id: string;
  nameEN: string;
  nameFR: string;
  nameNL: string;
};

export async function getSubjects(moduleId: string): Promise<Subject[]> {
  const result = await fetch(`${API_ENDPOINT}/modules/${moduleId}/subjects`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.subjects) {
    return [];
  }

  return result.subjects;
}

export async function createSubject(
  moduleId: string,
  subject: Subject,
): Promise<Subject> {
  const result = await fetch(`${API_ENDPOINT}/modules/${moduleId}/subjects`, {
    body: JSON.stringify(subject),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}

export async function updateSubject(
  moduleId: string,
  subject: Subject,
): Promise<Subject> {
  const result = await fetch(
    `${API_ENDPOINT}/modules/${moduleId}/subjects/${subject.id}`,
    {
      body: JSON.stringify(subject),
      headers: getHeaders(),
      method: "PUT",
    },
  ).then((r) => r.json());

  return result;
}

export async function getSubject(
  moduleId: string,
  subjectId: string,
): Promise<Subject> {
  const result = await fetch(
    `${API_ENDPOINT}/modules/${moduleId}/subjects/${subjectId}`,
    {
      headers: getHeaders(),
      method: "GET",
    },
  ).then((r) => r.json());

  return result.subject;
}

export async function deleteSubject(
  moduleId: string,
  subjectId: string,
): Promise<void> {
  await fetch(`${API_ENDPOINT}/modules/${moduleId}/subjects/${subjectId}`, {
    headers: getHeaders(),
    method: "DELETE",
  }).then((r) => r.json());
}
