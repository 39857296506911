import React from "react";

export function ErrorSSO() {
  return (
    <div className="auth login">
      <h2>Login failed</h2>
      <div>The login method you tried didn't work, please try again.</div>
    </div>
  );
}
