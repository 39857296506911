import React, { useState } from "react";

export function FaqItem({ title, text }: { text: string; title: string }) {
  const [open, setOpen] = useState(false);

  return (
    <div
      className="faq-item pointer"
      onClick={() => {
        setOpen(!open);
      }}
    >
      <h4 className="break-spaces">{title}</h4>
      {open ? <div className="break-spaces">{text}</div> : null}
    </div>
  );
}
