import { API_ENDPOINT, getHeaders } from "./AuthApi";

type Challenge = {
  id: string;
  nameEN: string;
  nameFR: string;
  nameNL: string;
  textEN: string;
  textFR: string;
  textNL: string;
};

export type FeedItem = {
  id: string;
  name?: string;
  text: string;
  timestamp: string;
};

export async function startGroupGame(nickname: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/game-group/start`, {
    body: JSON.stringify({ nickname: nickname }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());
}

export async function stopGroupGame(): Promise<void> {
  await fetch(`${API_ENDPOINT}/game-group/stop`, {
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());
}

export async function acceptChallenge(): Promise<void> {
  await fetch(`${API_ENDPOINT}/game-group/accept`, {
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());
}

export async function isPlaying(): Promise<boolean> {
  const result = await fetch(`${API_ENDPOINT}/game-group/playing`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.yes;
}

export async function alreadyAccepted(): Promise<boolean> {
  const result = await fetch(`${API_ENDPOINT}/game-group/accepted`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.yes;
}

export async function getPlayers(): Promise<string[]> {
  const result = await fetch(`${API_ENDPOINT}/game-group/players`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.names;
}

export async function getSystemFeed(): Promise<FeedItem[]> {
  const result = await fetch(`${API_ENDPOINT}/game-group/feed-system`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.items;
}

export async function getUserFeed(): Promise<FeedItem[]> {
  const result = await fetch(`${API_ENDPOINT}/game-group/user-system`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.items;
}

export async function postUserFeed(text: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/game-group/user-system`, {
    body: JSON.stringify({ text: text }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());
}

export async function comment(text: string, postId: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/game-group/comment/${postId}`, {
    body: JSON.stringify({ text: text }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());
}

export async function listComments(postId: string): Promise<FeedItem[]> {
  const result = await fetch(`${API_ENDPOINT}/game-group/comment/${postId}`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result.comments;
}

export async function getGroupCurrentChallenge(): Promise<Challenge> {
  const result = await fetch(`${API_ENDPOINT}/game-group/challenge`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  return result;
}
