import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import {
  List,
  ListItem,
  getListItems,
  updateList,
  createList,
  createListItems,
} from "../api/ListApi";
import { useEffectOnce } from "react-use";
import { FaTimes } from "react-icons/fa";
import { uniqueId } from "../uniqueId";

export function ListForm({ list }: { list: List | null }) {
  const [name, setName] = useState(list ? list.name : "");
  const [items, setItems] = useState<ListItem[]>([]);

  const [saved, setSaved] = useState(false);

  useEffectOnce(() => {
    if (list) {
      getListItems(list.id).then((result) => {
        setItems(result);
      });
    }
  });

  if (saved) {
    return <Navigate to={"/dashboard/lists"} />;
  }

  return (
    <form
      onSubmit={async (e) => {
        // TODO checks on fields
        e.preventDefault();

        let listId = list?.id || "";

        if (list) {
          const result = await updateList(list.id, name);

          if (result) {
            listId = result.id;
          }
        } else {
          const result = await createList(name);

          if (result) {
            listId = result.id;
          }
        }

        if (!listId) {
          return;
        }

        await createListItems(
          listId,
          items
            .filter((item) => item.nameNL !== "")
            .map((item) => {
              return {
                ...item,
                listId: listId,
              };
            }),
        );

        setSaved(true);
      }}
    >
      <div className="flex justify-evenly pb-4">
        <div className="w-25">
          <div>
            <h4>Algemene info</h4>
            <label>Naam</label>
            <input
              className="input"
              onChange={(input) => {
                setName(input.target.value);
              }}
              placeholder="Naam"
              required={true}
              type="text"
              value={name}
            />
            <button className="button" type="submit">
              {list ? "Lijst bijwerken" : "Lijst toevoegen"}
            </button>
          </div>
        </div>
        <div className="w-25">
          <h4>Items</h4>
          {items.map((item, index) => {
            return (
              <div key={index}>
                {index > 0 ? <div className="hr" /> : null}
                <div className="flex">
                  <label>Naam in het Nederlands</label>
                  <div
                    className="delete ml-auto"
                    onClick={() => {
                      setItems(items.filter((g) => g.id !== item.id));
                    }}
                  >
                    <FaTimes />
                  </div>
                </div>
                <input
                  className="input"
                  onChange={(input) => {
                    setItems(
                      items.map((g) => {
                        if (g.id === item.id) {
                          return {
                            ...g,
                            nameNL: input.target.value,
                          };
                        }

                        return g;
                      }),
                    );
                  }}
                  placeholder="Titel"
                  type="text"
                  value={item.nameNL}
                />
                <label>Naam in het Engels</label>
                <input
                  className="input"
                  onChange={(input) => {
                    setItems(
                      items.map((g) => {
                        if (g.id === item.id) {
                          return {
                            ...g,
                            nameEN: input.target.value,
                          };
                        }

                        return g;
                      }),
                    );
                  }}
                  placeholder="Titel"
                  type="text"
                  value={item.nameEN}
                />
                <label>Naam in het Frans</label>
                <input
                  className="input"
                  onChange={(input) => {
                    setItems(
                      items.map((g) => {
                        if (g.id === item.id) {
                          return {
                            ...g,
                            nameFR: input.target.value,
                          };
                        }

                        return g;
                      }),
                    );
                  }}
                  placeholder="Titel"
                  type="text"
                  value={item.nameFR}
                />
              </div>
            );
          })}
          <div
            className="pointer yellow"
            onClick={() => {
              setItems(
                items.concat({
                  id: uniqueId(),
                  listId: list?.id || uniqueId(),
                  nameEN: "",
                  nameFR: "",
                  nameNL: "",
                }),
              );
            }}
          >
            + Item toevoegen
          </div>
        </div>
      </div>
    </form>
  );
}
