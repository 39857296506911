import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { addMedia } from "../api/MediaApi";
import { createPartners } from "../api/PartnersApi";

export function PartnerForm() {
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>();

  const [saved, setSaved] = useState(false);

  if (saved) {
    return <Navigate to={"/dashboard/partners"} />;
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        let updatedBusiness = {
          url: "",
        };

        if (selectedImageFile) {
          const pathResult = await addMedia(selectedImageFile);

          if (pathResult && typeof pathResult !== "string" && pathResult.path) {
            updatedBusiness = {
              ...updatedBusiness,
              url: pathResult.path,
            };
          }
        }

        createPartners(updatedBusiness.url).then(async () => {
          setSaved(true);
        });
      }}
    >
      <div className="flex justify-evenly pb-4">
        <div className="w-25">
          <div>
            <h4>Algemene info</h4>
            <label>Afbeelding</label>
            <input
              className="input"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  setSelectedImageFile(e.target.files[0]);
                }
              }}
              placeholder="Upload afbeelding"
              type="file"
            />

            <button className="button" type="submit">
              Partner toevoegen
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
