import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { addImage, Business, listBusinesses } from "../../api/BusinessApi";
import { Checkbox } from "../../Checkbox";
import { createModule, Module, updateModule } from "../../api/ModulesApi";
import { getCorrectUrl } from "../../Utils";

export function ModuleForm({ module }: { module: Module | null }) {
  const [infoNL, setInfoNL] = useState(module ? module.descriptionNL : "");
  const [infoEN, setInfoEN] = useState(module ? module.descriptionEN : "");
  const [infoFR, setInfoFR] = useState(module ? module.descriptionFR : "");
  const [nameNL, setNameNL] = useState(module ? module.titleNL : "");
  const [nameEN, setNameEN] = useState(module ? module.titleEN : "");
  const [nameFR, setNameFR] = useState(module ? module.titleFR : "");
  const [privateModule, setPrivateModule] = useState(
    module ? !module.isPublic : false,
  );

  const [isSkill, setIsSkill] = useState(module ? module.skills : false);
  const [price, setPrice] = useState(module ? module.price.toString() : "0");
  const [order, setOrder] = useState(module ? module.order.toString() : "0");
  const [businesses, setBusinesses] = useState<Business[]>([]);

  const acceptedTypes: string[] = ["image/*"];
  const [selectedFile, setSelectedFile] = useState<File | null>();

  const [saved, setSaved] = useState(false);

  const moduleImage = module ? module.image : "";

  useEffect(() => {
    if (module) {
      listBusinesses().then((r) => {
        setBusinesses(r);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (saved) {
    return <Navigate to={"/dashboard/modules"} />;
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        let updatedModule = {
          descriptionEN: infoEN,
          descriptionFR: infoFR,
          descriptionNL: infoNL,
          id: "",
          image: moduleImage,
          isPublic: !privateModule,
          linkedBusinesses: businesses.map((b) => b.id),
          order: parseInt(order),
          price: parseFloat(price),
          skills: isSkill,
          titleEN: nameEN,
          titleFR: nameFR,
          titleNL: nameNL,
          video: "",
        };

        let image;

        if (selectedFile) {
          image = await addImage(selectedFile);
        }

        if (image && typeof image !== "string" && image.path) {
          updatedModule = {
            ...updatedModule,
            image: image.path,
          };
        }

        if (module) {
          await updateModule({
            ...updatedModule,
            id: module.id,
          });
        } else {
          await createModule(updatedModule);
        }

        setSaved(true);
      }}
    >
      <div className="flex justify-evenly pb-4">
        <div className="w-25">
          <div>
            <h4>Algemene info</h4>
            <label>Naam in het Nederlands</label>
            <input
              className="input"
              onChange={(input) => {
                setNameNL(input.target.value);
              }}
              placeholder="Naam"
              required={true}
              type="text"
              value={nameNL}
            />
            <label>Naam in het Engels</label>
            <input
              className="input"
              onChange={(input) => {
                setNameEN(input.target.value);
              }}
              placeholder="Naam"
              type="text"
              value={nameEN}
            />
            <label>Naam in het Frans</label>
            <input
              className="input"
              onChange={(input) => {
                setNameFR(input.target.value);
              }}
              placeholder="Naam"
              type="text"
              value={nameFR}
            />
            <label>Beschrijving in het Nederlands</label>
            <textarea
              className="input"
              onChange={(input) => {
                setInfoNL(input.target.value);
              }}
              placeholder="Beschrijving"
              required={true}
              rows={6}
              value={infoNL}
            />
            <label>Beschrijving in het Engels</label>
            <textarea
              className="input"
              onChange={(input) => {
                setInfoEN(input.target.value);
              }}
              placeholder="Beschrijving"
              rows={6}
              value={infoEN}
            />
            <label>Beschrijving in het Frans</label>
            <textarea
              className="input"
              onChange={(input) => {
                setInfoFR(input.target.value);
              }}
              placeholder="Beschrijving"
              rows={6}
              value={infoFR}
            />

            <button className="button" type="submit">
              {module ? "Module bijwerken" : "Module toevoegen"}
            </button>
          </div>
        </div>
        <div className="w-25">
          <h4>&nbsp;</h4>
          <label>Afbeelding</label>
          {selectedFile ? (
            <img
              alt={nameNL}
              className="business-image"
              src={URL.createObjectURL(selectedFile)}
            />
          ) : null}
          {moduleImage && !selectedFile ? (
            <img
              alt={nameNL}
              className="business-image"
              src={getCorrectUrl(moduleImage)}
            />
          ) : null}
          <input
            accept={acceptedTypes.toString()}
            className="input"
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                setSelectedFile(e.target.files[0]);
              }
            }}
            placeholder="Upload afbeelding"
            type="file"
          />
          <Checkbox
            onClick={(value) => {
              setPrivateModule(value);
            }}
            title="Private module"
            value={privateModule}
          />
          <Checkbox
            onClick={(value) => {
              setIsSkill(value);
            }}
            title="Skill"
            value={isSkill}
          />
          <label>Prijs</label>
          <input
            className="input"
            onChange={(e) => {
              setPrice(e.target.value);
            }}
            placeholder="Prijs"
            step={"0.1"}
            type="number"
            value={price}
          />
          <label>Volgorde</label>
          <input
            className="input"
            onChange={(e) => {
              setOrder(e.target.value);
            }}
            placeholder="Volgorde"
            step={"1"}
            type="number"
            value={order}
          />
        </div>
      </div>
    </form>
  );
}
