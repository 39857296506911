import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import "./FaceScannerInfo.css";
import GIF from "./img/faceScanGIF.gif";

export function FaceScannerInfo({
  onRequestClose,
  onAcceptScan,
}: {
  onAcceptScan: () => void;
  onRequestClose: () => void;
}) {
  const { t } = useTranslation("face-scanner");

  return (
    <div className={"scanner-explanation-wrapper"}>
      <div
        className="scanner-close-button"
        onClick={onRequestClose}
        title="Close dialog"
      >
        <FontAwesomeIcon color="grey" icon={faTimes} size="2x" />
      </div>
      <div className={"flex"}>
        <div>
          <div className={"title"}>{t("titleExplanation")}</div>
          <div className={"introduction"}>{t("introExplanation")}</div>
          <div className={"text"}>{t("paragraph1Explanation")}</div>
        </div>
        <div className={"gif-wrapper flex"}>
          <img className={"gif"} src={GIF} />
        </div>
      </div>
      <div className={"text"}>
        <Trans i18nKey="ppgExplanation" t={t} />
      </div>
      <div className={"text"}>
        <Trans i18nKey="facialExplanation" t={t} />
      </div>
      <div className={"text"}>
        {t("forMoreInfoExplanation")}{" "}
        <Link
          className={"link"}
          target={"_blank"}
          to={{ pathname: "https://www.intelliprove.com/technology/" }}
        >
          https://www.intelliprove.com/technology/
        </Link>
      </div>

      <div>
        <button className="button mt-4" onClick={onAcceptScan}>
          {t("doScan")}
        </button>
        <button className="button yellow mt-4 ml-2" onClick={onRequestClose}>
          {t("maybeLater")}
        </button>
      </div>
    </div>
  );
}
