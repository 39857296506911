import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type Discount = {
  discount: number;
  id: string;
  name: string;
};

export async function getDiscounts(): Promise<Discount[]> {
  const result = await fetch(`${API_ENDPOINT}/game/discounts`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.discounts) {
    return [];
  }

  return result.discounts;
}

export async function createDiscount(discount: Discount): Promise<Discount> {
  const result = await fetch(`${API_ENDPOINT}/game/discounts`, {
    body: JSON.stringify(discount),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}

export async function updateDiscount(discount: Discount): Promise<Discount> {
  const result = await fetch(
    `${API_ENDPOINT}/game/discounts/${discount.name}`,
    {
      body: JSON.stringify(discount),
      headers: getHeaders(),
      method: "PUT",
    },
  ).then((r) => r.json());

  return result;
}

export async function deleteDiscount(discount: Discount): Promise<void> {
  await fetch(`${API_ENDPOINT}/game/discounts/${discount.name}`, {
    headers: getHeaders(),
    method: "DELETE",
  }).then((r) => r.json());
}
