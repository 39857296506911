import React from "react";
import { useTranslation } from "react-i18next";

export function DetoxText() {
  const { i18n, t } = useTranslation("general");

  const nl = [
    "Je brengt meer en meer tijd door op je digital toestellen zonder het te beseffen en je verliest op dat moment de tijd uit het oog",
    "Je blijft (te) lang wakker door jouw digitaal gebruik terwijl je eigenlijk al had moeten slapen",
    "Het percentage van de door jou online gespendeerde tijd (werk uitgezonderd) waarvan je vindt dat het verloren tijd is of niet bijdraagt aan je persoonlijke doelstellingen (doelloos surfen, eindeloos chatten, gaming, bingewatching, youtube, Facebook scrollen..) is gelijk aan of hoger dan 50%",
    "Gepland werk of een geplande activiteit mis je wegens smartphone- of ander digitaal gebruik",
    "Je verlangt er stiekem naar om “minder online” te zijn",
    "Binnen het laatste uur voor je gaat slapen, check je nog je smartphone (of ander scherm) voor mail, social media, chat, nieuws of andere functionaliteiten",
    "Binnen het eerste uur nadat je wakker bent, check je jouw smartphone (of ander scherm) voor mail, social media, chat, nieuws of andere functionaliteiten",
    "Je raadpleegt en beantwoordt sms’jes, berichten, mails, tweets… terwijl je met een andere activiteit bezig bent die je volledige aandacht vereist zoals werken, koken, autorijden, ..",
    "Je kunt je niet goed concentreren en je aandacht wordt frequent getrokken naar digitale afleidingen (surfen, nieuwssites, online shoppen, social media, mailbox) en je vermoedt dat daarmee ook jouw productiviteit daalt",
    "Je voelt je ongemakkelijk als je per ongeluk je smartphone vergeten bent, als er geen ontvangst is of als je batterij bijna leeg is",
    "Je gebruikt dezelfde kanalen voor zowel persoonlijke als professionele contacten of aangelegenheden (vb messenger voor privé en werk, één mailadres voor privé en werk)",
    "Je wordt wrevelig en zenuwachtig als je je smartphone niet kunt checken",
    "Terwijl je eet (alleen), ligt jouw smartphone voor of naast je op tafel",
    "Je bent vrijwel dagelijks actief op 3 of meer verschillende sociale media kanalen (Facebook, instagram, pinterest, linkedin, youtube, twitter, ..)",
    "Je kunt niet één uur zonder je smartphone te checken, zelfs in je vrije tijd (zelfs terwijl je bijvoorbeeld in het bos wandelt, televisiekijkt of een gezellig gesprek voert met vrienden)",
    "Jouw inbox bezorgt je stress en je voelt je genoodzaakt om snel te antwoorden (zo snel mogelijk of toch zeker dezelfde dag nog)",
  ];

  const en = [
    "You spend more and more time on your digital devices without realizing it and you lose track of time at that moment",
    "You stay awake (too) long due to your digital use when you should have been asleep already",
    "The percentage of the time you spend online (excluding work) that you think is lost time or does not contribute to your personal goals (aimless surfing, endless chatting, gaming, binge-watching, YouTube, Facebook scrolling ...) is equal to or higher than 50%",
    "You miss planned work or a planned activity due to smartphone or other digital use",
    "You secretly long to be “less online”			",
    "Within the last hour before you go to sleep, you check your smartphone (or other screen) for mail, social media, chat, news or other functionalities",
    "Within the first hour after you wake up, you check your smartphone (or other screen) for email, social media, chat, news or other functionalities",
    "You consult and answer text messages, messages, emails, tweets… while you are engaged in another activity that requires your full attention such as working, cooking, driving, ..",
    "You cannot concentrate properly and your attention is frequently drawn to digital distractions (surfing, news sites, online shopping, social media, mailbox) and you suspect that this also decreases your productivity",
    "You feel uncomfortable if you accidentally forget your smartphone, if there is no reception or if your battery is low",
    "You use the same channels for both personal and professional contacts or matters (e.g. messenger for private and work, one email address for private and work)",
    "You get resentful and nervous when you can't check your smartphone			",
    "While you eat (alone), your smartphone is in front of or next to you on the table",
    "You are almost daily active on 3 or more different social media channels (Facebook, instagram, pinterest, linkedin, youtube, twitter, ..)",
    "You can't go one hour without checking your smartphone, even in your free time (even while walking in the woods, watching TV or having a nice chat with friends, for example)			",
    "Your inbox causes you stress and you feel compelled to answer quickly (as soon as possible or at least the same day)",
  ];

  const fr = [
    "Vous passez de plus en plus de temps sur vos appareils numériques sans vous en rendre compte et vous perdez la notion du temps à ce moment-là",
    "Vous restez éveillé (trop) longtemps à cause de votre utilisation numérique alors que vous auriez dû dormir",
    "Le pourcentage du temps que vous passez en ligne (hors travail) que vous pensez être du temps perdu ou ne contribue pas à vos objectifs personnels (surf sans but, bavardage sans fin, jeux, visionnage excessif, youtube, défilement Facebook ...) est égal à ou supérieur à 50% Le pourcentage du temps que vous passez en ligne (hors travail) que vous pensez être du temps perdu ou ne contribue pas à vos objectifs personnels (surf sans but, bavardage sans fin, jeux, visionnage excessif, youtube, défilement Facebook ...) est égal à ou supérieur à 50%",
    "Vous manquez un travail planifié ou une activité planifiée en raison d'un smartphone ou d'une autre utilisation numérique",
    'Vous aspirez secrètement à être "moins en ligne"',
    "Dans la dernière heure avant de vous coucher, vous vérifiez votre smartphone (ou autre écran) pour le courrier, les médias sociaux, le chat, les actualités ou d'autres fonctionnalités			",
    "Dans la première heure après votre réveil, vous vérifiez votre smartphone (ou autre écran) pour le courrier, les médias sociaux, le chat, les nouvelles ou d'autres fonctionnalités			",
    "Vous consultez et répondez à des SMS, messages, e-mails, tweets… alors que vous êtes engagé dans une autre activité qui nécessite toute votre attention telle que le travail, la cuisine, la conduite, ..",
    "Vous ne pouvez pas vous concentrer correctement et votre attention est fréquemment attirée sur les distractions numériques (navigation, sites d'actualités, achats en ligne, réseaux sociaux, boîte aux lettres) et vous pensez que cela diminue également votre productivité			",
    "Vous vous sentez mal à l'aise si vous oubliez accidentellement votre smartphone, s'il n'y a pas de réception ou si votre batterie est faible			",
    "Vous utilisez les mêmes canaux pour les contacts ou les questions personnelles et professionnelles (par exemple, messager pour le privé et le travail, une adresse e-mail pour le privé et le travail)",
    "Vous êtes irrité et nerveux lorsque vous ne pouvez pas vérifier votre smartphone",
    "Pendant que vous mangez (seul), votre smartphone est devant ou à côté de vous sur la table",
    "Vous êtes presque quotidiennement actif sur 3 canaux de médias sociaux ou plus (Facebook, Instagram, Pinterest, LinkedIn, YouTube, Twitter, ..)",
    "Vous ne pouvez pas passer une heure sans vérifier votre smartphone, même pendant votre temps libre (même en vous promenant dans les bois, en regardant la télévision ou en discutant avec des amis, par exemple)",
    "Votre boîte de réception vous cause du stress et vous vous sentez obligé de répondre rapidement (le plus tôt possible ou au moins le jour même)",
  ];

  const options_nl = [
    {
      name: "Ja/vaak",
      value: 2,
    },
    {
      name: "Soms",
      value: 1,
    },
    {
      name: "Nee/nooit",
      value: 0,
    },
  ];

  const options_en = [
    {
      name: "Yes/often",
      value: 2,
    },
    {
      name: "Sometimes",
      value: 1,
    },
    {
      name: "no/never",
      value: 0,
    },
  ];

  const options_fr = [
    {
      name: "Oui/Souvent",
      value: 2,
    },
    {
      name: "De temps en temps",
      value: 1,
    },
    {
      name: "Non/Jamais",
      value: 0,
    },
  ];

  function calculateScore() {
    try {
      let score = 0;

      for (let i = 0; i < nl.length; i++) {
        score += parseInt(
          (
            document.querySelector(
              `input[name="v${i + 1}"]:checked`,
            ) as HTMLInputElement
          ).value,
        );
      }

      document.getElementById("resultaat").style.display = "block";
      // @ts-expect-error no idea why, check OG ML repo?
      document.getElementById("score").innerText = score;
      document.getElementById("vragen").style.display = "none";
    } catch {
      document.getElementById("error").style.display = "block";
    }
  }

  return (
    <div
      style={{ alignItems: "center", display: "flex", flexDirection: "column" }}
    >
      <div id="vragen" style={{ overflow: "auto", width: "100%" }}>
        <table className="table">
          <thead>
            <tr>
              <td></td>
              {options_nl.map((_, i) => {
                return (
                  <td>
                    {i18n.language === "nl" && options_nl[i].name}
                    {i18n.language === "en" && options_en[i].name}
                    {i18n.language === "fr" && options_fr[i].name}
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {nl.map((_, i) => {
              return (
                <tr>
                  <td>
                    {i18n.language === "nl" && nl[i]}
                    {i18n.language === "en" && en[i]}
                    {i18n.language === "fr" && fr[i]}
                  </td>
                  {options_nl.map((item) => {
                    return (
                      <td>
                        <input
                          name={`v${i + 1}`}
                          type="radio"
                          value={item.value}
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <span id="error" style={{ color: "red", display: "none" }}>
          Gelieve alle vragen te beantwoorden
        </span>
        <div
          className="button w-fit mt-4"
          onClick={() => {
            calculateScore();
          }}
        >
          {t("calculate_score")}
        </div>
      </div>
      <div id="resultaat" style={{ display: "none", width: "80%" }}>
        <h2>
          {t("score")}
          <div id="score">0</div>
        </h2>
        <div>
          <ul>
            <li>
              {i18n.language === "nl" && "< 6: Je zit safe "}
              {i18n.language === "en" && "< 6: You are safe "}
              {i18n.language === "fr" && "< 6: You are safe"}
            </li>
            <li>
              {i18n.language === "nl" && "6-16: Digital danger "}
              {i18n.language === "en" && "6-16: Digital danger "}
              {i18n.language === "fr" && "6-16: Digital danger"}
            </li>
            <li>
              {i18n.language === "nl" && "> 16: Digital damage "}
              {i18n.language === "en" && "> 16: Digital damage "}
              {i18n.language === "fr" && "> 16: Digital damage"}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
