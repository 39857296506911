import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type Podcast = {
  audio: string;
  audioEN: string;
  audioFR: string;
  description: string;
  descriptionEN: string;
  descriptionFR: string;
  id: string;
  image: string;
  name: string;
  nameEN: string;
  nameFR: string;
};

export async function getPodcasts(): Promise<Podcast[]> {
  const result = await fetch(`${API_ENDPOINT}/podcasts`, {
    headers: getHeaders(),
    method: "GET",
  }).then((r) => r.json());

  if (!result.podcasts) {
    return [];
  }

  return result.podcasts;
}

export async function createPodcast(podcast: Podcast): Promise<Podcast> {
  const result = await fetch(`${API_ENDPOINT}/podcasts`, {
    body: JSON.stringify(podcast),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());

  return result;
}

export async function addPodcastFeedback(
  id: string,
  score: number,
  feedback: string,
): Promise<void> {
  await fetch(`${API_ENDPOINT}/podcasts/feedback`, {
    body: JSON.stringify({
      feedback: feedback,
      id: id,
      score: score,
    }),
    headers: getHeaders(),
    method: "POST",
  }).then((r) => r.json());
}

export async function updatePodcast(podcast: Podcast): Promise<Podcast> {
  const result = await fetch(`${API_ENDPOINT}/podcasts/${podcast.id}`, {
    body: JSON.stringify(podcast),
    headers: getHeaders(),
    method: "PUT",
  }).then((r) => r.json());

  return result;
}
