import React, { useState } from "react";
import { Sidebar } from "../Sidebar";
import { useEffectOnce } from "react-use";
import { Navigate } from "react-router-dom";
import { FaPlus, FaPen, FaTimes } from "react-icons/fa";
import {
  DashboardDocument,
  deleteDocument,
  getDocuments,
} from "../api/DashboardDocumentsApi";

export function DashboardDocuments() {
  const [documents, setDocuments] = useState<DashboardDocument[]>([]);
  const [addingDocument, setAddingDocument] = useState(false);
  const [editingDocument, setEditingDocument] =
    useState<DashboardDocument | null>(null);

  const [filter, setFilter] = useState("");

  useEffectOnce(() => {
    getDocuments().then((r) => {
      setDocuments(r);
    });
  });

  if (addingDocument) {
    return <Navigate to="/dashboard/dashboard-documents/new" />;
  }

  if (editingDocument) {
    return (
      <Navigate to={`/dashboard/dashboard-documents/${editingDocument.id}`} />
    );
  }

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>Documenten</h2>
          <div
            className="button small ml-auto"
            onClick={() => {
              setAddingDocument(true);
            }}
          >
            <FaPlus />
          </div>
        </div>

        <input
          className="input"
          onChange={(input) => {
            setFilter(input.target.value);
          }}
          placeholder={"Naam"}
          type="text"
          value={filter}
        />

        {documents.length === 0 ? (
          "Momenteel nog geen documenten beschikbaar"
        ) : (
          <table className="table ">
            <thead>
              <tr>
                <th>Naam</th>
                <th>Url</th>
                <th>Type</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {documents
                .filter((d) =>
                  d.name.toLowerCase().includes(filter.toLowerCase()),
                )
                .map((document) => {
                  return (
                    <tr key={document.id}>
                      <td>{document.name}</td>
                      <td>{document.url}</td>
                      <td>{document.type}</td>
                      <td>
                        <div className="flex">
                          <div
                            className="button small w-fit ml-5 yellow"
                            onClick={() => {
                              setEditingDocument(document);
                            }}
                          >
                            <FaPen />
                          </div>
                          <div
                            className="button small w-fit ml-5 pink"
                            onClick={async () => {
                              await deleteDocument(document.id);
                              setDocuments(
                                documents.filter((b) => b.id !== document.id),
                              );
                            }}
                          >
                            <FaTimes />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
    </Sidebar>
  );
}
