import React, { useState } from "react";
import { Sidebar } from "../Sidebar";
import { useEffectOnce } from "react-use";
import { Navigate } from "react-router-dom";
import { FaPlus, FaPen, FaTimes } from "react-icons/fa";
import { deleteQuiz, getQuizes, QuizType } from "../api/QuizApi";

export function Quizzes() {
  const [quizzes, setQuizzes] = useState<QuizType[]>([]);
  const [addingQuiz, setAddingQuiz] = useState(false);
  const [editingQuiz, setEditingQuiz] = useState<QuizType | null>(null);
  const [filter, setFilter] = useState("");

  useEffectOnce(() => {
    getQuizes().then((r) => {
      setQuizzes(r);
    });
  });

  if (addingQuiz) {
    return <Navigate to="/dashboard/quizzes/new" />;
  }

  if (editingQuiz) {
    return <Navigate to={`/dashboard/quizzes/${editingQuiz.id}`} />;
  }

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>Quizzen</h2>
          <div
            className="button small ml-auto"
            onClick={() => {
              setAddingQuiz(true);
            }}
          >
            <FaPlus />
          </div>
        </div>

        <input
          className="input"
          onChange={(input) => {
            setFilter(input.target.value);
          }}
          placeholder={"Naam"}
          type="text"
          value={filter}
        />

        {quizzes.length === 0 ? (
          "Momenteel nog geen quizzen beschikbaar"
        ) : (
          <table className="table ">
            <thead>
              <tr>
                <th>ID</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {quizzes.map((business) => {
                return (
                  <tr key={business.id}>
                    <td>{business.id}</td>
                    <td>
                      <div className="flex">
                        <div
                          className="button small w-fit ml-5 pink ml-auto"
                          onClick={async () => {
                            await deleteQuiz(business.id);
                            setQuizzes(
                              quizzes.filter((b) => b.id !== business.id),
                            );
                          }}
                        >
                          <FaTimes />
                        </div>
                        <div
                          className="button small w-fit ml-5 yellow"
                          onClick={() => {
                            setEditingQuiz(business);
                          }}
                        >
                          <FaPen />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </Sidebar>
  );
}
